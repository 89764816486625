import React, { useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getToken } from "../../../Context/ApiContext";

const ModalValidation = (props) => {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };

  return (
    <>
    <div className="row mb_24 r-15 navigationSection">
      <div className="col-xs-12 col-sm-12 colend">
        <a className="btn btn-default" onClick={props.prec} >Précédent</a>
        <Button className="btn btn-default btn_terminer" onClick={handleShow}>
          Terminer
        </Button>
      </div>
    </div>
    
      <Modal className="Modal-default" show={show} onHide={handleClose}>
        <Modal.Body>
          <h3>Vous y êtes presque! </h3>
          <div className="">
            <p>
              Plus qu’une étape ! Il ne vous reste plus qu’à dater et faire
              signer la demande d'adhésion du contrat et la demande d'adhésion
              d'achat d'unités de rente le cas échéant par votre client et nous
              les renvoyer complets à l'adresse prevoyance@repam.fr.
              <br />
              <br />
              En cas d'achat d'unités de rente soumis à formalités médicales, le
              questionnaire médical sera à renvoyer à medical@repam.fr
              <br />
              <br />
              Notre service gestion traitera votre dossier dans les meilleurs
              délais.
              <br />
              <br />
              Pour toute question, vous pouvez entrer en contact avec votre
              inspecteur ou notre service commercial au 04 72 33 40 95
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Link
            className="btn btn-primary btn-default btn_terminer"
            to={"/dependance/"+getToken()}
          >
            Ok
          </Link>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default ModalValidation;
