import React, { Component } from "react";
import ReactRom from 'react-dom'
import { Link } from "react-router-dom";
import ModalQuit from "./CommonComponent/modalQuit";
import ModalDocs from "./ComponentSimulation/ModalDocs";
import "./ComponentProposition/proposition.css";
import "./ComponentSimulation/Simulation.css";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axios from "axios";
import { TextField } from "@mui/material";
import moment from "moment";
import $ from "jquery";
import { Button } from "react-bootstrap";
import icon_filtre_off from "../../assets/img/cotistion.svg";
import assistance from "../../assets/img/assistance.svg";
import icoFile from "../../assets/img/icoFile.svg";
import rotation from "../../assets/img/rotation.svg";
import repamSvg from "../../assets/img/repam.svg";
import { withRouter } from "./whitRouter";
import {  HelpRounded } from "@mui/icons-material";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { getToken, getInformations, docDownload } from "../../Context/ApiContext";
import * as xml2js from "xml2js";
import ModalExpire from "./CommonComponent/modalExpire";

/* -------------------------------------------IMPORT STYLE Global ----------------------------------- */
import { Global } from "./ComponentSimulation/Global";
import ModalServeur from "./CommonComponent/modalServeur";

axios.defaults.timeout = 5000;

class Simulation extends Component {
  constructor(props) {
    super(props);
    this.printRef = React.createRef();
    global.TabRenteRachat = [];
    global.TabRente = [];

    this.state = {
      modalServeur: false,
      DDN: "",
      modalExpire: false,
      age: 10,
      AdherentCheck: false,
      checkbox_20E: false,
      VersementCheck: false,
      cotisation: "",
      ShowBtn: false,
      ShowBtnAppliquer: false,
      ShowBtnAfterClickSum: false,
      ShowBtn_tele: true,
      stateInfoBulle: false,
      Div_Stat: false,
      Div_Infos: false,
      DocTelecharge: false,
      TabForm: [],
      openSelect: false,
      toggelSelectinteraction: false,
      Val_Doc_Telecharge: ["Projet"],
      Show_tele_btn: true,
      showLoadinBtnTel: false,
      dateNais: "",
      tabSimu: [],
      dataSum: [],
      valNom: "",
      valLastName: "",
      valCiv: "",
      valueCotisation: "",
      valPrime: "",
      valRegleGestion: "20/20",
      id_Proposition: "",
      id_sumilation: "",
      id_client: "",
      Proje_num: "",
      ch_1: true,
      ch_2: false,
      ch_3: false,
      ch_4: false,
      loading: false,
      errorMsgName: false,
      errorMsgNameSize: false,
      errorMsgLastName: false,
      errorMsgLastNameSize: false,
      errorMsgDateNais: false,
      errorMsgDateNaisInvalid: false,
      errorMsginvalideDDN: false,
      errorMsgPrimeInitiale: false,
      errorMsgPrimeInitialeCoti: false,
      tab_Totale_Final: [],
      tab_Paritelle: [],
      dateRefMax: "",
      dateRefMin: "",
      dateMax: "",
      dateMin: "",
      YearDDNB: "",
      dataStorage: [],
      ID_visualiser_sim: "",
      civilitePrec: "1",
      nomPrec: "",
      lastnomPrec: "",
      ddnPrec: "",
      isAdherentPrec: false,
      refPrec: "",
      sVersementPrec: "",
      cotisationPrec: 20,
      errorServer: false,
      rente: "",
      BTN_visualiser: false,
      element_chart: false,
      disabledDDN: false
    };
  }

  componentDidMount() {

    ReactRom.render(
      <></>, document.getElementById('sessionText'))
    this.setState({ loading: true });
    const date = new Date();
    const currentYear = date.getFullYear();
    const currentMonth = date.getMonth() + 1;
    const currentDate = date.getDate();

    let v_month_1Day = "";
    let v_day_1Day = "";

    if (currentMonth < 10) {
      v_month_1Day = "0";
    }
    if (currentDate < 10) {
      v_day_1Day = "0";
    }


    this.setState({

      dateRefMax: currentYear - 18 + "-" + v_month_1Day + (currentMonth) + "-" + v_day_1Day + currentDate,
      dateRefMin: currentYear - 74 + "-" + v_month_1Day + (currentMonth) + "-" + v_day_1Day + currentDate,
      dateMax: currentYear - 18 + "-12-31" ,
      dateMin: currentYear - 74 + "-01-01" ,
    });

    /* ------------------------------------- Data LocalStorage data_visualiser_sim --------------------------------------- */

    let id = "";

    let parsed_dependance = JSON.parse(localStorage.getItem("Data_Dependance"));
    let parsed_visualiser = JSON.parse(localStorage.getItem("data_visualiser"));

    if (parsed_visualiser) {
      this.setState({ ShowBtnAppliquer: true, loading: false });
      id = parsed_visualiser[0].id;
    } else if (parsed_dependance) {
      this.setState({ loading: false });
      let Precedent = parsed_dependance[0].Precedent;
      id = parsed_dependance[0].id;

      if (Precedent) {
        this.setState({ ShowBtnAppliquer: true });
      } else {
        let Response = parsed_dependance[0].Response === "oui" ? true : false;
        this.setState({ checkbox_20E: Response });
      }
    }

    id &&
      fetch(`${process.env.REACT_APP_URL}/proposition/${id}/${getToken()}`)
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else if (response.status === 404) {
            this.props.navigate("/Page_404");
          } else {
            this.props.navigate("/Page_500");
          }
        })
        .then((data) => {
          let items = data.proposition;
          const date = new Date();
          const currentYear = date.getFullYear();
          const currentMonth = date.getMonth();
          const currentDate = date.getDate();

          let v_day_1Day = "";
          if (currentMonth <= 9) {
            v_month_1Day = "0";
          }
          if (currentMonth <= 9) {
            v_day_1Day = "0";
          }
          this.valCiv = true
          this.valCot  = true
          this.valDDN  = true

         this.setState({

            Proje_num: items.reference,
            valRegleGestion: items.simulation["ref"],
            valCiv: items.simulation["civilite"] ,
            nomPrec: items.client["nom"],
            lastnomPrec: items.client["prenom"],
            DDN: items.client["date_de_naissance"],
            checkbox_20E: items.simulation["isAdherent"], // check
            valueCotisation: items.simulation["cotisation"],
            VersementCheck: items.simulation["isVersement"], // chech
            valPrime: items.simulation["value_Versement"], // chech
            id_Proposition: items.id,
            id_sumilation: items.simulation["id"],
            id_client: items.client["id"],
            BTN_visualiser: true,
            ShowBtnAppliquer: true,
            ShowBtn: true,
            loading: false,
          });
          this.showUnites=items.simulation["isVersement"]
          this.valPrime=items.simulation["value_Versement"]
          let YearN = (new Date(items.client["date_de_naissance"]) ).getFullYear()
          if(YearN > (currentYear-18)||YearN < (currentYear-74)){
            this.setState({
              errorMsgDateNaisInvalid:true,
              ShowBtn: false,
              disabledDDN:true,
              dateMax:"" ,
              dateMin: "" ,
            })
          }else{
            let dateJ = new Date();
            let currentYear = dateJ.getFullYear();
            let currentMonth = dateJ.getMonth() + 1;
            let currentDate = dateJ.getDate();

            let v_month_1Day = "";
            let v_day_1Day = "";

            if (currentMonth < 10) {
              v_month_1Day = "0";
            }
            if (currentDate < 10) {
              v_day_1Day = "0";
            }


            this.setState({
             disabledDDN:false,
             dateMax: currentYear - 18 + "-12-31" ,
            dateMin: currentYear - 74 + "-01-01" ,
            });
          }
          $("div.civ  ,div.cot  ").addClass("Select_Bl");
          // $("div.civ  fieldset,div.cot fieldset").addClass( "Select_Bl input_valid");
        })
        .catch((error) => {
          this.props.navigate("/Page_500");
        });
  }
  /* <---------------------  close modal serveur error   ------------------> */
  handleCloseModalServeur = () => {
    this.setState({
      modalServeur: false
    })
  }

  /* <----------------------------------------------FN OnChange Date de naissance -----------------------------------------------------> */
  onchangeDate = (event) => {
    event.preventDefault();
    if (this.state.ShowBtnAppliquer) {
      this.setState({ ShowBtnAfterClickSum: true, Div_Stat: false });
    } else {
      this.setState({ ShowBtnAfterClickSum: false, Div_Stat: false });
    }

    const parent = event.currentTarget.parentNode.parentNode.parentNode;
    const valDate = event.target.value;
    this.valCot = this.state.valueCotisation;
    let dateValDate= new Date(valDate)
    dateValDate.setMonth(dateValDate.getMonth()+1)
    let getValYear = dateValDate.getFullYear();
    let getValMonth = dateValDate.getMonth();
    let getValDays = dateValDate.getDate()
    const date = new Date();
    const currentYear = date.getFullYear();
    const currentMonth = date.getMonth() + 1;

    if (valDate) {
      parent.classList.add("focus-input");
    } else {
      parent.classList.remove("focus-input");
    }
    const varDateNais = event.currentTarget.parentNode.parentNode;

      this.setState({ DDN: valDate });
      let dateDDnValide = false;
      this.valDDN = true;
      const dateDDN = new Date(valDate);
      if ((dateDDN.getFullYear() > new Date(this.state.dateMax).getFullYear()) || (dateDDN.getFullYear() < new Date(this.state.dateMin).getFullYear())) {
        dateDDnValide = true;
      } else {
        dateDDnValide = false;
      }

      if (dateDDnValide) {
        this.setState({
          errorMsgDateNais: true,
          errorMsginvalideDDN: false,
        });
        this.varerrorMsgDateNais = true;
        this.varerrorMsginvalideDDN = false;
        document
          .getElementById("TextFieldDDn")
          .parentNode.parentNode.classList.add("error-valide");
        this.setState({ ShowBtn: false, Div_Stat: false });

        this.onShowSave()
      } else {

        this.setState({
          errorMsgDateNais: false,
          errorMsginvalideDDN: false,
        });
        this.varerrorMsgDateNais = false;
        this.varerrorMsginvalideDDN = false;
        document
          .getElementById("TextFieldDDn")
          .parentNode.parentNode.classList.remove("error-valide");



        this.onShowSave();
      }

  };

  /* <------------------------------------------------------FN OnChange Cotisation -------------------------------------------------------> */

  changeCotisation = (event) => {
    if (this.state.ShowBtnAppliquer) {
      this.setState({
        ShowBtnAfterClickSum: true,
        Div_Stat: false,
      });
    } else {
      this.setState({
        ShowBtnAfterClickSum: false,
        Div_Stat: false,
      });
    }

    this.valCot = event.target.getAttribute("data-value");
    if (this.valCot == null && this.state.valueCotisation) {
      this.valCot = this.state.valueCotisation;
    }
    document
      .querySelector(".MuiInputLabel-root")
      .classList.remove("Mui-focused");
    if (this.valCot) {
      this.setState({
        valueCotisation: this.valCot,
      });

      if (this.state.Div_Infos) {
        let valMaxCot = 1;

        if (this.valCot) {
          valMaxCot = this.valCot * 12 * 20;
          if (
            this.valPrime > valMaxCot ||
            this.valPrime > 6000 ||
            this.valPrime < 0 ||
            !/^[0-9]*$/i.test(this.valPrime)
          ) {
            this.setState({
              errorMsgPrimeInitiale: true,
              errorMsgPrimeInitialeCoti: false,
            });
            this.varerrorMsgPrimeInitiale = true;
            this.varerrorMsgPrimeInitialeCoti = false;
            document
              .getElementById("TextFieldPrime")
              .parentNode.parentNode.classList.add("error-valide");
          } else {
            this.setState({
              errorMsgPrimeInitiale: false,
              errorMsgPrimeInitialeCoti: false,
            });
            this.varerrorMsgPrimeInitiale = false;
            this.varerrorMsgPrimeInitialeCoti = false;
            document
              .getElementById("TextFieldPrime")
              .parentNode.parentNode.classList.remove("error-valide");
          }
        }
      }
      this.onShowSave();
    }
  };

  onchangePrime = (event) => {
    if (this.state.ShowBtnAppliquer) {
      this.setState({
        ShowBtnAfterClickSum: true,
        Div_Stat: false,
      });
    } else {
      this.setState({
        ShowBtnAfterClickSum: false,
        Div_Stat: false,
      });
    }
    const parent = event.currentTarget.parentNode.parentNode;
    let valMaxCot = 1;
    this.valPrime = event.target.value;
    if (this.state.valueCotisation) {
      valMaxCot = this.state.valueCotisation * 12 * 20;
      if (
        this.valPrime > valMaxCot ||
        this.valPrime > 6000 ||
        this.valPrime < 0 ||
        !/^[0-9]*$/i.test(this.valPrime)
      ) {
        this.setState({
          valPrime: this.valPrime,
          errorMsgPrimeInitiale: true,
          errorMsgPrimeInitialeCoti: false,
        });

        this.varerrorMsgPrimeInitiale = true;
        this.varerrorMsgPrimeInitialeCoti = false;
        parent.classList.add("error-valide");
      } else {
        this.setState({
          valPrime: this.valPrime,
          errorMsgPrimeInitiale: false,
          errorMsgPrimeInitialeCoti: false,
        });

        this.varerrorMsgPrimeInitiale = false;
        this.varerrorMsgPrimeInitialeCoti = false;

        parent.classList.remove("error-valide");
      }
    } else {
      this.setState({
        valPrime: this.valPrime,
        errorMsgPrimeInitiale: false,
        errorMsgPrimeInitialeCoti: true,
      });

      this.varerrorMsgPrimeInitiale = false;
      this.varerrorMsgPrimeInitialeCoti = true;
      parent.classList.add("error-valide");
    }

    this.onShowSave();
  };

  /* <------------------------------------------------------FN OnChange CheckAdherent -----------------------------------------------------> */
  OnChangeCheckAdherent = (e) => {
    this.setState({ checkbox_20E: !this.state.checkbox_20E });
  };
  OnChangeChecVersement = (e) => {

    if (this.state.ShowBtnAppliquer) {
      this.setState({
        ShowBtnAfterClickSum: true,
        Div_Stat: false,
      });
    } else {
      this.setState({
        ShowBtnAfterClickSum: false,
        Div_Stat: false,
      });
    }
    this.setState({ VersementCheck: !this.state.VersementCheck, valPrime: "" });
    this.setState({ Div_Infos: !this.state.Div_Infos });
    this.showUnites = !this.showUnites;
    this.valPrime=null
    if (this.showUnites) {
      this.setState({ ShowBtn: false });
    }
    this.onShowSave();
  };

  SeparateurNum = (val1, val2, val4, val5) => {
    let val3 = "";
    if (val1 === 0) {
      val3 = "-";
    } else {
      if (val1 / 1000 > 1) {
        if (val1 % 1000 < 100) {
          val2 = "0" + val4 + "," + val5;
        }
        if (val1 % 1000 < 10) {
          val2 = "00" + val4 + "," + val5;
        }
        if (val1 % 1000 >= 100) {
          val2 = val4 + "," + val5;
        }
        if (Math.floor(val1 / 1000) > 0) {
          val3 = Math.floor(val1 / 1000) + " " + val2 + " €";
        } else {
          val3 = " " + val2 + " €";
        }
      } else {
        val2 = val4 + "," + val5;
        if (Math.floor(val1 / 1000) > 0) {
          val3 = Math.floor(val1 / 1000) + " " + val2 + " €";
        } else {
          val3 = " " + val2 + " €";
        }
      }
    }
    return val3;
  };

  /* <------------------------------------------------------FN  OnShow  DivStat -----------------------------------------------------> */
  OnShowDivStat = (event) => {
    event.preventDefault();
    this.setState({ errorServer: false, loading: true, });

    const base_urlcotisation = `${process.env.REACT_APP_URL_NODE}/simulation`;

    let vDDE = this.state.DDN;
    let vCot = this.state.valueCotisation;
    let vPrime = this.state.valPrime;

    let dateDN =  new Date(this.state.DDN)
    //
      let numM = "";
      if ((dateDN.getMonth()+1) < 10) {
        numM = "0"
      }
      let numD = "";
      if (dateDN.getDate() < 10) {
        numD = "0"
      }
    let  valDDN_s = dateDN.getFullYear() + "-" + numM + (dateDN.getMonth()+1) + "-" + numD + dateDN.getDate()

    let TabFinal = [];
    let TabSum = [];

    let tab_Paritelle = [];
    let tab_Totale_Final = [];
    this.setState({ tabSimu: [], dataSum: [] });

    let cotisation = this.state.valueCotisation;
    var varTest = false;

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json", "token": getToken() },
      body: JSON.stringify({
        dateDeNaissance: valDDN_s,
        cotisation: vCot,
        primeInitiale: vPrime
      })
    };

    fetch(base_urlcotisation, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          return response.text()
        } else if (response.status === 404) {
          this.props.navigate("/Page_404");
        } else {
          this.props.navigate("/Page_500");
        }
      })
      .then((result) => {
        if (result === "BUS003") {
          varTest = true;
        } else {
          varTest = false;
          let Tab_init = [];
          let Tab_val_final = [];
          let resultParsed = JSON.parse(result);

          resultParsed.resultatSimulation.map((items) => {
            if (
              items.ageSortieCotisation === 65 ||
              items.ageSortieCotisation === 70 ||
              items.ageSortieCotisation === 75 ||
              items.ageSortieCotisation === 80 ||
              items.ageSortieCotisation === 85
            ) {
              Tab_init.push({
                age: items.ageSortieCotisation,
                Rente: items.renteAccumulee,
                RenteRachat: items.niveauRenteRachat,
                RenteTotal: items.renteTotale,
              });
            }
            return true;
          });

          /* ----------------------------------------------- Remplissage  TabRenteRachattemp \ TabRentetemp \ Tab_val_final -------------------------------------------------------- */
          let TabRenteRachattemp = [];
          let TabRentetemp = [];
          if (Tab_init.length < 4) {
            Tab_val_final = [{ age: "65", RenteTotal: 0 }, { age: "70", RenteTotal: 0 }];
            TabRenteRachattemp = [{ RenteRachat: 0 }, { RenteRachat: 0 }];
            TabRentetemp = [{ Rente: 0 }, { Rente: 0 }];
            Tab_val_final = Tab_val_final.concat(Tab_init);
            TabRenteRachattemp = TabRenteRachattemp.concat(Tab_init);
            TabRentetemp = TabRentetemp.concat(Tab_init);
          } else  if (Tab_init.length < 5) {
            Tab_val_final = [{ age: "65", RenteTotal: 0 }];
            TabRenteRachattemp = [{ RenteRachat: 0 }];
            TabRentetemp = [{ Rente: 0 }];
            Tab_val_final = Tab_val_final.concat(Tab_init);
            TabRenteRachattemp = TabRenteRachattemp.concat(Tab_init);
            TabRentetemp = TabRentetemp.concat(Tab_init);
          }else {
            Tab_val_final = Tab_init;
            TabRenteRachattemp = Tab_init;
            TabRentetemp = Tab_init;
          }
          global.TabRenteRachat = TabRenteRachattemp;
          global.TabRente = TabRentetemp;

          Tab_val_final.map((items) => {
            let item_val_partielle = parseFloat(0.25 * parseFloat(items.RenteTotal)).toFixed(2);
            let val_partielle = "";
            let val_final_partielle = "";
            let v0_P = parseFloat(item_val_partielle % 1000).toFixed(2).split(".")[0];
            let v1_P = parseFloat(item_val_partielle % 1000).toFixed(2).split(".")[1];

            if (!(item_val_partielle > 0)) { val_final_partielle = "-"; }
            else {
              if (item_val_partielle / 1000 > 1) {
                if (item_val_partielle % 1000 < 100) { val_partielle = "0" + v0_P + "," + v1_P; }
                if (item_val_partielle % 1000 < 10) { val_partielle = "00" + v0_P + "," + v1_P; }
                if (item_val_partielle % 1000 >= 100) { val_partielle = v0_P + "," + v1_P; }
                if (Math.floor(item_val_partielle / 1000) > 0) { val_final_partielle = Math.floor(item_val_partielle / 1000) + " " + val_partielle + " €"; }
                else { val_final_partielle = " " + val_partielle + " €"; }
              } else {
                val_partielle = v0_P + "," + v1_P;
                if (Math.floor(item_val_partielle / 1000) > 0) {
                  val_final_partielle = Math.floor(item_val_partielle / 1000) + " " + val_partielle + " €";
                } else {
                  val_final_partielle = " " + val_partielle + " €";
                }
              }
            }

            /* ---------------------------------------------------- val_Dépendance_totale ------------------------------------------------------- */
            let item_val = items.RenteTotal;
            let val = "";
            let val_total = "";
            let v0 = parseFloat(item_val % 1000).toFixed(2).split(".")[0];
            let v1 = parseFloat(item_val % 1000).toFixed(2).split(".")[1];

            if (!(item_val > 0)) { val_total = "-"; } else {
              if (item_val / 1000 > 1) {
                if (item_val % 1000 < 100) { val = "0" + v0 + "," + v1; }
                if (item_val % 1000 < 10) { val = "00" + v0 + "," + v1; }
                if (item_val % 1000 >= 100) { val = v0 + "," + v1; }
                if (Math.floor(item_val / 1000) > 0) { val_total = Math.floor(item_val / 1000) + " " + val + " €"; }
                else { val_total = " " + val + " €"; }
              } else {
                val = v0 + "," + v1;
                if (Math.floor(item_val / 1000) > 0) {
                  val_total = Math.floor(item_val / 1000) + " " + val + " €";
                } else {
                  val_total = " " + val + " €";
                }
              }
            }

            tab_Paritelle.push(val_final_partielle);
            tab_Totale_Final.push(val_total);

            TabSum.push({
              name: items.age + " ans",
              Dépendance_partielle: parseFloat(
                0.25 * parseFloat(items.RenteTotal)
              ).toFixed(2),
              Dépendance_totale: parseFloat(items.RenteTotal)
            });

            return true;
          });
        }

        if (varTest) {
          if (this.state.DDN) {
            this.setState({
              errorMsginvalideDDN: true,
              errorMsgDateNais: false,
              loading: false,
            });
            this.varerrorMsginvalideDDN = true;
            this.varerrorMsgDateNais = false;
            document
              .getElementById("TextFieldDDn")
              .parentNode.parentNode.classList.add("error-valide");
          }
        } else {
          this.setState({
            ShowBtn: false,
            ShowBtnAfterClickSum: false,
            /*  Div_Stat: true, */
            errorMsginvalideDDN: false,
            tab_Paritelle: tab_Paritelle,
            tab_Totale_Final: tab_Totale_Final,
            tabSimu: TabFinal,
            dataSum: TabSum,
          });
          this.varerrorMsginvalideDDN = false;

          if (this.state.errorMsgDateNais) {
            document
              .getElementById("TextFieldDDn")
              .parentNode.parentNode.classList.add("error-valide");
          } else {
            document
              .getElementById("TextFieldDDn")
              .parentNode.parentNode.classList.remove("error-valide");
          }

          this.renderSimulation();
          this.renderSimulationtotale();
          this.renderSimulationgraphe();
        }

        let Tab_TabRenteRachat = [];
        /* ------------------------------- update champ rente-------------------------------------------- */

        global.TabRenteRachat.map((items) => {
          let item_val = items.RenteRachat;
          let val = "";
          let val_total = "";
          let v0 = parseFloat(item_val % 1000)
            .toFixed(2)
            .split(".")[0];
          let v1 = parseFloat(item_val % 1000)
            .toFixed(2)
            .split(".")[1];

          if (item_val === 0) {
            val_total = "-";
          } else {
            if (item_val / 1000 > 1) {
              if (item_val % 1000 < 100) {
                val = "0" + v0 + "," + v1;
              }
              if (item_val % 1000 < 10) {
                val = "00" + v0 + "," + v1;
              }
              if (item_val % 1000 >= 100) {
                val = v0 + "," + v1;
              }
              if (Math.floor(item_val / 1000) > 0) {
                val_total = Math.floor(item_val / 1000) + " " + val + " €";
              } else {
                val_total = " " + val + " €";
              }
            } else {
              val = v0 + "," + v1;
              if (Math.floor(item_val / 1000) > 0) {
                val_total = Math.floor(item_val / 1000) + " " + val + " €";
              } else {
                val_total = " " + val + " €";
              }
            }
          }
          Tab_TabRenteRachat.push(val_total);
          return true;
        });

        let valRente = "";
        valRente = Tab_TabRenteRachat[0] === "-" ? "0" : Tab_TabRenteRachat[0];
        this.setState({ rente: valRente, ShowBtnAppliquer: true });


        /*  let element_chart=false; */
        if (!this.state.ShowBtnAppliquer) {
          //post new proposition
          const base_url = `${process.env.REACT_APP_URL}/propositionMacro/simulation/${getToken()}`;



          const datasimulation = {
            nom: this.state.valNom,
            prenom: this.state.valLastName,
            date_de_naissance: this.state.DDN,
            etat: "simulation",
            civilite: this.valCiv,
            isAdherent: this.state.checkbox_20E,
            cotisation: this.state.valueCotisation,
            isVersement: this.state.VersementCheck,
            ref: this.state.valRegleGestion,
            value_versement: this.state.valPrime,
            rente_rachat: valRente,
          };


          const requestOptions2 = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(datasimulation)
          };


          fetch(base_url, requestOptions2)
            .then((response) => {

              if (response.status === 200) {
                return response.json();
              } else if (response.status === 404) {
                this.props.navigate("/Page_404");
              } else {
                this.props.navigate("/Page_500");
              }
            })
            .then((data) => {
              data.proposition.id && this.setState({ Div_Stat: true })

              if (data.code == "-2") {
                this.setState({
                  modalExpire: true,
                });
              } else if (data.code == "-1") {
                this.setState({
                  modalServeur: true,
                })
              } else {
                this.setState({
                  id_Proposition: data.proposition.id,
                  Proje_num: data.proposition.reference,
                  id_sumilation: data.simulation.id,
                  id_client: data.client.id,
                });
              }
              this.setState({ loading: false, });
              this.Data_chart(valRente)

            }).catch((error) => {
              this.setState({ loading: false, });

            });
        } else {


          /* ------------------------------------------------------------------- */

          const base_url_sumilation = `${process.env.REACT_APP_URL}/simulation/${this.state.id_sumilation}/${getToken()}`;
          const base_url_Client = `${process.env.REACT_APP_URL}/client/${this.state.id_client}/${getToken()}`;

          const dataclient = {
            nom: this.state.nomPrec,
            prenom: this.state.lastnomPrec,
            date_de_naissance: vDDE,
          };

          const datasimulation = {
            civilite: this.state.valCiv,
            isAdherent: this.state.AdherentCheck,
            cotisation: this.state.valueCotisation,
            isVersement: this.state.VersementCheck,
            ref: this.state.valRegleGestion,
            value_versement: this.state.valPrime == "" ? null : this.state.valPrime,
            rente_rachat: valRente,

          };
          const requestOptions_Simulation = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(datasimulation)
          };


          const requestOptions_Client = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dataclient)
          };
          fetch(base_url_sumilation, requestOptions_Simulation)
            .then((response) => {
              if (response.status === 200) {
                return response.json();
              } else if (response.status === 404) {
                this.props.navigate("/Page_404");
              } else {
                this.props.navigate("/Page_500");
              }
            })
            .then((data) => {


              if (data.code == "-2") {
                this.setState({
                  modalExpire: true,
                });
              } else if (data.code == "-1") {
                this.setState({
                  modalServeur: true,
                })
              } else {
                this.setState({
                  id_Proposition: data.simulation.proposition,
                  id_sumilation: data.simulation.id,
                });
                /* req 2 client */

                fetch(base_url_Client, requestOptions_Client)
                  .then((response) => {
                    if (response.status === 200) {
                      return response.json();
                    } else if (response.status === 404) {
                      this.props.navigate("/Page_404");
                    } else {
                      this.props.navigate("/Page_500");
                    }
                  })
                  .then((data) => {

                    if (data.code == "-2") {
                      this.setState({
                        modalExpire: true,
                      });
                    } else if (data.code == "-1") {
                      this.setState({
                        modalServeur: true,
                      })
                    } else {
                      this.setState({
                        id_client: data.client.id,
                        Div_Stat: true
                      });

                    }
                    this.setState({ loading: false, });
                    this.Data_chart(valRente)

                  }).catch((error) => {
                    console.log("error req 2 => " + error)

                  });

                /* end req 2  client */

              }

            }).catch((error) => {
              console.log("error req 1 => " + error)
            });



        }



      })
      .catch((error) => {
        this.setState({ errorServer: true, loading: false, Div_Stat: false });

        this.props.navigate("/Page_500");
      });

    /*  (this.state.id_Proposition && this.state.id_Proposition !== undefined)   && this.setState({Div_Stat: true})   */
  };
  Data_chart = (val) => {
    setTimeout(() => {
      let elemChart = document.querySelector("li.legend-item-0 >span");
      let elemChart1 = document.querySelector("li.legend-item-1 >span");

      elemChart.innerHTML =
        "<span>Dépendance partielle</span> <br />" +
        " <span className='secande_text_0'>(25% du montant de la rente)</span> ";
      elemChart1.innerHTML =
        "<span>Dépendance totale</span> <br />" +
        " <span className='span'>(100% du montant de la rente)</span> ";

      var element = document.querySelector(".DivStat");
      element.scrollIntoView();
      this.setState({
        rente: val,
        loading: false,
        ShowBtnAppliquer: true,
      });
    }, 50);
  }


  handeInfoBulle = () => {
    this.setState({
      stateInfoBulle: !this.state.stateInfoBulle,
    });
  };

  handleClose = (event) => {
    document
      .querySelector(".MuiInputLabel-root")
      .classList.remove("Mui-focused");
  };

  handleCloseCiv = (event) => {
    document
      .querySelector(".MuiInputLabel-root")
      .classList.remove("Mui-focused");

    this.valCiv = event.target.getAttribute("data-value");
    if (this.valCiv === undefined && this.state.valCiv) {
      this.valCiv = this.state.valCiv;
    }
    this.setState({
      valCiv: this.valCiv,
    });
    if (this.state.valCiv !== this.valCiv) {
      if (this.state.ShowBtnAppliquer) {
        this.setState({
          ShowBtnAfterClickSum: true,
          Div_Stat: false,
        });
      } else {
        this.setState({
          ShowBtnAfterClickSum: false,
          Div_Stat: false,
        });
      }
    }

    this.onShowSave();
  };

  handleRegleGestion = (event) => {
    document
      .querySelector(".MuiInputLabel-root")
      .classList.remove("Mui-focused");
      let valRegleGestion = event.target.getAttribute("data-value");

      if(event.target.getAttribute("data-value") === null){
        valRegleGestion = "20/20"
      }
    this.setState({ valRegleGestion: valRegleGestion });
  };

  onShowSave = () => {
    if (
      this.state.nomPrec &&
      !this.state.errorMsgName &&
      !this.state.errorMsgNameSize &&
      this.state.lastnomPrec &&
      !this.state.errorMsgLastName &&
      !this.state.errorMsgLastNameSize &&
      this.valCiv &&
      this.valCot &&
      this.valDDN &&
      !this.varerrorMsgPrimeInitiale &&
      !this.errorMsgPrimeInitialeCoti &&
      !this.varerrorMsgDateNais &&
      !this.varerrorMsginvalideDDN
    ) {
      if (this.showUnites) {
        if (this.valPrime) {
          this.setState({ ShowBtn: true, ShowBtnAfterClickSum: true });
        } else {
          this.setState({ ShowBtn: false, ShowBtnAfterClickSum: false });
        }
      } else {
        this.setState({ ShowBtn: true, ShowBtnAfterClickSum: true });
      }
    } else {
      this.setState({ ShowBtn: false, ShowBtnAfterClickSum: false });
    }
  };

  renderSimulation = () => {
    if (this.state.tab_Paritelle.length === 5) {
      return this.state.tab_Paritelle.map((u) => (
        <td className="" key={u}>
          {u}
        </td>
      ));
    }
  };

  renderSimulationtotale = () => {
    if (this.state.tab_Totale_Final.length === 5) {
      return this.state.tab_Totale_Final.map((u) => (
        <td className="" key={u}>
          {u}
        </td>
      ));
    }
  };

  renderSimulationgraphe = () => {
    return (
      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          width={100}
          height={100}
          data={this.state.dataSum}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="name"
            tick={{
              stroke: "#9AA0AC",
              fontSize: 14,
              fontWeight: "400",
              fontFamily: "Gotham-Rounded-Light",
            }}
          />
          <YAxis
            unit="€"
            tick={{
              stroke: "#9AA0AC",
              fontSize: 14,
              fontWeight: "400",
              fontFamily: "Gotham-Rounded-Light",
            }}
          />
          <Tooltip />
          <Legend />
          <Bar
            dataKey="Dépendance_partielle"
            name="Dépendance partielle"
            fill="#445AA5"
            barSize={24}
            radius={[4, 4, 0, 0]}
          />
          <Bar
            dataKey="Dépendance_totale"
            name="Dépendance totale"
            fill="#E73C4C"
            barSize={24}
            radius={[4, 4, 0, 0]}
          />
        </BarChart>
      </ResponsiveContainer>
    );
  };

  onHideSelectinteraction = () => {
    this.setState({
      interaction: !this.state.interaction,
    });
  };

  validateName = (event) => {
    const parent = event.currentTarget.parentNode.parentNode;
    this.setState({
      nomPrec: event.target.value,
    });

    if (!/^[a-z- àâäéèêëîïôöùûüÿçæœ-]*$/i.test(event.target.value)) {
      parent.classList.add("error-valide");
      this.setState({
        errorMsgName: true,
        errorMsgNameSize: false,
      });
    } else if (!event.target.value.replace(/\s+/, "").length) {
      parent.classList.add("error-valide");
      this.setState({
        errorMsgName: false,
        errorMsgNameSize: true,
      });
    } else {
      if (event.target.value.length > 1 && event.target.value.length < 51) {
        parent.classList.remove("error-valide");
        this.setState({
          valNom: event.target.value,
          errorMsgName: false,
          errorMsgNameSize: false,
        });
      } else {
        parent.classList.add("error-valide");
        this.setState({
          valNom: false,
          errorMsgName: false,
          errorMsgNameSize: true,
        });
      }
    }

    if (this.state.ShowBtnAppliquer) {
      this.setState({
        ShowBtnAfterClickSum: true,
        Div_Stat: false,
      });
    } else {
      this.setState({
        ShowBtnAfterClickSum: false,
        Div_Stat: false,
      });
    }
  };

  validateLastName = (event) => {
    const parent = event.currentTarget.parentNode.parentNode;
    this.setState({
      lastnomPrec: event.target.value,
    });
    if (!/^[a-z- àâäéèêëîïôöùûüÿçæœ-]*$/i.test(event.target.value)) {
      parent.classList.add("error-valide");
      this.setState({
        valLastName: false,
        errorMsgLastName: true,
        errorMsgLastNameSize: false,
      });
    } else if (!event.target.value.replace(/\s+/, "").length) {
      parent.classList.add("error-valide");
      this.setState({
        valLastName: false,
        errorMsgLast: false,
        errorMsgLastNameSize: true,
      });
    } else {
      if (event.target.value.length > 1 && event.target.value.length < 51) {
        parent.classList.remove("error-valide");
        this.setState({
          valLastName: event.target.value,
          errorMsgLastName: false,
          errorMsgLastNameSize: false,
        });
      } else {
        parent.classList.add("error-valide");
        this.setState({
          valLastName: false,
          errorMsgLast: false,
          errorMsgLastNameSize: true,
        });
      }
    }

    if (this.state.ShowBtnAppliquer) {
      this.setState({
        ShowBtnAfterClickSum: true,
        Div_Stat: false,
      });
    } else {
      this.setState({
        ShowBtnAfterClickSum: false,
        Div_Stat: false,
      });
    }
  };

  onchnageEtatSumtoProjet = (event) => {
    event.preventDefault();
    this.setState({ ShowBtn_tele: false, DocTelecharge: true });
  };



  getDaysInMonth = (year, month) => {
    return new Date(year, month, 0).getDate();
  };
  /* -------------------------------- FN_PDF ------------------------------------ */


  handleClickPdf2() {
    this.setState({
      showLoadinBtnTel: true,
    });
    if (this.state.ch_1) {
      const date = new Date(this.state.DDE);
      const currentYear = date.getFullYear();
      const currentMonth = date.getMonth();
      const currentDate = date.getDate();
      let Tab_totale = [];
      let Tab_Rente = [];
      let Tab_TabRenteRachat = [];
      let Age = "";
      let a = moment([currentYear, currentMonth, currentDate]);
      let b = moment([
        this.state.DDN.slice(0, 4),
        parseInt(this.state.DDN.slice(5, 7)-1),
        this.state.DDN.slice(8, 10),
      ]);
      Age = a.diff(b, "years");


      this.state.tab_Totale_Final.map((items, i) => {
        let ttf = parseFloat(parseFloat(global.TabRente[i].Rente)+parseFloat(global.TabRenteRachat[i].RenteRachat)).toFixed(2)
        let item_val = ttf
        let val = "";
        let val_total = "";
        let v0 = parseFloat(item_val % 1000)
          .toFixed(2)
          .split(".")[0];
        let v1 = parseFloat(item_val % 1000)
          .toFixed(2)
          .split(".")[1];

        if (item_val === 0) {
          val_total = "-";
        } else {
          if (item_val / 1000 > 1) {
            if (item_val % 1000 < 100) {
              val = "0" + v0 + "," + v1;
            }
            if (item_val % 1000 < 10) {
              val = "00" + v0 + "," + v1;
            }
            if (item_val % 1000 >= 100) {
              val = v0 + "," + v1;
            }
            if (Math.floor(item_val / 1000) > 0) {
              val_total =
                Math.floor(item_val / 1000) + " " + val + " €";
            } else {
              val_total = " " + val + " €";
            }
          } else {
            val = v0 + "," + v1;
            if (Math.floor(item_val / 1000) > 0) {
              val_total =
                Math.floor(item_val / 1000) + " " + val + " €";
            } else {
              val_total = " " + val + " €";
            }
          }
        }
        Tab_totale.push(val_total);
        return true;
      });

      global.TabRente.map((items) => {
        let item_val = items.Rente;
        let val = "";
        let val_total = "";
        let v0 = parseFloat(item_val % 1000)
          .toFixed(2)
          .split(".")[0];
        let v1 = parseFloat(item_val % 1000)
          .toFixed(2)
          .split(".")[1];

        if (item_val === 0) {
          val_total = "-";
        } else {
          if (item_val / 1000 > 1) {
            if (item_val % 1000 < 100) {
              val = "0" + v0 + "," + v1;
            }
            if (item_val % 1000 < 10) {
              val = "00" + v0 + "," + v1;
            }
            if (item_val % 1000 >= 100) {
              val = v0 + "," + v1;
            }
            if (Math.floor(item_val / 1000) > 0) {
              val_total =
                Math.floor(item_val / 1000) + " " + val + " €";
            } else {
              val_total = " " + val + " €";
            }
          } else {
            val = v0 + "," + v1;
            if (Math.floor(item_val / 1000) > 0) {
              val_total =
                Math.floor(item_val / 1000) + " " + val + " €";
            } else {
              val_total = " " + val + " €";
            }
          }
        }
        Tab_Rente.push(val_total);
        return true;
      });
      global.TabRenteRachat.map((items) => {
        let item_val = items.RenteRachat;
        let val = "";
        let val_total = "";
        let v0 = parseFloat(item_val % 1000)
          .toFixed(2)
          .split(".")[0];
        let v1 = parseFloat(item_val % 1000)
          .toFixed(2)
          .split(".")[1];

        if (item_val === 0) {
          val_total = "-";
        } else {
          if (item_val / 1000 > 1) {
            if (item_val % 1000 < 100) {
              val = "0" + v0 + "," + v1;
            }
            if (item_val % 1000 < 10) {
              val = "00" + v0 + "," + v1;
            }
            if (item_val % 1000 >= 100) {
              val = v0 + "," + v1;
            }
            if (Math.floor(item_val / 1000) > 0) {
              val_total =
                Math.floor(item_val / 1000) + " " + val + " €";
            } else {
              val_total = " " + val + " €";
            }
          } else {
            val = v0 + "," + v1;
            if (Math.floor(item_val / 1000) > 0) {
              val_total =
                Math.floor(item_val / 1000) + " " + val + " €";
            } else {
              val_total = " " + val + " €";
            }
          }
        }
        Tab_TabRenteRachat.push(val_total);
        return true;
      });
      let infos = {

        civilite: this.valCiv == "1" ? "Monsieur" : "Madame",
        nom: this.state.nomPrec,
        prenom: this.state.lastnomPrec,
        projet: this.state.Proje_num,
        doc_ref: this.state.valRegleGestion,
        courtier: getInformations().split("|")[2],
        adresse: getInformations().split("|")[0],
        postal: "",
        ville: "",
        mail: getInformations().split("|")[4],
        code: getInformations().split("|")[1],
        date_de_naissance: this.state.DDN && this.state.DDN.split("-")[2] + "/" + this.state.DDN.split("-")[1] + "/" + this.state.DDN.split("-")[0],
        cotisation: this.state.valueCotisation,
        age: Age,
        montant_verse: ((this.state.valPrime === "") || (this.state.valPrime === null)) ? "0" : this.state.valPrime,
        montant_1: Tab_Rente[0] === "-" ? "--" : Tab_Rente[0] + "/mois",
        montant_2: Tab_Rente[1] === "-" ? "--" : Tab_Rente[1] + "/mois",
        montant_3: Tab_Rente[2] === "-" ? "--" : Tab_Rente[2] + "/mois",
        montant_4: Tab_Rente[3] === "-" ? "--" : Tab_Rente[3] + "/mois",
        montant_5: Tab_Rente[4] === "-" ? "--" : Tab_Rente[4] + "/mois",
        montant_6: Tab_TabRenteRachat[0] === "-" ? "--" : Tab_TabRenteRachat[0] + "/mois",
        montant_7: Tab_TabRenteRachat[1] === "-" ? "--" : Tab_TabRenteRachat[1] + "/mois",
        montant_8: Tab_TabRenteRachat[2] === "-" ? "--" : Tab_TabRenteRachat[2] + "/mois",
        montant_9: Tab_TabRenteRachat[3] === "-" ? "--" : Tab_TabRenteRachat[3] + "/mois",
        montant_10: Tab_TabRenteRachat[4] === "-" ? "--" : Tab_TabRenteRachat[4] + "/mois",
        montant_11: Tab_totale[0] === "-" ? "--" : Tab_totale[0] + "/mois",
        montant_12: Tab_totale[1] === "-" ? "--" : Tab_totale[1] + "/mois",
        montant_13: Tab_totale[2] === "-" ? "--" : Tab_totale[2] + "/mois",
        montant_14: Tab_totale[3] === "-" ? "--" : Tab_totale[3] + "/mois",
        montant_15: Tab_totale[4] === "-" ? "--" : Tab_totale[4] + "/mois"

      }
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(infos),
      };

      fetch(`${process.env.REACT_APP_URL}/pdf/1/2/2/2`, requestOptions)
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else if (response.status === 404) {
            this.props.navigate("/Page_404");
          } else {
            this.props.navigate("/Page_500");
          }
        })
        .then((data) => {
          let result = data.result;
          let name = data.name;
          for (var i = 0; i < result.length; i++) {
            let linkSource = result[i];
            let downloadLink = document.createElement("a");
            downloadLink.href = "data:application/pdf;base64," + linkSource;

            downloadLink.download = name[i];
            downloadLink.click();
            this.downloadStat()
          }
          this.setState({
            showLoadinBtnTel: false,
          });
        }).catch((error) => {
          this.props.navigate("/Page_500");
        });

    } else {
      this.downloadStat()
    }


  }


  downloadStat = () => {
    if (this.state.ch_2) {

      let linkSource = `${process.env.REACT_APP_URL_PDF}/doc3_NoticeinformationsDependance.pdf`;
      let downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.download = "NoticeinformationsDependance.pdf";
      downloadLink.click();
    }
    if (this.state.ch_3) {

      let linkSource = `${process.env.REACT_APP_URL_PDF}/doc2_FicheIPID.pdf`;
      let downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.download = "FicheIPID.pdf";
      downloadLink.click();

    }
    if (this.state.ch_4) {

      let linkSource = `${process.env.REACT_APP_URL_PDF}/doc4_Fiche_Info_Conseil.pdf`;
      let downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.download = "Fiche_Info_Conseil.pdf";
      downloadLink.click();

    }
  }

  OnContinue = () => {
    let data_simulation = [{ id: this.state.id_Proposition, Precedent: false }];
    let Data_dependance = localStorage.getItem("Data_Dependance");

    Data_dependance &&
      localStorage.setItem("Data_Dependance", JSON.stringify(data_simulation));

    this.props.navigate("/proposition");
  };

  OnPrecedent = (event) => {
    event.preventDefault();
    let tag = event.currentTarget.dataset.tag;
    this.setState({
      showModal: true,
      namePage: tag,
    });
  };

  OnchangeModal = () => {
    if (this.state.showModal) {
      this.setState({
        showModal: false,
      });
    }
  };

  render() {
    const {
      ShowBtn,
      ShowBtnAppliquer,
      checkbox_20E,
      VersementCheck,
      ShowBtn_tele,
      Div_Stat,
      DocTelecharge,
      ch_1,
      ch_2,
      ch_3,
      ch_4,
      showLoadinBtnTel,
    } = this.state;



    const btnLoading = showLoadinBtnTel ? "toggledLoading" : "";


    /* ---------------------------------------- FN Date ------------------------------------------------ */

    const date = new Date();
    const currentYear = date.getFullYear();
    const currentMonth = date.getMonth() + 1;
    const currentDate = date.getDate();





    /* ---------------------------------------------------------------------------------------- */

    const openSelectinteraction = this.state.interaction ? "active" : "";

    /*  ---------------------------------------- passage de paramètre --------------------------------------- */



    return (
      <>


        <Global />

        <div className="row boxTopTitle">
          <div className="col-xs-12 col-sm-9 "></div>
          <div className="col-xs-12 col-sm-3 ">
            <Button className="btn btn_file btn-default" onClick={() => docDownload("PlaquetteProduit_Dépendance.pdf")}>
              <img src={icoFile} className="icoFile" alt={icoFile} />
              Plaquette produit
            </Button>
          </div>
        </div>
        <div className="row content_section">
          <form id="formSimulation" onSubmit={this.OnShowDivStat} method="POST">
            <div className="row colcenter colcenter_3 r-15">
              {DocTelecharge && (
                <div className="col-2 boxStep active active_r">
                  <div className="content-box-step">
                    <div className="divNum">
                      <span>1.</span>
                    </div>
                    <span className="textBox">Simulation</span>
                  </div>
                </div>
              )}
              {!DocTelecharge && (
                <div className="col-2 boxStep active ">
                  <div className="content-box-step">
                    <div className="divNum">
                      <span>1.</span>
                    </div>
                    <span className="textBox">Simulation</span>
                  </div>
                </div>
              )}

              {DocTelecharge && <div className="cls_separation active"></div>}
              {!DocTelecharge && <div className="cls_separation"></div>}

              {DocTelecharge && (
                <div className="col-2 boxStep active active_l">
                  <div className="content-box-step ">
                    <div className="divNum">
                      <span>2.</span>
                    </div>
                    <span className="textBox">Projet</span>
                  </div>
                </div>
              )}
              {!DocTelecharge && (
                <div className="col-2 boxStep">
                  <div className="content-box-step ">
                    <div className="divNum">
                      <span>2.</span>
                    </div>
                    <span className="textBox">Projet</span>
                  </div>
                </div>
              )}

              <div className="cls_separation "></div>

              <div className="col-2 boxStep  ">
                <div className="content-box-step ">
                  <div className="divNum">
                    <span>3.</span>
                  </div>
                  <span className="textBox">Souscription</span>
                </div>
              </div>
            </div>

            <div className="row r-15">
              <div className="col-12 colstart mt-24 mb-24">
                <div className="col-xs-12 col-sm-8">
                  <h2 className="textBudgetv2">Votre client</h2>
                </div>
                <div className="col-xs-12 xs-offset-0 col-sm-12 sm-offset-0 col-md-4 offset-md-0 colend ">
                  <Select
                    id="select-interaction"
                    onClose={this.handleRegleGestion}
                    onOpen={this.handleOpen}
                    displayEmpty
                    defaultValue={this.state.valRegleGestion}
                    className={`selectInput selectInput_v2 ${openSelectinteraction}`}
                    value={this.state.valRegleGestion}
                  >
                    <MenuItem value="20/20"> 20/20 </MenuItem>
                    <MenuItem value="30/15">30/15 </MenuItem>
                  </Select>

                  <div
                    className={`interaction ${openSelectinteraction}`}
                    onClick={this.onHideSelectinteraction}
                  >
                    <img src={icon_filtre_off} alt={icon_filtre_off} />
                  </div>
                </div>
              </div>
            </div>

            <div className="row  r-15">
              <div className="col-xs-12 col-md-3 divInput  mb-40">
                <Box>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Civilité*
                    </InputLabel>
                    <Select
                      labelId="Civilité*"
                      className="select civ"
                      label="-Sélectionner-"
                      onClose={this.handleCloseCiv}
                      onOpen={this.handleOpen}
                      value={this.state.valCiv}
                      name="civilite"
                    >
                      <MenuItem value={"1"}>Monsieur</MenuItem>
                      <MenuItem value={"2"}>Madame</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </div>
              <div className="col-xs-12 col-md-3  mb-xs-15 mb-40">
                <TextField
                  id=""
                  name="nom"
                  label="Nom*"
                  type="text"
                  className=""
                  onKeyUp={this.onShowSave}
                  onChange={(e) => this.validateName(e)}
                  value={this.state.nomPrec}
                />
                {this.state.errorMsgName && (
                  <span className="errorMsg errorMsgStyle1">
                    (*)Le format du champ renseigné n’est pas valide
                  </span>
                )}
                {this.state.errorMsgNameSize && (
                  <span className="errorMsg errorMsgStyle1">
                    (*)Votre nom doit comporter au moins 2 caractères et un
                    maximum de 51 caractères.
                  </span>
                )}
              </div>
              <div className="col-xs-12 col-md-3  mb-xs-15 mb-40">
                <TextField
                  id=""
                  label="Prénom*"
                  type="text"
                  name="prenom"
                  className="prenom"
                  onKeyUp={this.onShowSave}
                  onChange={(e) => this.validateLastName(e)}
                  value={this.state.lastnomPrec}
                />
                {this.state.errorMsgLastName && (
                  <span className="errorMsg errorMsgStyle1">
                    (*)Le format du champ renseigné n’est pas valide
                  </span>
                )}
                {this.state.errorMsgLastNameSize && (
                  <span className="errorMsg errorMsgStyle1">
                    (*)Votre prénom doit comporter au moins 2 caractères et un
                    maximum de 51 caractères.
                  </span>
                )}
              </div>
            </div>

            <div className="row r-15 ">
              <div className="col-xs-12 col-md-3 mb-xs-15">
                <TextField
                  id="TextFieldDDn"
                  label="Date de naissance*"
                  name="date_de_naissance"
                  type="date"
                  onChange={this.onchangeDate}
                  className="dateNaissance"
                  disabled={this.state.disabledDDN}
                  value={this.state.DDN}
                  inputProps={{
                    max: this.state.dateMax,
                    min: this.state.dateMin
                  }}
                />
                {this.state.errorMsgDateNais && (
                  <span className="errorMsg errorMsgStyle1">
                    (*)La date de naissance est invalide. L'âge à la date
                    d'effet du contrat doit être au minimum de 18 ans et au
                    maximum de 74 ans (inclus).
                  </span>
                )}
                {this.state.errorMsgDateNaisInvalid && (
                  <span className="errorMsg errorMsgStyle1">
                    (*)L'âge de l'assuré est devenue invalide.
                  </span>
                )}

                {this.state.errorMsginvalideDDN && (
                  <span className="errorMsg errorMsgStyle1">
                    (*)La date de naissance est invalide. L'âge à la date
                    d'effet du contrat doit être au minimum de 18 ans et au
                    maximum de 74 ans (inclus).
                  </span>
                )}
              </div>
              <div className="col-xs-12 col-md-6">
                  <div className="contentGris3">
                    <h5 className="infoH5">
                      <span>i</span>L'âge à la date d'effet du contrat doit être au minimum de 18 ans et au maximum de 74 ans par différence de millésime (millésime = année date d'effet - année de naissance).
                    </h5>
                  </div>
              </div>
            </div>

            <div className="row r-15">
              <div className="col-xs-12 col-md-6 ">
                <div className=" boxCheckedyesno">
                  <div className="toggle box-toggle">
                    <input
                      type="checkbox"
                      name="isAdherent"
                      value={checkbox_20E}
                      checked={checkbox_20E}
                      onChange={(e) => this.OnChangeCheckAdherent(e)}
                      className="inputCheckbox"
                    /* checked={checkbox_20E} */
                    />
                  </div>
                  Votre client est-il déjà adhérent REPAM ?
                </div>
              </div>
            </div>
            {!this.state.checkbox_20E && (
              <>
              <div className="row r-15 Infoadherent">
                <div className="col-xs-12 col-sm-12 ">
                  <div className="contentGris3">
                    <h5 className="infoH5">
                      <span>i</span>
                      Droit d'entrée à l'association des assurés REPAM pour un
                      nouvel adhérent : 20 € une fois, à la souscription
                    </h5>
                  </div>
                </div>
              </div>

              <br />
              </>
            )}

            <div className="row r-15">
              <div className="col-12 colstart">
                <h2 className="textBudgetv2">Le contrat</h2>
              </div>
            </div>

            <div className="row  r-15">
              <div className="col-xs-12 col-md-6 colstart">
                <div className="contentBoxGris">
                  <p>Comment fonctionne le contrat Dépendance REPAM ?</p>
                  <ModalDocs />
                </div>
              </div>
            </div>
            <div className="row r-15 ">
              <div className="col-12">
                <hr className="hr w-100" />
              </div>
            </div>
            <div className="row r-15 ">
              <div className="col-xs-12 col-md-6">
                <div className="contentGris2">

                  <div className="row r-15 ">
                    <div className="col-xs-12 col-md-6 divTxB">
                      Cotisation mensuelle*
                    </div>
                    <div className="col-xs-12 col-md-6 divInput colstart ">
                      <div className="col-xs-12 col-md-12">
                        <Box>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              Cotisation mensuelle
                            </InputLabel>
                            <Select
                              labelId="select-Cotisation"
                              id="demo-simple-select"
                              className="cot"
                              name="cotisation"
                              label=""
                              value={this.state.valueCotisation}
                              onClose={this.changeCotisation}
                              onOpen={this.handleOpen}
                              onChange={(e) =>
                                this.setState({
                                  valueCotisation: e.target.value,
                                })
                              }
                            >
                              <MenuItem value="20">20 €</MenuItem>
                              <MenuItem value="40">40 €</MenuItem>
                              <MenuItem value="60">60 €</MenuItem>
                              <MenuItem value="80">80 €</MenuItem>
                              <MenuItem value="100">100 €</MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <div className="row r-15 ">
              <div className="col-12">
                <hr className="hr w-100" />
              </div>
            </div>

            <div className="row r-15">
              <div className="col-xs-12 col-md-6 ">
                <div className="row r-15 ">
                  <div className=" boxCheckedyesno">
                    <div className="toggle box-toggle">
                      <input
                        type="checkbox"
                        value={VersementCheck}
                        checked={VersementCheck}
                        onChange={(e) => this.OnChangeChecVersement(e)}
                        className="inputCheckbox"
                      />
                    </div>
                    Votre client souhaite-t-il réaliser un versement
                    complémentaire pour acheter des unités de rente
                    additionnelles ?
                  </div>
                </div>
                {VersementCheck && (
                  <div className="row r-15 ">
                    <div className="contentGris2">
                      <div className="row r-15">
                        <div className="col-xs-12 col-md-6 divTxB">
                          Versement complémentaire souhaité
                        </div>
                        <div className="col-xs-12 col-md-6 divInput ">
                          <TextField
                            id="TextFieldPrime"
                            label="Versement complémentaire"
                            variant="outlined"
                            value={this.state.valPrime}
                            type="number"
                            className="inputVersement"
                            onChange={(e) => this.onchangePrime(e)}
                          />
                          <span className="units-value" id="unitsValue">
                            €
                          </span>
                          {this.state.errorMsgPrimeInitiale && (
                            <>
                              <span
                                className="units-value error-valide"
                                id="unitsValue"
                              >
                                €
                              </span>
                              <span className="errorMsg errorMsgStyle1">
                                Le montant du versement est limité à 20 fois le
                                montant de la cotisation annuelle dans la limite
                                de 6000€.
                              </span>
                            </>
                          )}
                          {this.state.errorMsgPrimeInitialeCoti && (
                            <>
                              <span
                                className="units-value error-valide"
                                id="unitsValue"
                              >
                                €
                              </span>
                              <span className="errorMsg errorMsgStyle1">
                                Veuillez introduire le montant de versement
                                souhaité
                              </span>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-xs-12 col-md-6" id="Section_statistique">
                {this.state.Div_Infos && (
                  <div className="contentGris3 mt-24">
                    <h5 className="infoH5 infoH5Versement">
                      <span>i</span>Ce versement complémentaire donnera droit à
                      votre adhérent à un montant de rente qui viendra s'ajouter
                      à la rente acquise grâce aux versements de ses
                      cotisations. Le montant de ce versement est limité à 20
                      fois le montant de la cotisation annuelle dans la limite
                      de 6000€.
                    </h5>
                  </div>
                )}
              </div>
            </div>

            {/*    <-------------------------------------------------- Div Stat -----------------------------------------------------------> */}
            {Div_Stat && (
              <div className="row r-15 p-relative">
                <div className="col-12 ">
                  <div className="DivStat">
                    <div className="row r-15 textTitle">
                      <div className="col-12">
                        Simulation de la rente mensuelle
                      </div>
                    </div>

                    <div className="row r-15">
                      <div className="col-12">
                        <div className="divTableCotisation">
                          <div className="row r-15 box3">
                            <div className="clsTitle box3">
                              <span className="text1">Cotisation :</span>
                              <span className="text2">
                                {this.state.valueCotisation}€/Mois
                              </span>
                            </div>
                          </div>
                          <div className="row r-15">
                            <div className="col-xs-12 offset-xs-0 col-md-12 box3">
                              <h3 className="text1">
                                Simulation donnée à titre indicatif*
                              </h3>
                            </div>
                          </div>
                         { /*<div className="row r-15">
                            <div className="col-xs-12 offset-xs-0 col-md-12 box3">
                            <h5 className="infoH5" style={{color:'red'}}>
                            Cette simulation se base sur l’âge de l’assuré et sur une prise d'effet soit au 1er jour du mois prochain (pour une simulation jusqu’au 15 du mois) soit au 1er jour du mois suivant (pour une simulation à partir du 16 du mois). Le montant de la rente estimé est donc donné à titre indicatif et évolue au cours d’un même mois. La prise d’effet pourra se faire au plus tôt le premier jour du mois prochain et le premier de chaque mois.
                            </h5>
                            <br />
                            </div>
                          </div>*/}
                          <div className="row r-15">
                            <div className="clsTable box3 table-responsive">
                              <table className="table tableRentes tableJob">
                                <tr>
                                  <th rowSpan="2">TYPE DE DÉPENDANCE</th>
                                  <th colSpan="5">
                                    ÂGE DE RECONNAISSANCE DE LA DEPENDANCE
                                  </th>
                                </tr>
                                <tr>
                                  <td className="td1"> 65 ans </td>
                                  <td className="td1"> 70 ans </td>
                                  <td className="td1"> 75 ans </td>
                                  <td className="td1"> 80 ans </td>
                                  <td className="td1"> 85 ans </td>
                                </tr>
                                <tr>
                                  <th>
                                    DÉPENDANCE PARTIELLE
                                    <Tippy content="La dépendance partielle correspond à un versement de 25% de la rente">
                                      <HelpRounded className="iconhelpR ms-2" />
                                    </Tippy>
                                  </th>
                                  {this.renderSimulation()}
                                </tr>
                                <tr>
                                  <th>
                                    DÉPENDANCE TOTALE
                                    <Tippy content="La dépendance totale correspond à un versement de 100% de la rente">
                                      <HelpRounded className="iconhelpR ms-2" />
                                    </Tippy>
                                  </th>
                                  {this.renderSimulationtotale()}
                                </tr>
                              </table>
                              <p>
                                <small style={{fontSize: '9.5px'}}>
                                  *Simulation réalisée sur la base de
                                  l'âge de l'assuré et de la valeur d'acquisition de l'unité de rente en
                                  vigueur en 2023. Elle tient compte des éventuelles unités de rente additionnelles achetées.
                                  <br /><br />
                                </small>

                                <small style={{fontSize: '10px'}}>
                                  Elle peut varier lègèrement en fonction du jour de la simulation du projet :
                                <br />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  • avant le 15 du mois : rente calculée sur la base d'une prise d'effet théorique le 1er du mois suivant (M+1).
                                <br />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  • à partir du 16 du mois : rente calculée sur la base d'une prise d'effet théorique le 1er du mois d'après (M+2).
                                </small>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row r-15">
                      <div className="col-xs-12 col-md-9">
                        <div className="divStat">
                          {this.renderSimulationgraphe()}
                        </div>
                      </div>
                      <div className="col-xs-12 col-md-3 box_blue">
                        <h2>[+++] ASSISTANCE INCLUSE DANS VOTRE CONTRAT</h2>
                        <h3>
                          <img src={assistance} alt={assistance} />
                          ASSISTANCE
                        </h3>
                        <p>
                          Des services pour l'aidé et pour l'aidant tout au long
                          de la vie du contrat, notamment :
                          <br />
                          <b>• Pour faciliter les démarches : </b>
                          <br />
                          information juridique, conseil social, accompagnement
                          budgétaire <br />
                          <b>• Pour favoriser la mobilité :</b>
                          <br />
                          intervention d’un éducateur en activité physique et
                          d’un ergothérapeute <br />
                          <b>• Pour soulager le quotidien :</b>
                          <br />
                          soutien psychologique, aide à domicile, auxiliaire de
                          vie, portage de repas, entretien de jardin, livraison
                          de courses
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <--------------------------------------------------Start Div Document à télécharger----------------------------------------------------------> */}
                {DocTelecharge && (
                  <div className="row blocDocTelecharge">
                    <div className="row r-15">
                      <hr className="hr w-100" />
                    </div>
                    <div className="row">
                      <h3 className="textBudget">Documents à télécharger</h3>
                    </div>
                    <div className="row listeChoixDocDnw">
                      <div className="col-xs-12 col-md-5">
                        <div className="boxCeckbox">
                          <input
                            value="Projet"
                            name="Val_Doc_Telecharge"
                            id="ch_1"
                            type="checkbox"
                            onChange={() =>
                              this.setState({ ch_1: !this.state.ch_1 })
                            }
                            checked={this.state.ch_1}
                            className="formCheck"
                          />
                        </div>
                        <div className="divCheck">
                          <text className="textDT">Projet</text>
                        </div>
                      </div>
                      {/* <--------------> */}
                      <div className="col-xs-12 col-md-2"></div>
                      {/* <--------------> */}
                      <div className="col-xs-12 col-md-5">
                        <div className="boxCeckbox">
                          <input
                            value="IPID Assistance"
                            name="Val_Doc_Telecharge"
                            id="ch_3"
                            type="checkbox"
                            onChange={() =>
                              this.setState({ ch_3: !this.state.ch_3 })
                            }
                            className="formCheck"
                          />
                        </div>
                        <div className=" col-5 divCheck">
                          <text className="textDT">IPID Dépendance</text>
                        </div>
                      </div>
                    </div>
                    <div className="row listeChoixDocDnw">
                      <div className="col-xs-12 col-md-5">
                        <div className="boxCeckbox">
                          <input
                            value="Notice d'informations"
                            name="Val_Doc_Telecharge"
                            id="ch_2"
                            type="checkbox"
                            onChange={() =>
                              this.setState({ ch_2: !this.state.ch_2 })
                            }
                            className="formCheck"
                          />
                        </div>
                        <div className=" col-5 divCheck">
                          <text className="textDT">Notice d'information</text>
                        </div>
                      </div>
                      {/* <--------------> */}
                      <div className="col-xs-12 col-md-2"></div>
                      {/* <--------------> */}
                      <div className="col-xs-12 col-md-5">
                        <div className="boxCeckbox">
                          <input
                            value="Fiche d'information et de conseil"
                            name="Val_Doc_Telecharge"
                            id="ch_"
                            type="checkbox"
                            onChange={() =>
                              this.setState({ ch_4: !this.state.ch_4 })
                            }
                            className="formCheck"
                          />
                        </div>
                        <div className=" col-5 divCheck">
                          <text className="textDT">
                            Fiche d'information et de conseil
                          </text>
                        </div>
                      </div>
                      {/* <-------------------------------------------> */}
                    </div>

                    {!ch_1 && !ch_2 && !ch_3 && !ch_4 ? (
                      <div className="row row_btn">
                        <Button
                          variant="contained"
                          className="btn btnActiveTele btn-default "
                          disabled
                        >
                          Télécharger
                        </Button>
                      </div>
                    ) : (
                      <div className="row row_btn">
                        <Button
                          variant="contained"
                          className={`btnActiveTele btn-default btn_form ${btnLoading}`}
                          onClick={() =>
                            this.handleClickPdf2()
                          }
                        >
                          Télécharger<div className="ld-spin "></div>
                        </Button>
                      </div>
                    )}

                    <div className="row r-15">
                      <hr className="hr w-100" />
                    </div>

                    <div className="row r-15 navigationSection">
                      <div className="col-xs-12 col-md-12 offset-md-12 colend">
                        <Link
                          className="btn btn-default"
                          variant="btn"
                          to={`/dependance/${getToken()}`}
                        >
                          Précédent
                        </Link>
                        <Button
                          className="btn btnActive btn-default"
                          onClick={this.OnContinue}
                        >
                          Continuer{" "}
                        </Button>
                      </div>
                    </div>
                  </div>
                )}

                {/* *********************************** end *********************************** */}

                {ShowBtn_tele && (
                  <div className="row r-15 navigationSection ">
                    <div className="col-xs-12 col-md-12 offset-md-12 colend">
                      <a
                        href="/"
                        className="btn btnActive btn-default"
                        onClick={this.onchnageEtatSumtoProjet}
                      >
                        Télécharger mon projet
                      </a>
                    </div>
                  </div>
                )}
              </div>
            )}
            {/*     <---------------------------------------------- Div Btn Info----------------------------------------------------------> */}

            <div className="row r-15 navigationSection ">
              {!Div_Stat && (
                <div className="col-xs-12 offset-xs-0 col-md-126 offset-md-12 colend">

                  <Link
                    className="btn btn-default"
                    variant="btn"
                    to={`/dependance/${getToken()}`}
                  >
                    Précédent
                  </Link>
                  {ShowBtn ? (
                        <Button
                          type="submit"
                          className="btn btn-default btnActive"
                        >
                          Enregistrer et continuer
                        </Button>
                      ) : (
                        <Tippy
                          content="Un ou plusieurs champs sont en erreur ou ne sont pas renseignés."
                          maxWidth={180}
                        >
                          <Button className="btn btn-default " id="disabled">
                            Enregistrer et continuer
                          </Button>
                        </Tippy>
                      )}





                </div>
              )}
              {this.state.errorServer && (
                <span className="errorServer errorMsg errorMsgStyle1">
                  Un problème est survenu, veuillez patienter un instant ou
                  réessayer ultérieurement.
                </span>
              )}
            </div>
          </form>
        </div>

        <ModalExpire show={this.state.modalExpire} />
        {this.state.modalServeur ? <ModalServeur handleCloseModalServeur={() => this.handleCloseModalServeur()} /> : null}

        {this.state.showModal && (
          <ModalQuit
            page={this.state.namePage}
            OnchangeModal={this.OnchangeModal}
          />
        )}
        {this.state.loading && (
          <div className="loading">
            <div className="boxLoading">
              <img src={rotation} alt={rotation} className="svgrotation" />
              <img src={repamSvg} alt={repamSvg} />
            </div>
          </div>
        )}
      </>
    );
  }
}
export default withRouter(Simulation);
