import React, { Component } from "react";
import ReactRom from 'react-dom'
import { withRouter } from "./whitRouter";
import ModalExpire from "./CommonComponent/modalExpire";
import ModalValidation from "./ComponentRecapitulatif/modalValidation";
import "./ComponentRecapitulatif/Recapitulatif.css";
import Button from "@mui/material/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import icoFile from "../../assets/img/icoFile.svg";
import {
  getToken,
  getInformations, 
  docDownload,
} from "../../Context/ApiContext";
import ModalServeur from "./CommonComponent/modalServeur";
/* IMPORT STYLE Global */
import { Global } from "./ComponentRecapitulatif/Global";

class Recapitulatif extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalExpire: false,
      modalServeur: false,
      btnTelecharge: false,
      showLoadinBtnTel: false,
      checkedInpt: 0,
      show: false,
      disabledBtn: false,
      hStep: false,
      civilite: "",
      nom: "",
      prenom: "",
      date_de_naissance: "",
      id_Proposition: "",
      id_souscrption: "",
      etat: "",
      isAdherent: "",
      date_effet_souhaite: "",
      cotisation: "",
      isVersement: "",
      valRegleGestion: "",
      situation_familiale: "",
      departement_de_naissance: "",
      Ville: "",
      ville_de_naissance: "",
      code_postal: "",
      numero_et_voie: "",
      pays_de_naissance: "",
      nomNaissance: "",
      periodicite: "",
      prelevement: "",
      ch_1: true,
      ch_2: false,
      ch_3: true,
      ch_4: true,
      ch_5: true,
      ch_6: true,
      isPrime: false,
      Prime: "",
      Rente: "",
      dataStorage: [],
      tabDep: [
        "Ain",
        "Aisne",
        "Allier",
        "Alpes-de-Haute-Provence",
        "Hautes-Alpes",
        "Alpes-Maritimes",
        "Ardèche",
        "Ardennes",
        "Ariège",
        "Aube",
        "Aude",
        "Aveyron",
        "Bouches-du-Rhône",
        "Calvados",
        "Cantal",
        "Charente",
        "Charente-Maritime",
        "Cher",
        "Corrèze",
        "Côte-d'Or",
        "Côtes-d'Armor",
        "Creuse",
        "Dordogne",
        "Doubs",
        "Drôme",
        "Eure",
        "Eure-et-Loir",
        "Finistère",
        "Corse-du-Sud",
        "Haute-Corse",
        "Gard",
        "Haute-Garonne",
        "Gers",
        "Gironde",
        "Hérault",
        "Ille-et-Vilaine",
        "Indre",
        "Indre-et-Loire",
        "Isère",
        "Jura",
        "Landes",
        "Loir-et-Cher",
        "Loire",
        "Haute-Loire",
        "Loire-Atlantique",
        "Loiret",
        "Lot",
        "Lot-et-Garonne",
        "Lozère",
        "Maine-et-Loire",
        "Manche",
        "Marne",
        "Haute-Marne",
        "Mayenne",
        "Meurthe-et-Moselle",
        "Meuse",
        "Morbihan",
        "Moselle",
        "Nièvre",
        "Nord",
        "Oise",
        "Orne",
        "Pas-de-Calais",
        "Puy-de-Dôme",
        "Pyrénées-Atlantiques",
        "Hautes-Pyrénées",
        "Pyrénées-Orientales",
        "Bas-Rhin",
        "Haut-Rhin",
        "Rhône",
        "Haute-Saône",
        "Saône-et-Loire",
        "Sarthe",
        "Savoie",
        "Haute-Savoie",
        "Paris",
        "Seine-Maritime",
        "Seine-et-Marne",
        "Yvelines",
        "Deux-Sèvres",
        "Somme",
        "Tarn",
        "Tarn-et-Garonne",
        "Var",
        "Vaucluse",
        "Vendée",
        "Vienne",
        "Haute-Vienne",
        "Vosges",
        "Yonne",
        "Territoire de Belfort",
        "Essonne",
        "Hauts-de-Seine",
        "Seine-Saint-Denis",
        "Val-de-Marne",
        "Val-d'Oise",
        "Guadeloupe",
        "Martinique",
        "Guyane",
        "La Réunion",
        "Mayotte",
      ],
      id_pro: "",
      APE: "",
      AFE: "",
      CheckClient: "",
      tel: "",
      mail: "",
      communeNaissance: "",
      projet: "",
      data_yt: [],
      rente_rachat: "",
    };
  }
  componentDidMount() {

     
    ReactRom.render(
      <></>, document.getElementById('sessionText'))
    /* ------------------------------------ localStorage ------------------------------------------------- */
    let Data_dependance = localStorage.getItem("Data_Dependance");
    let data_visualiser = localStorage.getItem("data_visualiser");

    if (Data_dependance) {
      let parsed_dependance = JSON.parse(Data_dependance);
      let id = "";
      parsed_dependance.map((i) => {
        this.setState({ id_pro: i.id });
        id = i.id;
        return true;
      });

      fetch(`${process.env.REACT_APP_URL}/proposition/${id}/${getToken()}`)
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else if (response.status === 404) {
            this.props.navigate("/Page_404");
          } else {
            this.props.navigate("/Page_500");
          }
        })
        .then((data) => {
          if (data.code == "-2") {
            this.setState({
              loading: false,
              modalExpire: true,
            });
          } else if (data.code == "-1") {
            this.setState({
              modalServeur: true,
              loading: false,
            })

          } else {
            this.setState({
              modalExpire: false,
            });
            let items = data.proposition;
            this.setState({
              civilite: items.simulation["civilite"] === "1" ? "M." : "Mme",
              nom: items.client["nom"],
              prenom: items.client["prenom"],
              isPrime: items.simulation["isVersement"],
              ch_5: items.simulation["isVersement"],
              Prime: items.simulation["value_Versement"],
              Rente: items.simulation["rente_rachat"], ///
              date_de_naissance:
                items.client["date_de_naissance"].split("-")[2] +
                "-" +
                items.client["date_de_naissance"].split("-")[1] +
                "-" +
                items.client["date_de_naissance"].split("-")[0],
              id_Proposition: items.id,
              id_souscrption: items.souscription["id"],
              etat: items.etat,
              isAdherent: items.simulation["isAdherent"],
              date_effet_souhaite:
                items.souscription["date_effet_souhaite"].split("-")[2] +
                "-" +
                items.souscription["date_effet_souhaite"].split("-")[1] +
                "-" +
                items.souscription["date_effet_souhaite"].split("-")[0],
              cotisation: items.simulation["cotisation"],
              isVersement: items.simulation["isVersement"],
              valRegleGestion: items.simulation["ref"],
              situation_familiale: items.souscription["situation_familiale"],
              departement_de_naissance:
                items.souscription["departement_de_naissance"],
              code_postal: items.souscription["code_postal"],
              Ville: items.souscription["ville"],
              ville_de_naissance: items.souscription["ville_de_naissance"],
              numero_et_voie: items.souscription["numero_et_voie"],
              periodicite: items.souscription["periodicite"],
              prelevement: items.souscription["jour_de_prelevement"],
              pays_de_naissance: items.souscription["pays_de_naissance"],
              nomNaissance: items.souscription["nom_de_naissance"],
              APE: items.souscription["isAPF"],
              AFE: items.souscription["isAFE"],
              CheckClient: items.souscription["isTNS"],
              tel: items.souscription["mobile"],
              mail: items.souscription["email"],
              communeNaissance: items.souscription["ville_de_naissance"],
              projet: items.reference,
            });
          }
        })
        .catch((error) => {
          this.props.navigate("/Page_500");
        });
    } else if (data_visualiser) {
      let parsed_visualiser = JSON.parse(data_visualiser);
      let id = "";
      parsed_visualiser.map((i) => {
        this.setState({ id_pro: i.id });
        id = i.id;
        return true;
      });

      fetch(`${process.env.REACT_APP_URL}/proposition/${id}/${getToken()}`)
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else if (response.status === 404) {
            this.props.navigate("/Page_404");
          } else {
            this.props.navigate("/Page_500");
          }
        })
        .then((data) => {
          if (data.code == "-2") {
            this.setState({
              loading: false,
              modalExpire: true,
            });
          } else if (data.code == "-1") {
            this.setState({
              modalServeur: true,
              loading: false,
            })

          } else {
            this.setState({
              modalExpire: false,
            });
            let items = data.proposition;

            this.setState({
              civilite: items.simulation["civilite"] === "1" ? "M." : "Mme",
              nom: items.client["nom"],
              prenom: items.client["prenom"],
              isPrime: items.simulation["isVersement"],
              ch_5: items.simulation["isVersement"],
              Prime: items.simulation["value_Versement"],
              Rente: items.simulation["rente_rachat"], ///
              date_de_naissance:
                items.client["date_de_naissance"].split("-")[2] +
                "-" +
                items.client["date_de_naissance"].split("-")[1] +
                "-" +
                items.client["date_de_naissance"].split("-")[0],
              id_Proposition: items.id,
              id_souscrption: items.souscription["id"],
              etat: items.etat,
              isAdherent: items.simulation["isAdherent"],
              date_effet_souhaite:
                items.souscription["date_effet_souhaite"].split("-")[2] +
                "-" +
                items.souscription["date_effet_souhaite"].split("-")[1] +
                "-" +
                items.souscription["date_effet_souhaite"].split("-")[0],
              cotisation: items.simulation["cotisation"],
              isVersement: items.simulation["isVersement"],
              valRegleGestion: items.simulation["ref"],
              situation_familiale: items.souscription["situation_familiale"],
              departement_de_naissance:
                items.souscription["departement_de_naissance"],
              code_postal: items.souscription["code_postal"],
              Ville: items.souscription["ville"],
              ville_de_naissance: items.souscription["ville_de_naissance"],
              numero_et_voie: items.souscription["numero_et_voie"],
              periodicite: items.souscription["periodicite"],
              prelevement: items.souscription["jour_de_prelevement"],
              pays_de_naissance: items.souscription["pays_de_naissance"],
              nomNaissance: items.souscription["nom_de_naissance"],
              APE: items.souscription["isAPF"],
              AFE: items.souscription["isAFE"],
              CheckClient: items.souscription["isTNS"],
              tel: items.souscription["mobile"],
              mail: items.souscription["email"],
              communeNaissance: items.souscription["ville_de_naissance"],
              projet: items.reference,
            });
          }
        })
        .catch((error) => {
          this.props.navigate("/Page_500");
        });
    }
  }
  /* <---------------------  close modal serveur error   ------------------> */
  handleCloseModalServeur = () => {
    this.setState({
      modalServeur: false
    })
  }
  /* ------------------------------ FN_PDF ------------------------------ */
  handleClickPdf() {
    this.setState({
      showLoadinBtnTel: true,
    });
    /* ************************************** Data Doc 5 *********************************** */

    if ((this.state.ch_1) || (this.state.ch_5)) {

      let doc5_projet = this.state.projet; ///
      let doc5_ref = this.state.valRegleGestion;
      let doc5_civilite =
        this.state.civilite === "M." ? "Monsieur" : "Madame";
      let doc5_prenom = this.state.prenom;
      let doc5_nom = this.state.nom;
      let doc5_naissance =
        this.state.date_de_naissance.split("-")[0] +
        "/" +
        this.state.date_de_naissance.split("-")[1] +
        "/" +
        this.state.date_de_naissance.split("-")[2];
      let doc5_nomNaissance = this.state.nomNaissance;
      let doc5_pays = this.state.pays_de_naissance;
      let doc5_communeNaissance = this.state.communeNaissance; //
      let doc5_depN = this.state.departement_de_naissance;
      let doc5_adresse_c = this.state.numero_et_voie;
      let doc5_codeP = this.state.code_postal;
      let doc5_mail_c = this.state.mail; //
      let doc5_ville_c = this.state.Ville;
      let doc5_SituationFamiliale = this.state.situation_familiale;
      let doc5_tel = this.state.tel; //
      let doc5_cotisation = this.state.cotisation;
      let doc5_isAdherent = this.state.isAdherent;
      let doc5_APE = this.state.APE; //
      let doc5_AFE = this.state.AFE; //
      let doc5_CheckClient = this.state.CheckClient; //

      let doc5_dde =
        this.state.date_effet_souhaite.split("-")[0] +
        "/" +
        this.state.date_effet_souhaite.split("-")[1] +
        "/" +
        this.state.date_effet_souhaite.split("-")[2];
      let doc5_cot = this.state.cotisation;
      let doc5_frais = this.state.periodicite === "Mensuelle" ? "1,50 €" : "0 €";
      let doc5_dda = this.state.isAdherent ? "0 €" : "20 €";
      let doc5_cot_totale = this.state.cotisation * 12 + " € /an";
      let doc5_periodicite = this.state.periodicite;
      let doc5_prelevement = this.state.prelevement;

      let infos = {

        projet: doc5_projet,
        doc_ref: doc5_ref,
        courtier: getInformations().split("|")[2],
        adresse: getInformations().split("|")[0],
        postal: "",
        ville: "",
        mail: getInformations().split("|")[4],
        code: getInformations().split("|")[1],
        civilite: doc5_civilite,
        nom: doc5_nom,
        prenom: doc5_prenom,
        nomNaissance: doc5_nomNaissance,
        date_de_naissance: doc5_naissance,
        communeNaissance: doc5_communeNaissance,
        DepartementDeNaissance: doc5_depN,
        adresse_c: doc5_adresse_c,
        pays: doc5_pays,
        codeP: doc5_codeP,
        ville_c: doc5_ville_c,
        num_tel: doc5_tel,
        mail_c: doc5_mail_c,
        SituationFamiliale: doc5_SituationFamiliale,
        cotisation: doc5_cotisation,
        isAdherent: doc5_isAdherent,
        ape: doc5_APE,
        afe: doc5_AFE,
        checkClient: doc5_CheckClient,
        date_effet_souhaite: doc5_dde,
        cotisation: doc5_cot,
        frais_de_fractionnement: doc5_frais,
        dda: doc5_dda,
        cotisation_annuelle_totale: doc5_cot_totale,
        periodicite: doc5_periodicite,
        prelevement: doc5_prelevement,
        montant: this.state.Prime,
        rente: this.state.Rente

      }
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(infos),
      };
      let docCh1 = this.state.ch_1 ? 1 : 2
      let docCh5 = this.state.ch_5 ? 1 : 2
      if (!this.state.isPrime) {
        docCh5 = 2
      }
      fetch(`${process.env.REACT_APP_URL}/pdf/2/${docCh1}/${docCh5}/2`, requestOptions)
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else if (response.status === 404) {
            this.props.navigate("/Page_404");
          } else {
            this.props.navigate("/Page_500");
          }
        })
        .then((data) => {
          console.log(data)
          let result = data.result;
          let name = data.name;
          for (var i = 0; i < result.length; i++) {
            let linkSource = result[i];
            let downloadLink = document.createElement("a");
            downloadLink.href = "data:application/pdf;base64," + linkSource;

            downloadLink.download = name[i];
            downloadLink.click();
            this.setState({
              showLoadinBtnTel: false,
            });
          }

          this.downloadStat()
          this.setState({
            showLoadinBtnTel: false,
          });
        }).catch((error) => {
          console.log(error)
          this.props.navigate("/Page_500");
        });
    } else {
      this.downloadStat()
    }


    /* ************************************** Data Doc 6 *********************************** */
    const base_url = `${process.env.REACT_APP_URL}/souscription/${this.state.id_souscrption
      }/${getToken()}`;
    const data = {
      etat: "souscription2",
    };
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data)
    };
    fetch(base_url, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else if (response.status === 404) {
          this.props.navigate("/Page_404");
        } else {
          this.props.navigate("/Page_500");
        }
      })
      .then((data) => {
        if (data.code == "-2") {
          this.setState({
            loading: false,
            modalExpire: true,
          });
        } else if (data.code == "-1") {
          this.setState({
            modalServeur: true,
            loading: false,
          })

        } else {
          this.setState({
            modalExpire: false,
            showLoadinBtnTel: false,
          });
        }

      })
      .catch((error) => {

        this.props.navigate("/Page_500");

      });
  }
  downloadStat = () => {
    if (this.state.ch_2) {
      let linkSource = `${process.env.REACT_APP_URL_PDF}/doc3_NoticeinformationsDependance.pdf`;
      let downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.download = "NoticeinformationsDependance.pdf";
      downloadLink.click();

    }
    if (this.state.ch_3) {

      let linkSource = `${process.env.REACT_APP_URL_PDF}/doc2_FicheIPID.pdf`;
      let downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.download = "FicheIPID.pdf";
      downloadLink.click();

    }
    if (this.state.ch_4) {
      let linkSource = `${process.env.REACT_APP_URL_PDF}/doc4_Fiche_Info_Conseil.pdf`;
      let downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.download = "Fiche_Info_Conseil.pdf";
      downloadLink.click();

    }
    if (this.state.ch_6) {
      let linkSource = `${process.env.REACT_APP_URL_PDF}/Mandat_Sepa.pdf`;
      let downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.download = "Mandat_Sepa.pdf";
      downloadLink.click();

    }
  }

  OnPrecedent = (event) => {
    event.preventDefault();

    let Data_dependance = localStorage.getItem("Data_Dependance");
    Data_dependance &&
      localStorage.setItem(
        "Data_Dependance",
        JSON.stringify([{ id: this.state.id_Proposition, Precedent: true }])
      );

    this.props.navigate("/souscription");
  };

  render() {
    const { ch_1, ch_2, ch_3, ch_4, ch_5, ch_6, isPrime, Prime, showLoadinBtnTel } =
      this.state;

    let Total = 0,
      periodicite = 0,
      cotisation = 0;

    cotisation = parseInt(this.state.cotisation);

    if (this.state.periodicite === "Mensuelle") {
      periodicite = 1.5;
    }
    Total = periodicite * 12 + cotisation * 12;
    const btnLoading = showLoadinBtnTel ? "toggledLoading" : "";
    return (
      <>
        <Global />


        <div className="row r-15">
          <div className="row r-15  boxTopTitle">
            <div className="col-xs-12 col-sm-9 "></div>
            <div className="col-xs-12 col-sm-3 ">
              <Button className="btn btn_file" onClick={() => docDownload("PlaquetteProduit_Dépendance.pdf")}>
                <img src={icoFile} className="icoFile" alt={icoFile} />
                Plaquette produit
              </Button>
            </div>
          </div>
          <div className="row content_section">
            <div className="row colcenter colcenter_3 r-15">
              <div className="col-2 boxStep active active_r">
                <div className="content-box-step">
                  <div className="divNum">
                    <span>1.</span>
                  </div>
                  <span className="textBox">Simulation</span>
                </div>
              </div>

              <div className="cls_separation active"></div>

              <div className="col-2 boxStep  active active_l active_r">
                <div className="content-box-step ">
                  <div className="divNum">
                    <span>2.</span>
                  </div>
                  <span className="textBox">PROJET</span>
                </div>
              </div>

              <div className="cls_separation active"></div>

              <div className="col-2 boxStep active active_l">
                <div className="content-box-step ">
                  <div className="divNum">
                    <span>3.</span>
                  </div>
                  <span className="textBox">Souscription</span>
                </div>
              </div>
            </div>
            <div className="row r-15 mt-15 cls_content_fiche">
              <div className="col-xs-12 col-sm-6 cls_left">
                <div className="r-15 cls_box_fiche">
                  <h2>Coordonnées de l’assuré</h2>
                  <h3>
                    {this.state.civilite} {this.state.prenom} {this.state.nom}
                  </h3>
                  <p>{this.state.numero_et_voie}</p>
                  <p>
                    {this.state.code_postal} {this.state.Ville}
                  </p>
                </div>

                <div className="r-15 cls_box_fiche">
                  <h2>Récapitulatif de la souscription</h2>

                  <table className="table">
                    <tbody>
                      <tr>
                        <td>Date d’effet</td>
                        <td>{this.state.date_effet_souhaite}</td>
                      </tr>
                      <tr>
                        <td>Périodicité prélèvement</td>
                        <td>{this.state.periodicite}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="r-15 cls_box_fiche cls_box_fiche_3">
                  <div className="r-15 cls_box_fiche">
                    <h2>Cotisations & frais</h2>
                  </div>
                  <table className="table">
                    <tbody>
                      <tr>
                        <td>Cotisation mensuelle souhaitée</td>
                        <td>{this.state.cotisation}€</td>
                      </tr>
                      <tr>
                        <td>Frais de fractionnement</td>
                        <td>
                          {this.state.periodicite === "Mensuelle" ? 1.5 : 0}
                          €/Mois
                        </td>
                      </tr>
                      <tr>
                        <td>Assistance</td>
                        <td>Incluse</td>
                      </tr>

                      <tr className="cl_ct">
                        <td>Cotisation annuelle TTC</td>
                        <td>{Total} €</td>
                      </tr>
                      <tr>
                        <td>
                          Droit d'entrée à l'association des assurés REPAM*
                        </td>
                        <td>{this.state.isAdherent ? 0 : 20} €</td>
                      </tr>
                      {isPrime && (
                        <tr>
                          <td>Achat unités de rente additionnelles</td>
                          <td>{Prime} €</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <p>
                    <small>
                      * Ce montant est facturé une fois, à la souscription
                    </small>
                  </p>
                </div>
              </div>

              <div className="col-xs-12 col-sm-6 cls_right">
                <div className="r-15 cls_box_fiche">
                  <h2>Situation de l’assuré</h2>

                  <table className="table">
                    <tbody>
                      <tr>
                        <td>Date de naissance</td>
                        <td>
                          {this.state.date_de_naissance.split("-")[0] +
                            "/" +
                            this.state.date_de_naissance.split("-")[1] +
                            "/" +
                            this.state.date_de_naissance.split("-")[2]}
                        </td>
                      </tr>
                      <tr>
                        <td>Situation familiale</td>
                        <td>{this.state.situation_familiale}</td>
                      </tr>

                      <tr>
                        <td>Département de naissance</td>
                        <td>{this.state.departement_de_naissance ? this.state.departement_de_naissance : "-"}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="r-15 cls_box_fiche">
                  <h2>Documents à télécharger</h2>

                  <div className="form_n2 ">
                    <div className="row">
                      <div className="col-12 box_checkbox">
                        <FormControlLabel
                          value="checked1"
                          control={<Checkbox color="primary" />}
                          label="Demande d’adhésion Contrat Dépendance"
                          onChange={() =>
                            this.setState({ ch_1: !this.state.ch_1 })
                          }
                          checked={this.state.ch_1}
                        />
                      </div>
                    </div>
                    {this.state.isPrime && (
                      <div className="row">
                        <div className="col-12 box_checkbox">
                          <FormControlLabel
                            value="checked1"
                            control={<Checkbox color="primary" />}
                            label="Demande d'achat d'unités de rente additionnelles"
                            onChange={() =>
                              this.setState({ ch_5: !this.state.ch_5 })
                            }
                            checked={this.state.ch_5}
                          />
                        </div>
                      </div>
                    )}
                    <div className="row">
                      <div className="col-12 box_checkbox">
                        <FormControlLabel
                          value="checked2"
                          control={<Checkbox color="primary" />}
                          label="Mandat SEPA"
                          onChange={() =>
                            this.setState({ ch_6: !this.state.ch_6 })
                          }
                          checked={this.state.ch_6}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 box_checkbox">
                        <FormControlLabel
                          value="checked2"
                          control={<Checkbox color="primary" />}
                          label="IPID Dépendance"
                          onChange={() =>
                            this.setState({ ch_3: !this.state.ch_3 })
                          }
                          checked={this.state.ch_3}
                        />
                      </div>
                    </div>
                    {/*<div className="row">
                      <div className="col-12 box_checkbox">
                        <FormControlLabel
                          value="checked2"
                          control={<Checkbox color="primary" />}
                          label="Notice d'informations"
                          onChange={() =>
                            this.setState({ ch_2: !this.state.ch_2 })
                          }
                          checked={this.state.ch_2}
                        />
                      </div>
                        </div>*/}
                    <div className="row">
                      <div className="col-12 box_checkbox">
                        <FormControlLabel
                          value="checked3"
                          control={<Checkbox color="primary" />}
                          label="Fiche d'informations et de conseils"
                          onChange={() =>
                            this.setState({ ch_4: !this.state.ch_4 })
                          }
                          checked={this.state.ch_4}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 box_checkbox">
                        {ch_1 || ch_2 || ch_3 || ch_4 || ch_5|| ch_6 ? (
                          <Button
                            className={`btn btn-default btn_form ${btnLoading}`}
                            onClick={() =>
                              this.handleClickPdf()
                            }
                          >
                            Télécharger<div className="ld-spin "></div>
                          </Button>
                        ) : (
                          <Button className="btn btn-default btn_form" disabled>
                            Télécharger
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row r-15 cls_content_fiche">
              <div className="col-xs-12 col-12">
                <div className="r-15 cls_box_fiche cls_box_fiche_4">
                  <h2>Souscription en attente de signature</h2>
                  <p>Nous vous remercions de bien vouloir :</p>
                  <p className="titelred">
                    1. télécharger la demande d'adhésion du contrat et la
                    demande d'adhésion d'achat d'unités de rente le cas échéant,
                  </p>
                  <p className="titelred">
                    2. la faire dater et signer par votre client,
                  </p>
                  <p className="titelred">
                    3. nous l'adresser à l'adresse suivante :
                    prevoyance@repam.fr
                  </p>
                  <p>
                    En cas d'achat d'unités de rente soumis à formalités
                    médicales, le questionnaire médical sera à renvoyer à
                    medical@repam.fr
                  </p>
                </div>
              </div>
            </div>

            <div className="row r-15">
              <div className="col-12">
                <ModalValidation prec={this.OnPrecedent} />
              </div>
            </div>
          </div>

         
        </div>
        <ModalExpire show={this.state.modalExpire} />
        {this.state.modalServeur ? <ModalServeur handleCloseModalServeur={() => this.handleCloseModalServeur()} /> : null}
      </>
    );
  }
}

export default withRouter(Recapitulatif);