import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { withRouter } from "../whitRouter";
class modalAdmin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: true,
    };
  }

  handleClose = () => {
    this.props.OnCloseModal()
    this.setState({
      show: false,
    });
  };

  api = () => {
    this.setState({
      show: false,
    });
    this.props.api()
  };

  render() {

    return (
      <Modal
        id="ModalProposition"
        className="ModalQuit"
        show={this.state.show}
        onHide={this.handleClose}
      >
        <Modal.Body>
          <p>{this.props.text}</p>
        </Modal.Body>
        <Modal.Footer>
      
          <Button
            className="btn"
            variant="primary"
            onClick={() => this.api()}
          >
            Oui
          </Button>

          <Button
            className="btn"
            variant="secondary"
            onClick={this.handleClose}
          >
            Annuler
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default withRouter(modalAdmin);
