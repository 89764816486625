import React, { Component } from "react";

import logo from "../assets/img/logo-repam-blue.svg";
import Iconmenu from "../assets/img/Icon-menu.svg";
import Iconclosemenu from "../assets/img/icon-menu-close.svg";
import { Link, Route, Routes } from "react-router-dom";

import Dependance from "./ComponentMain/Dependance";
import NewProposition from "./ComponentMain/newProposition";
import Admin from "./ComponentMain/Admin";
import Proposition from "./ComponentMain/Proposition";
import Simulation from "./ComponentMain/Simulation";
import Souscription from "./ComponentMain/Souscription";
import Recapitulatif from "./ComponentMain/Recapitulatif";
/* ComponentStatus */
import Page_500 from "./ComponentMain/ComponentStatus/Page_500";
import Page_404 from "./ComponentMain/ComponentStatus/Page_404";

import { Tooltip, OverlayTrigger, Button } from "react-bootstrap";
import { getInformations, getToken, getAdmin} from "../Context/ApiContext";

import { withRouter } from "./ComponentMain/whitRouter";
import ModalQuit from "./ComponentMain/CommonComponent/modalQuit";

class Main extends Component {
  constructor(props) {
    super(props);

    this.state = {      
      showModal: false,
    };
  }

  alertClick = (event) => {
    event.preventDefault();
    let tag = event.currentTarget.dataset.tag;

    if (document.getElementsByClassName("navigationSection").length || document.getElementsByClassName("dependenceFiltre").length ) {
      this.setState({
        showModal: true,
        namePage: tag,
      });
    } else {
      this.setState({
        showModal: false,
      });
      let alink = document.createElement("a");
      alink.href = tag;
      alink.click();
    }
  };

  OnchangeModal = () => {
    if (this.state.showModal) {
      this.setState({
        showModal: false,
      });
    }
  };

  render() {
    return (
      <>
        <div className="page-wrapper">
            <div className="page-content">
              <header className="top-header">
                
                <div className="sidebar-header">
                  <div className="row bloc_logo">
                    <a id="hrf_dependance"  
                    href={`#/dependance/${getToken()}`}
                    data-tag={`#/dependance/${getToken()}`}
                    onClick={(e)=>this.alertClick(e)}>
                      <img
                        src={logo}
                        alt={logo}
                        className="logo img-responsive"
                      />
                    </a>
                  </div>
                 
                 
                </div>
        
                <div id="sessionText"></div>
                <div className="right_panel">
                  <div className="profil-bar f_right">
                    <OverlayTrigger
                      delay={{ hide: 1450, show: 300 }}
                      overlay={(props) => (
                        <Tooltip {...props}>
                          <h2 id="tooltipInfos1">
                            {getInformations().split("|")[2]}
                          </h2>{" "}
                            <div id="tooltipInfos2">
                              {getInformations().split("|")[0]}{" "}
                            </div>
                            <div id="tooltipInfos3">
                              {getInformations().split("|")[1]}
                            </div>
                          
                            {((sessionStorage.getItem("isAdmin") == "1" )|| (sessionStorage.getItem("isAdmin") == "2")) ? 
                            <Link  to="/admin">Les administrateurs </Link>
                                :null
                                }
                        </Tooltip>
                      )}
                      placement="bottom"
                    >
                      <div>
                      {sessionStorage.getItem("infos") ? (
                        <>
                        <Button id="tooltipLogo" variant="warning">
                          {" "}
                          {sessionStorage
                            .getItem("infos")
                            .split("|")[2]
                            .split(" ")[1]
                            .slice(0, 1)}
                          {sessionStorage
                            .getItem("infos")
                            .split("|")[2]
                            .split(" ")[2]
                            .slice(0, 1)}{" "}
                        </Button>
                       
                     </>
                      ) : (
                        <Button id="tooltipLogo" variant="warning">
                          {" "}
                        </Button>
                      )}
                      </div>
                    </OverlayTrigger>

                  

                  </div>
                </div>
              </header>

              <Routes>
                <Route path="*" element={<Page_404 />} />
                <Route path="/dependance/:token" element={<Dependance />} />
                <Route
                  path="/newProposition"
                  exact
                  element={<NewProposition />}
                />
                <Route exact path="/proposition" element={<Proposition />} />
                <Route exact path="/simulation" element={<Simulation />} />
                <Route exact path="/Souscription" element={<Souscription />} />
                <Route exact path="/admin" element={<Admin />} />
                <Route
                  exact
                  path="/recapitulatif"
                  element={<Recapitulatif />}
                />
                <Route exact path="/Page_500" element={<Page_500 />} />
              </Routes>
            </div>
        </div>
        {this.state.showModal && (
          <ModalQuit
            page={this.state.namePage}
            OnchangeModal={()=>this.OnchangeModal()}
          />
        )}
      </>
    );
  }
}
export default withRouter(Main);