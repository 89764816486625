import React, { Component } from "react";
import ReactRom from 'react-dom'
import FormProposition from "./ComponentnewProposition/formProposition";
import Button from "@mui/material/Button";
import icoFile from "../../assets/img/icoFile.svg";
import "./ComponentProposition/proposition.css";
import {docDownload} from "../../Context/ApiContext"

class newProposition extends Component {
  componentDidMount(){
    ReactRom.render(
      <></>, document.getElementById('sessionText'))
  }
  render() {
    return (
      <>
        <div className="row boxTopTitle">
          <div className="col-xs-12 col-sm-9 "></div>
          <div className="col-xs-12 col-sm-3 ">
            <Button className="btn btn_file" onClick={()=>docDownload("PlaquetteProduit_Dépendance.pdf")}>
              <img src={icoFile} className="icoFile" alt={icoFile} />
              Plaquette produit
            </Button>
          </div>
        </div>
        <div className="row content_section">
          <div className="row pl_25 pr_25">
            <FormProposition />
          </div>
        </div>
      </>
    );
  }
}

export default newProposition;
