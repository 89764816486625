import icon_delete from "../../../assets/img/icon-delete.svg";
import icon_edit from "../../../assets/img/icon-edit.svg";
import icon_visualiser from "../../../assets/img/icon-visualiser.svg";
import "./Tabe.css";
import "../ComponentSimulation/Simulation.css";
import {getAdmin} from "../../../Context/ApiContext"
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional


export const columnsAdmin = [
  {
    name: "Cabinet",
    selector: (row) => row.cabinet,
    sortable: true,
  },
  {
    name: "Code",
    selector: (row) => row.code,
    sortable: true,
  },
  {
    name: "Nom",
    selector: (row) => row.nom,
    sortable: true,
  },
  {
    name: "Prénom",
    selector: (row) => row.prenom,
    sortable: true,
  },
  {
    name: "Statut",
    selector: (row) => row.admin == "1"? "Admin": (row.admin == "2"?"Super admin":'--'),
    sortable: true,
  },
  {
    name: "Actions",
    selector: (row) => (getAdmin() =="1"? ((row.admin == null || row.admin == "")? (

    <Tippy  id = {'tippyadmin_'+row.id} key={row.id} content={`Activer`}>
                  <button id = {'admin_'+row.id}
                    className={`btn_rud Activer adminAct`}
                    datatarget={`Activer`}
                    dataid={`${row.id}`}
                  ></button>
                </Tippy>
    ):     <Tippy  id = {'tippyadmin_'+row.id} key={row.id} content={`Desactiver`}>
    <button
    disabled
    style={{opacity:0.5}}
     id = {'admin_'+row.id}
      className={`btn_rud Desactiver adminDesact`}
      datatarget={`Desactiver`}
      dataid={`${row.id}`}
    ></button>
  </Tippy>):
    (getAdmin() =="2"?((row.admin == null || row.admin == "")? 
    <Tippy  id = {'tippyadmin_'+row.id} key={row.id} content={`Activer`}>
    <button
     id = {'admin_'+row.id}
      className={`btn_rud Activer adminAct`}
      datatarget={`Activer`}
      dataid={`${row.id}`}
    ></button>
  </Tippy>
    : (row.admin == "1"? 
    <Tippy  id = {'tippyadmin_'+row.id} key={row.id} content={`Desactiver`}>
    <button
     id = {'admin_'+row.id}
      className={`btn_rud Desactiver adminDesact`}
      datatarget={`Desactiver`}
      dataid={`${row.id}`}
    ></button>
  </Tippy>
    :  <Tippy  id = {'tippyadmin_'+row.id} key={row.id} content={`Desactiver`}>
    <button
    disabled
    style={{opacity:0.5}}
     id = {'admin_'+row.id}
      className={`btn_rud Desactiver adminDesact`}
      datatarget={`Desactiver`}
      dataid={`${row.id}`}
    ></button>
  </Tippy>)): "--") ) ,
    sortable: false,
  },
];
