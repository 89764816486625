import React, { useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getToken } from "../../../Context/ApiContext";

const ModalValidation = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };

  return (
    <>
      <Button className="btn btn-default btn_terminer" onClick={handleShow}>
        Terminer
      </Button>
      <Modal className="Modal-default" show={show} onHide={handleClose}>
        <Modal.Body>
          <h3>Vous y êtes presque! </h3>
          <div className="">
            <p>
              Plus qu’une étape ! Il ne vous reste plus qu’à dater et faire signer la demande d'achat d'unités de rente par votre client et nous la renvoyer à l'adresse prevoyance@repam.fr.
              <br />
              <br />
              Le questionnaire de santé et le cas échéant le questionnaire médical seront à renvoyer à medical@repam.fr.
              <br />
              <br />
              Notre service gestion traitera votre dossier dans les meilleurs délais.
              <br />
              <br />
              Pour toute question, vous pouvez entrer en contact avec votre inspecteur ou notre service commercial au 04 72 33 40 95.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Link
            className="btn btn-primary btn-default btn_terminer"
            to={"/dependance/"+getToken()}
          >
            Ok
          </Link>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default ModalValidation;
