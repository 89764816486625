import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import imgpsh from "../../../assets/img/imgpsh_fullsize_anim.png";
import Iconclosemenu from "../../../assets/img/icon-menu-close.svg";
class ModalDocs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,

      hStep: false,
    };
  }

  handleClose = () => {
    this.setState({
      show: false,
    });
  };
  handleShow = () => {
    this.setState({
      show: true,
    });
  };

  render() {
    return (
      <>
        <Button className="btn" onClick={this.handleShow}>
          Voir le document
        </Button>

        {/* Modal Download doc */}

        <Modal id="ModalDocs" show={this.state.show} onHide={this.handleClose}>
          <div className="close_modal " onClick={this.handleClose}>
            <img
              src={Iconclosemenu}
              className="img-responsive img_close_menu"
              alt=""
            />
          </div>
          <img src={imgpsh} className={`imgpsh img-responsive`} alt={imgpsh} />
        </Modal>
      </>
    );
  }
}

export default ModalDocs;
