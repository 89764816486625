import React, { Component } from "react";
import ReactRom from 'react-dom'
import { withRouter } from "./whitRouter";
import ReactExport from "react-export-excel-xlsx-fix";
import DataTable from "react-data-table-component";
import "react-data-table-component-extensions/dist/index.css";
import { columns, columnsAdmin, data } from "./ComponentDependance/Data.js";
import { Modal, Form, Button } from "react-bootstrap";
import $ from "jquery";
import search from "../../assets/img/icons-search.svg";
import search_on from "../../assets/img/icons-search-on.svg";
import search_valid from "../../assets/img/icons-search_valid.svg";
import icon_sort from "../../assets/img/Icon-sort.svg";
import rotation from "../../assets/img/rotation.svg";
import repamSvg from "../../assets/img/repam.svg";
import pictoDelete from "../../assets/img/pictoDelete.svg";
import pictoDuplicate from "../../assets/img/pictoDuplicate.svg";
import Iconclosemenu from "../../assets/img/icon-menu-close.svg";
import ModalExpire from "./CommonComponent/modalExpire";
import ModalServeur from "./CommonComponent/modalServeur";
import {
  getToken,
  setInformations,
  setToken,
  setAdmin,
  docDownload,
} from "../../Context/ApiContext";

import "./ComponentDependance/Tabe.css";
import "./ComponentSimulation/Simulation.css";
import ModalProposition from "./ComponentDependance/ModalProposition";
import moment from "moment";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional
import { getMonth, setMonth } from "date-fns";
/* <----------------------------- Img Action/table -------------------------------> */
const tab_img = ["Modifier", "Dupliquer", "Supprimer"];

class Table extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: [],
      data: data,
      text: "",
      original_rows: [],
      original_rows_Excel: [],
      rows: [],
      rowsExcel: [],
      search: "",
      tabfilter: [],
      tabfilterExcel: [],
      tabsearch: [],
      tabsearchExcel: [],
      selectValue: 30,
      textAdherent: "",
      textCode: "",
      textCabinet: "",
      AllAdherent: [],
      FiltreAdherent: [],
      ShowDivFiltre: false,
      ActiveFilter: false,
      CheckedPEC: false,
      CheckedAT: false,
      CheckedAURS: false,
      CheckedDT: false,
      textspan: $("span.sc-bZkfAO.sc-kLLXSd.lmGpws.OHwJB").text(),
      valF: "",
      Focus_input_adherent: false,
      inputFo: false,
      inputCod: false,
      inputCab: false,
      suggestions: [],
      NumPage: 1,
      setShow: false,
      loading: true,
      loadingTab: false,
      idDeleteProp: 0,
      setShowduplicate: false,
      idduplicateProp: 0,
      dataStorage: [],
      numClient: "",
      resultatTableDep: "Aucun projet n'a été ajouté",
      modalExpire: false,
      modalServeur: false,
      isAdmin: false,
      isSuperAdmin: false
    };
  }

  componentDidMount() {
    
    ReactRom.render(
      <div className="contentGris3  ">
        <h5 className="infoH5 " >
          <span > !</span>Seule une session utilisateur peut être ouverte en simultané. Si vous souhaitez créer un autre compte utilisateur veuillez contacter l’administrateur au sein de votre cabinet ou le service commercial de REPAM.
        </h5>
      </div>, document.getElementById('sessionText'))
      
    localStorage.clear();
    setToken(this.props.useParams.token);
    sessionStorage.setItem("token", this.props.useParams.token);

    const requestOptions = { method: "GET" };
    this.onInitTab(
      `${process.env.REACT_APP_URL}/propositions/${getToken()}`,
      requestOptions
    );
  }

  componentDidUpdate(prevProps, prevState) {

    if (this.state.rows.length > 0) {
     
      /* <---------------------------search------------------------------> */
      if (prevState.tabsearch !== this.state.tabsearch) {
        let TabFinal_search = [];
        let TabFinal_search_Excel = [];
        let tabR =[]
        let tabREx =[]
      if(this.state.tabfilter.length > 0){
        tabR = this.state.tabfilter
        tabREx = this.state.tabfilterExcel 
      }else{
        tabR = this.state.original_rows
        tabREx = this.state.original_rows_Excel 

      }

        if (this.state.search.length > 0) {

          tabR.map((item) => {
            if (this.state.tabsearch.includes(item.id)) {

              if (this.state.isAdmin) {
                TabFinal_search.push({
                  cabinet: item.cabinet,
                  code: item.code,
                  courtier: item.courtier,
                  id: item.id,
                  num: item.num,
                  adherent: item.adherent,
                  ddc: item.ddc,
                  ddf: item.ddf,
                  statut: item.statut,
                  cmttc: item.cmttc,
                  actions: item.actions,
                  NumPage: null,
                  NumRows: null,
                })
              } else {
                TabFinal_search.push({
                  id: item.id,
                  num: item.num,
                  adherent: item.adherent,
                  ddc: item.ddc,
                  ddf: item.ddf,
                  statut: item.statut,
                  cmttc: item.cmttc,
                  actions: item.actions,
                  NumPage: null,
                  NumRows: null,
                });
              }
            }
            return true;
          });

          tabREx.map((item) => {
            if (this.state.tabsearchExcel.includes(item.id)) {

              if (this.state.isAdmin) {
                TabFinal_search_Excel.push({
                  cabinet: item.cabinet,
                  code: item.code,
                  courtier: item.courtier,
                  id: item.id,
                  num: item.num,
                  adherent: item.adherent,
                  ddc: item.ddc,
                  ddf: item.ddf,
                  statut: item.statut,
                  cmttc: item.cmttc,
                  NumPage: null,
                  NumRows: null,
                })
              } else {
                TabFinal_search_Excel.push({
                  id: item.id,
                  num: item.num,
                  adherent: item.adherent,
                  ddc: item.ddc,
                  ddf: item.ddf,
                  statut: item.statut,
                  cmttc: item.cmttc,
                  NumPage: null,
                  NumRows: null,
                });
              }
            }
            return true;
          });
        } else {

          if(this.state.tabfilter.length > 0){
            TabFinal_search = this.state.tabfilter
            TabFinal_search_Excel = this.state.tabfilterExcel 
          }else{
            TabFinal_search = this.state.original_rows
            TabFinal_search_Excel = this.state.original_rows_Excel 
    
          }
        }
        this.setState({ rows: TabFinal_search, rowsExcel: TabFinal_search_Excel });
      }
      /* <---------------------------icon ------------------------------> */

      if (prevState.rows !== this.state.rows) {
        $(
          "div.rdt_TableHead  div.rdt_TableHeadRow div.rdt_TableCol div.rdt_TableCol_Sortable span.sc-kDDrLX"
        ).empty();
        $(
          "div.rdt_TableHead  div.rdt_TableHeadRow div.rdt_TableCol div.rdt_TableCol_Sortable span.sc-kDDrLX"
        ).append(`<img src=${icon_sort} />`);
        $(
          "div.rdt_TableHead  div.rdt_TableHeadRow div.rdt_TableCol:nth-child(7) div.rdt_TableCol_Sortable span.sc-kDDrLX"
        ).empty();
      }
    }
  }
  onInitTab = (apiUrl, requestOptions) => {
    fetch(apiUrl, requestOptions)
      .then((response) => {
        if (response.status == 200) {
          return response.json();
        } else if (response.status == 404) {
          this.props.navigate("/Page_404");
        } else {
          this.props.navigate("/Page_500");
        }
      })
      .then((data) => {
        if (data.code == "-2") {
          this.setState({
            loading: false,
            modalExpire: true,
          });
        } else if (data.code == "-1") {
          this.setState({
            modalServeur: true,
            loading: false,
          })

        } else {
          if (data.information) {


            setAdmin(data.isAdmin);
            sessionStorage.setItem("isAdmin", (data.isAdmin == null || data.isAdmin == "")?0:data.isAdmin );
            setInformations(data.information);
            sessionStorage.setItem("infos", data.information);
            document.getElementById("tooltipLogo").innerHTML =
              data.information.split("|")[2].split(" ")[1].slice(0, 1) +
              data.information.split("|")[2].split(" ")[2].slice(0, 1);
            if (data.isAdmin == "1" && process.env.REACT_APP_CODE_ADMIN== data.code) {

              this.setState({
                isAdmin: true,
                isSuperAdmin:false,
                columns: columnsAdmin
              })

            }else if (data.isAdmin == "2" && process.env.REACT_APP_CODE_ADMIN== data.code) {

              this.setState({
                isAdmin: true,
                isSuperAdmin:true,
                columns: columnsAdmin
              })

            }
             else {
              this.setState({
                isAdmin: false,
                isSuperAdmin:false,
                columns: columns
              })

            }
          } else {
            this.props.navigate("/Page_500");
          }
          this.setState({
            modalExpire: false,
          });
          let NewData = data.result;
          let Tab_Final = [];
          let tabName = [];
          let Tab_Excel = [];
          let itemDdf, itemCmttc, itemStatus;
          NewData.map((items, index) => {
            if (
              tabName.includes(items.client.nom + " " + items.client.prenom) ==
              false
            ) {
              tabName.push(items.client.nom + " " + items.client.prenom);
            }

            if (items.contrat.length == 0) {
              if (items.simulation.length == 0) {
                itemDdf = "";
                itemCmttc = "0 €";
              } else {
                itemDdf = items.souscription.date_effet_souhaite? items.souscription.date_effet_souhaite :'-';
                itemCmttc = items.simulation.cotisation + "€";
              }

              if (items.etat == "souscription") {
                itemStatus = "Demande d'adhésion téléchargée";
              } else {
                itemStatus = "Projet en cours de saisie";
              }
            } else {
              itemDdf = items.contrat.date_effet_souhaite;
              itemCmttc = "-";//items.contrat.cotisation;

              if (items.etat == "contrat1") {
                itemStatus = "Demande d'achat unités de rente téléchargée";
              } else {
                itemStatus = "Achat unités de rente en cours de saisie";
              }
            }



            let getValDays = items.date_creation.substring(0, 4);
            let getValMonth = items.date_creation.substring(5, 7);
            let getValYear = items.date_creation.substring(8, 10);

            let getValDaysdde = itemDdf.substring(0, 4);
            let getValMonthdde = itemDdf.substring(5, 7);
            let getValYeardde = itemDdf.substring(8, 10);
            Tab_Final.push({
              id: index,
              id_Proposition: items.id,
              num: (
                <button
                  className="btnreference"
                  onClick={this.onRouteAction}
                  datatarget="Modifier"
                  dataid={`${items.id}`}
                  datanum={`${items.reference}`}
                >
                  {items.reference}
                </button>
              ),
              cabinet: items.courtier.cabinet,
              code: items.courtier.code,
              courtier: items.courtier.nom + " " + items.courtier.prenom,
              adherent: items.client.nom + " " + items.client.prenom,
              ddc: getValYear + "-" + getValMonth + "-" + getValDays,
              ddf: getValYeardde + "-" + getValMonthdde + "-" + getValDaysdde,
              statut: itemStatus,
              cmttc: itemCmttc,
              actions: tab_img.map((i) => (
                <Tippy key={i} content={`${i}`}>
                  <button
                    className={`btn_rud ${i}`}
                    onClick={this.onRouteAction}
                    datatarget={`${i}`}
                    dataid={`${items.id}`}
                    datanum={`${items.reference}`}
                  ></button>
                </Tippy>
              )),
            });
            Tab_Excel.push({
              id: index,
              id_Proposition: items.id,
              cabinet: items.courtier.cabinet,
              code: items.courtier.code,
              courtier: items.courtier.nom + " " + items.courtier.prenom,
              num: items.reference,
              adherent: items.client.nom + " " + items.client.prenom,
              ddc: getValYear + "-" + getValMonth + "-" + getValDays,
              ddf: getValYeardde + "-" + getValMonthdde + "-" + getValDaysdde,
              statut: itemStatus,
              cmttc: itemCmttc,
            });
            /***valide si rows meme ref* foundRef **/
            return true;
          });
          this.setState({
            original_rows: Tab_Final,
            original_rows_Excel: Tab_Excel,
            rows: Tab_Final,
            rowsExcel: Tab_Excel,
            AllAdherent: tabName,
            loading: false,
          });

        }
      })
      .catch((error) => {
        this.props.navigate("/Page_500");
      });
  };
  /* <---------------------  close modal serveur error   ------------------> */
  handleCloseModalServeur = () => {
    this.setState({
      modalServeur: false
    })
  }
  onRouteAction = (event) => {
    const nameAction = event.target.attributes.datatarget.nodeValue;
    const idClient = event.target.attributes.dataid.nodeValue;
    const base_url = `${process.env.REACT_APP_URL
      }/proposition/${idClient}/${getToken()}`;
    if (nameAction == "Modifier") {
      localStorage.removeItem("Data_Dependance");
      fetch(base_url)
        .then((response) => {
          if (response.status == 200) {
            return response.json();
          } else if (response.status == 404) {
            this.props.navigate("/Page_404");
          } else {
            this.props.navigate("/Page_500");
          }
        })
        .then((data) => {
          let items = data.proposition;
          if ((items.etat == "contrat") || (items.etat == "contrat1")) {
            let data_visualiser_contrat = [{ id: idClient }];
            localStorage.setItem(
              "data_visualiser",
              JSON.stringify(data_visualiser_contrat)
            );
            this.props.navigate("/newProposition");
          } else if (items.etat == "souscription") {
            let data_visualiser_souscription = [{ id: idClient }];
            localStorage.setItem(
              "data_visualiser",
              JSON.stringify(data_visualiser_souscription)
            );
            this.props.navigate("/recapitulatif");
          } else {
            let data_visualiser_simulation = [{ id: idClient }];
            localStorage.setItem(
              "data_visualiser",
              JSON.stringify(data_visualiser_simulation)
            );
            this.props.navigate("/simulation");
          }
        })
        .catch((error) => {
          this.props.navigate("/Page_500");
        });
    } else if (nameAction == "Dupliquer") {
      this.setState({
        setShowduplicate: true,
        idduplicateProp: idClient,
        numClient: event.target.attributes.datanum.nodeValue,
      });
    } else {
      this.setState({
        resultatTableDep: "Aucun projet n'a été aucun ajouté",
        setShow: true,
        idDeleteProp: idClient,
      });
    }
  };
  removeIdPro = () => {
    localStorage.removeItem("data_prop");
  };
  deleteProposition = () => {
    this.setState({
      setShow: false,
      idDeleteProp: 0,
      loading: true,
    });
    const idClient = this.state.idDeleteProp;
    this.removeIdPro();
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ title: "React PUT Request Example" }),
    };
    this.onInitTab(
      `${process.env.REACT_APP_URL}/proposition/` + idClient + "/" + getToken(),
      requestOptions
    );
  };

  duplicateProposition = () => {
    this.setState({
      setShowduplicate: false,
      idduplicateProp: 0,
      loading: true,
    });
    const idClient = this.state.idduplicateProp;
    this.removeIdPro();
    this.state.original_rows.find((obj) => {
      if (obj.num == this.state.numClient) {
        this.state.original_rows.push(obj);
      }
      return this.state.original_rows;
    });
    this.state.original_rows_Excel.find((obj) => {
      if (obj.num == this.state.numClient) {
        this.state.original_rows_Excel.push(obj);
      }
      return this.state.original_rows_Excel;
    });

    const requestOptions = {
      method: "POST",
    };

    this.onInitTab(
      `${process.env.REACT_APP_URL
      }/propositionMacroDuplication/${idClient}/${getToken()}`,
      requestOptions
    );
  };

  handleClose = () => {
    this.setState({
      setShow: false,
      setShowduplicate: false,
    });
  };
  handleShow = () => {
    this.setState({
      setShow: true,
    });
  };
  handleShowduplicate = () => {
    this.setState({
      setShowduplicate: true,
    });
  };
  /*  <--------------------------------------------- FN filtre Data ----------------------------------------------> */

  FiltreData = () => {
    this.setState({ ActiveFilter: true, loadingTab: true, ShowDivFiltre: false });
    const datenow = new Date();
    let list = this.state.original_rows;

    if (this.state.isAdmin) {
      if (this.state.textCabinet != '') {
        let listCab = []
        list.map((items) => {
          if (
            items.cabinet.toLowerCase() == this.state.textCabinet.toLowerCase()
          ) {
            listCab.push(items);
          }
        })
        list = listCab

      }

      if (this.state.textCode != '') {
        let listCod = []
        list.map((items) => {
          if (
            (items.code).split('PAM')[1] == this.state.textCode
          ) {
            listCod.push(items);
          }
        })

        list = listCod
      }
    }

    let listTime = []
    list.map((item) => {

      let Date_Now = moment([
        moment(datenow).format("DD-MM-YYYY").split("-")[2],
        parseInt(moment(datenow).format("DD-MM-YYYY").split("-")[1]) - 1,
        moment(datenow).format("DD-MM-YYYY").split("-")[0],
      ]);
      let val_Date = moment([
        item.ddc.split("-")[2],
        parseInt(item.ddc.split("-")[1]) - 1,
        item.ddc.split("-")[0],
      ]);

      let diff_res = Date_Now.diff(val_Date, "days");
      if (this.state.selectValue === 1) {
        listTime.push(item);
      } else if (diff_res <= this.state.selectValue) {

        listTime.push(item);
      }
    })

    list = listTime

    if (this.state.textAdherent !== "") {
      let listAdherent = []
      list.map((item) => {

        if (
          item.adherent.toLowerCase() ===
          this.state.textAdherent.toLowerCase()
        ) {
          listAdherent.push(item);
        }
      })
      list = listAdherent;
    }

    if (this.state.CheckedPEC === true || this.state.CheckedAT === true ||
      this.state.CheckedDT === true || this.state.CheckedAURS === true) {
      let listCase = []
      list.map((item) => {
          if (this.state.CheckedPEC === true && item.statut === "Projet en cours de saisie") {            
            listCase.push(item);
          }
          if ( this.state.CheckedAT === true && item.statut === "Demande d'adhésion téléchargée") {          
            listCase.push(item);
          }
          if (this.state.CheckedDT === true && item.statut === "Demande d'achat unités de rente téléchargée"){          
            listCase.push(item);
          }
          if (this.state.CheckedAURS === true && item.statut === "Achat unités de rente en cours de saisie") {          
            listCase.push(item);
          }
      })

      list = listCase;
    }
    
    this.setState({
      loadingTab: false,rows: list, rowsExcel: list, tabfilter:list, tabfilterExcel: list
    });
    if (list.length === 0) {
      this.setState({
        loadingTab: false,
        resultatTableDep:
          "Aucun résultat ne correspond à vos critères de recherche.",
      });
    }

  };


  /*  <--------------------------------------------- FN Export Excel  ----------------------------------------------> */

  ExportExcel = () => (
   
    <div className="d-none">
      <table id="table-to-xls">
        <thead>
          <tr>
            {this.state.columns
              .map((i) => i.name !== "Actions" && i.name)
              .map((col) => {
                return <th key={col.toString()}> {col} </th>;
              })}
          </tr>
        </thead>
        <tbody>
          {this.state.rows.map((items) => (
            <tr key={items.id}>
              {this.state.isAdmin ? <td>items.cabinet</td> : null}
              
              <td>{items.courtier}</td>
              <td>{items.num}</td>
              <td>{items.adherent}</td>
              <td>{items.ddc}</td>
              <td>{items.ddf}</td>
              <td>{items.statut}</td>
              <td>{items.cmttc}</td>
            </tr>
          ))}
          {(() => {
            return (
              this.state.rows.length == 0 && (
                <tr key={0}>
                  <td style={{ textAlign: "center" }} colSpan={6}>
                    Aucune donnée disponible
                  </td>
                </tr>
              )
            );
          })()}
        </tbody>
      </table>
    </div>
  );

  /* <----------------------------------------------- FN Find ----------------------------------------------------------> */
  onChangeSearch = (event) => {
    this.setState({ search: event.target.value });
    const value = event.target.value;
    const regex = new RegExp(`${value}`, "i");
    let tab_search = [];
    let tab_search_Excel = [];
    let tabR =[]
    let tabREx =[]
  
    if(this.state.tabfilter.length > 0){
      tabR =this.state.tabfilter
      tabREx =this.state.tabfilterExcel
    this.setState({rows: this.state.tabfilter, rowsExcel: this.state.tabfilterExcel})
    }else{
      tabR =this.state.original_rows
      tabREx =this.state.original_rows_Excel
    this.setState({rows: this.state.original_rows, rowsExcel: this.state.original_rows_Excel})
    }
      
    
    tabR.map((items) => {
      if (
        regex.test(items.num) ||
        regex.test(items.adherent) ||
        regex.test(items.ddc) ||
        regex.test(items.ddf) ||
        regex.test(items.statut) ||
        regex.test(items.cmttc) || (
          this.state.isAdmin && regex.test(items.cabinet))
      ) {
        tab_search.push(items.id);
      }
      return true;
    });

    tabREx.map((items) => {
      if (
        regex.test(items.num) ||
        regex.test(items.adherent) ||
        regex.test(items.ddc) ||
        regex.test(items.ddf) ||
        regex.test(items.statut) ||
        regex.test(items.cmttc) || (
          this.state.isAdmin && regex.test(items.cabinet))
      ) {
        tab_search_Excel.push(items.id);
      }
      return true;
    });

    if (tab_search.length == 0) {
      this.setState({
        resultatTableDep:
          "Aucun résultat ne correspond à vos critères de recherche.",
      });
    }

    this.setState({ tabsearch: tab_search, tabsearchExcel: tab_search_Excel });
  };

  /* <----------------------------------------------------- Toggle Div Filtre ------------------------------------------------------------> */
  Change = () => {
    this.setState({ ShowDivFiltre: !this.state.ShowDivFiltre });
  };

  /* <----------------------------------------------------- Close Div Filtre / Etat initial ------------------------------------------------------------> */
  Delete = () => {
    this.setState({
      rows: this.state.original_rows,
      rowsExcel: this.state.original_rows_Excel,
      textAdherent: "",
      textCode:"",
      textCabinet: "",
      CheckedPEC: false,
      CheckedAT: false,
      ActiveFilter: false,
      loadingTab: true,
      ShowDivFiltre: false,
      tabfilter:[],
      tabfilterExcel: []
    });
    $(".form-check-input ").prop("checked", false);
    $(".input_S").val();
    setTimeout(() => {
      this.setState({
        loadingTab: false,
      });
    }, 500);
  };
  onChangedCode = (e) => {
    const value = e.target.value;
    this.setState(() => ({ textCode: value }));

  }

  onChangedCabinet = (e) => {
    const value = e.target.value;
    this.setState(() => ({ textCabinet: value }));

  }
  onTextChanged = (e) => {
    const value = e.target.value;
    let suggestions = [];
    if (value.length > 0) {
      const regex = new RegExp(`${value}`, "i");

      suggestions = this.state.AllAdherent.sort().filter((v) => regex.test(v));
    }
    this.setState(() => ({ suggestions, textAdherent: value }));
  };

  adherentSelected(value) {
    this.setState(() => ({ textAdherent: value, suggestions: [] }));
  }

  renderAdherent = () => {
    const { suggestions, textAdherent } = this.state;
    if (
      suggestions.length == 0 &&
      !this.state.AllAdherent.includes(textAdherent)
    ) {
      return (
        <ul>
          <li>Aucun résultat</li>
        </ul>
      );
    } else if (
      suggestions.length == 0 ||
      this.state.AllAdherent.includes(textAdherent)
    ) {
      return null;
    }

    return (
      <ul>
        {suggestions.map((item, index) => {

          return (
            <li key={item.id} onClick={() => this.adherentSelected(item)}>
              {/*  <span className="text_Color"> </span>  {val1} {val2}*/}
              {item}
            </li>
          );
        })}
      </ul>
    );
  };
  /*  <--------------------------------------------- PAGINATION ----------------------------------------------> */
  FN_onChangePage = (page) => {
    this.setState({ NumPage: page });
  };

  FN_onChangeNumRows = (numRows) => {
    this.setState({ NumRows: numRows });
  };

  render() {
    const {
      textAdherent,
      ShowDivFiltre,
      inputFo,
      ActiveFilter,
      resultatTableDep,
    } = this.state;
    /* <------------------- input adherent / filter ----------------------> */
    textAdherent !== ""
      ? $(".AutoText").addClass("input_valid")
      : this.state.inputFo
        ? $(".AutoText").addClass("input_focused") &&
        $(".AutoText").removeClass("input_valid")
        : $(".AutoText").removeClass("input_focused") &&
        $(".AutoText").removeClass("input_valid");

    setTimeout(() => {
      let sel = $("div.sc-lllmON select.sc-cwSeag")
        .children("option:selected")
        .val();
      /* <------------------- change span linges par page -------------------> */
      $("nav.rdt_Pagination span.sc-ilhmMj").empty();
      $("nav.rdt_Pagination span.sc-ilhmMj").append("Lignes par page");
      /* <-------- change span numero de page / nombre de linge  ------------> */
      $("nav.rdt_Pagination span.sc-kMjNwy ").empty();
      $("nav.rdt_Pagination span.sc-kMjNwy").append(
        `<div id='d-num-row'><div id='Id-S'>${this.state.NumPage}</div> de  ${sel} </div>`
      );
    }, 0);

    /* <-------------------------- display ul -----------------------------> */
    textAdherent !== ""
      ? $(".AutoText ul").css("display", "block")
      : $(".AutoText ul").css("display", "none");
    // nommer le fichier excel comme suit: jjmmaa_ListeProjet_Dependance.xls
    const date = new Date();
    const currentYear = date.getFullYear();
    const currentMonth = date.getMonth() + 1;
    const currentDate = date.getDate();
    const datFile =
      currentDate +
      "" +
      currentMonth +
      "" +
      currentYear +
      "_ListeProjet_Dependance";
    // nommer le fichier excel comme suit: jjmmaa_ListeProjet_Dependance.xls
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
    const dataSet1 = [{ data: [] }];
    this.state.rowsExcel.map((items, index) => {
      
      if(this.state.isAdmin == true){
        dataSet1.push({
          num: items.num,
          cabinet: items.cabinet,
          code: items.code,
          courtier: items.courtier,
          adherent: items.adherent,
          ddc: items.ddc,
          ddf: items.ddf,
          statut: items.statut,
          cmttc: items.cmttc,
        });
      }else{
        dataSet1.push({
          num: items.num,
          adherent: items.adherent,
          ddc: items.ddc,
          ddf: items.ddf,
          statut: items.statut,
          cmttc: items.cmttc,
        });
      }
      return true;
    });
    const toggelFilter = this.state.ShowDivFiltre ? "toggledShowDivFiltre" : "";

    return (
      <>
        <div className="containerP">
          <div className="divD row bloc_panel ">
            <div className="col-xs-12 col-6 pl_8">
              <h2 className="title_content">Dépendance</h2>
            </div>
            <div className="col-xs-12 col-6 bloc_btn_header pr_0">

              <button className="download-table-xls-button" onClick={() => docDownload("PlaquetteProduit_Dépendance.pdf")}>
                Plaquette produit
              </button>


              <ModalProposition />
            </div>
          </div>
          <div className="container1 dependenceFiltre">
            <div className="row   row-MV">
              <div className="col-2 colstart divPro">
                <span className="textPro ">Projets en cours </span>
              </div>

              <div className="col-10 colend">
                <Tippy content="Exporter XLS">
                  <div className="fl_left">
                    <ExcelFile
                      filename={datFile}
                      element={
                        <button className="download-table-Excel-button" />
                      }
                    >
                      {dataSet1.length == 1 ? (
                        <ExcelSheet data={dataSet1} name="Depandance">
                          <ExcelColumn
                            label="Aucune donnée disponible"
                            value="num"
                          />
                        </ExcelSheet>
                      ) : (
                        this.state.isAdmin?<ExcelSheet data={dataSet1} name="Depandance">
                        <ExcelColumn label="Cabinet" value="cabinet" /> 
                          <ExcelColumn label="Code courtier" value="code" />
                          <ExcelColumn label="Courtier" value="courtier" />
                          <ExcelColumn label="N° de projet" value="num" />
                          <ExcelColumn label="Adhérent" value="adherent" />
                          <ExcelColumn label="Date de création" value="ddc" />
                          <ExcelColumn label="Date d'effet" value="ddf" />
                          <ExcelColumn label="Statut" value="statut" />
                          <ExcelColumn
                            label="Cotisation mensuelle"
                            value="cmttc"
                          />
                        </ExcelSheet>
                        :
                        <ExcelSheet data={dataSet1} name="Depandance">
                          <ExcelColumn label="N° de projet" value="num" />
                          <ExcelColumn label="Adhérent" value="adherent" />
                          <ExcelColumn label="Date de création" value="ddc" />
                          <ExcelColumn label="Date d'effet" value="ddf" />
                          <ExcelColumn label="Statut" value="statut" />
                          <ExcelColumn
                            label="Cotisation mensuelle"
                            value="cmttc"
                          />
                        </ExcelSheet>
                      )}
                    </ExcelFile>
                    <this.ExportExcel />
                  </div>
                </Tippy>
                {ShowDivFiltre ? (
                  <Tippy content="Filtre ">
                    <button
                      className="divIcon_Active"
                      onClick={this.Change}
                    ></button>
                  </Tippy>
                ) : (
                  <Tippy content="Filtre ">
                    {ActiveFilter ? <><button className="divIcon_Active" onClick={this.Change}><div className="active_Filtre">Actif</div></button></> : <button className="divIcon" onClick={this.Change}></button>}
                  </Tippy>
                )}

                <Form.Control
                  type="text"
                  placeholder="Rechercher par mot clé"
                  aria-describedby="passwordHelpBlock"
                  className="inputRe"
                  onChange={(event) => this.onChangeSearch(event)}
                />
              </div>
            </div>

            {/* <----------------------------------------- content table  ----------------------------------------> */}

            {this.state.ShowDivFiltre && (
              <div className="filtreZone">
                <div className="boxfiltre">
                  <div className="">
                    <span className="textf"> Filtre Par </span>
                  </div>
                  <div className="row row-MV">
                    <div className="col-2 colstart">
                      <span className="Label-input-DF">
                        Date de création du projet
                      </span>
                    </div>
                    <div className="col-2">
                      <Form.Select
                        aria-label="Default select example"
                        className="input-Per-Rech"
                        value={this.state.selectValue}
                        onChange={(e) =>
                          this.setState({ selectValue: e.target.value })
                        }
                      >
                        <option value={30}> 30 derniers jours </option>
                        <option value={90}>90 derniers jours</option>
                        <option value={1}>Tous</option>
                      </Form.Select>
                    </div>
                  </div>

                  <div className="row row-MV Position">
                    <div className="col-2 colstart">
                      <span className="Label-input-DF"> Adhérent </span>
                    </div>
                    <div className="col-2 div-F-A">
                      <div className="AutoText">
                        <input
                          className="input_S"
                          value={textAdherent}
                          onChange={this.onTextChanged}
                          placeholder="Nom d'Adhérent"
                          type="text"
                          onBlur={() => this.setState({ inputFo: false })}
                          onFocus={() => this.setState({ inputFo: true })}
                        />
                        {this.renderAdherent()}

                        {textAdherent !== "" ? (
                          <img
                            className="icon_search"
                            src={search_valid}
                            alt={search_valid}
                          />
                        ) : inputFo ? (
                          <img
                            className="icon_search"
                            src={search_on}
                            alt={search_on}
                          />
                        ) : (
                          <img
                            className="icon_search"
                            src={search}
                            alt={search}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row row-MV ">
                    <div className="col-2 colstart">
                      <span className="Label-input-DF"> Statut </span>
                    </div>
                    <div className="col-10 colstart pl-0">
                      <Form.Check
                        id="checkbox_Filtre1"
                        inline
                        label="Projet en cours de saisie"
                        name="PEC"
                        type="checkbox"
                        checked={this.state.CheckedPEC ? true : false}
                        onChange={() =>
                          this.setState({ CheckedPEC: !this.state.CheckedPEC })
                        }
                      />
                      <Form.Check
                        id="checkbox_Filtre2"
                        inline
                        label="Demande d’adhésion téléchargée"
                        name="AT"
                        type="checkbox"
                        checked={this.state.CheckedAT ? true : false}
                        onChange={() =>
                          this.setState({ CheckedAT: !this.state.CheckedAT })
                        }
                      />
                      <Form.Check
                        id="checkbox_Filtre3"
                        inline
                        label="Achat unités de rente en cours de saisie"
                        name="AURS"
                        type="checkbox"
                        checked={this.state.CheckedAURS ? true : false}
                        onChange={() =>
                          this.setState({ CheckedAURS: !this.state.CheckedAURS })
                        }
                      />
                      <Form.Check
                        id="checkbox_Filtre4"
                        inline
                        label="Demande d'achat unités de rente téléchargée"
                        name="DT"
                        type="checkbox"
                        checked={this.state.CheckedDT ? true : false}
                        onChange={() =>
                          this.setState({ CheckedDT: !this.state.CheckedDT })
                        }
                      />
                    </div>
                  </div>


                  {this.state.isAdmin ?
                    <div>
                      <div className="row row-MV Position">
                        <div className="col-2 colstart">
                          <span className="Label-input-DF"> Code </span>
                        </div>
                        <div className="col-2 div-F-A">
                          <div className="AutoText">
                            <input
                              className="input_S"
                              value={this.state.textCode}
                              onChange={this.onChangedCode}
                              placeholder="Code Repam"
                              type="text"
                              onBlur={() => this.setState({ inputCod: false })}
                              onFocus={() => this.setState({ inputCod: true })}
                            />

                          </div>
                        </div>
                      </div>

                      <div className="row row-MV Position">
                        <div className="col-2 colstart">
                          <span className="Label-input-DF"> Cabinet </span>
                        </div>
                        <div className="col-2 div-F-A">
                          <div className="AutoText">
                            <input
                              className="input_S"
                              value={this.state.textCabinet}
                              onChange={this.onChangedCabinet}
                              placeholder="Cabinet"
                              type="text"
                              onBlur={() => this.setState({ inputCab: false })}
                              onFocus={() => this.setState({ inputCab: true })}
                            />

                          </div>
                        </div>
                      </div>
                    </div>
                    : null}
                  <div className=" row row-MT ">
                    <div className="col-12 colend">
                      <Button
                        variant="danger"
                        className="me-3 btn-default"
                        onClick={this.FiltreData}
                      >
                        Appliquer
                      </Button>
                      <Button
                        variant="dark"
                        onClick={this.Delete}
                        className="btn-default"
                      >
                        Effacer
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="blockTabDep">
              {this.state.loadingTab && (
                <div className="loading loadingTab">
                  <div className="boxLoading">
                    <img src={rotation} alt={rotation} className="svgrotation" />
                    <img src={repamSvg} alt={repamSvg} />
                  </div>
                </div>
              )}
              {this.state.isAdmin ? 
              <DataTable
              title="List"
              columns={this.state.columns}
              data={this.state.rows}
              noDataComponent={resultatTableDep}
              pagination
              striped
              dense
              noHeader
              onChangePage={(page) => this.FN_onChangePage(page)}
              onChangeRowsPerPage={(numRows) =>
                this.FN_onChangeNumRows(numRows)
              }
            />:
            <DataTable
                className="superadmin"
                title="List"
                columns={this.state.columns}
                data={this.state.rows}
                noDataComponent={resultatTableDep}
                pagination
                striped
                dense
                noHeader
                onChangePage={(page) => this.FN_onChangePage(page)}
                onChangeRowsPerPage={(numRows) =>
                  this.FN_onChangeNumRows(numRows)
                }
              />
              }
              
            </div>
          </div>
          <Modal
            id="DeletProposition"
            className="ModalGestion"
            show={this.state.setShow}
            onHide={this.handleClose}
          >
            <div className="close_modal " onClick={this.handleClose}>
              <img
                src={Iconclosemenu}
                className="img-responsive img_close_menu"
                alt=""
              />
            </div>
            <Modal.Body>
              <img
                src={pictoDelete}
                alt={pictoDelete}
                className="pictoGestion"
              />
              <h2>
                Êtes-vous sûr de vouloir <br />
                supprimer cette proposition?
              </h2>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleClose}>
                Annuler la suppression
              </Button>
              <Button variant="primary" onClick={this.deleteProposition}>
                Confirmer la suppression
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            id="DuplicateProposition"
            show={this.state.setShowduplicate}
            onHide={this.handleClose}
            className="ModalGestion"
          >
            <div className="close_modal " onClick={this.handleClose}>
              <img
                src={Iconclosemenu}
                className="img-responsive img_close_menu"
                alt=""
              />
            </div>
            <Modal.Body>
              <img
                src={pictoDuplicate}
                alt={pictoDuplicate}
                className="pictoGestion"
              />
              <h2>Dupliquer la proposition</h2>
              <p>
                Une copie de la proposition sera créée et sauvegardée<br /> avec un nouveau numéro de projet

              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleClose}>
                Annuler
              </Button>
              <Button variant="primary" onClick={this.duplicateProposition}>
                Confirmer la copie
              </Button>
            </Modal.Footer>
          </Modal>
          {this.state.loading && (
            <div className="loading">
              <div className="boxLoading">
                <img src={rotation} alt={rotation} className="svgrotation" />
                <img src={repamSvg} alt={repamSvg} />
              </div>
            </div>
          )}
        </div>
        <ModalExpire show={this.state.modalExpire} />
        {this.state.modalServeur ? <ModalServeur handleCloseModalServeur={() => this.handleCloseModalServeur()} /> : null}
      </>
    );
  }
}

export default withRouter(Table);
