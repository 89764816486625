import React, { Component } from "react";

import TextField from "@mui/material/TextField";
import { Button } from "react-bootstrap";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { HelpRounded } from "@mui/icons-material";

import { Link } from "react-router-dom";
import moment from "moment";
import * as xml2js from "xml2js";
import rotation from "../../../assets/img/rotation.svg";
import repamSvg from "../../../assets/img/repam.svg";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import info from "../../../assets/img/Icon-feather-info.svg";
import { getToken, getInformations } from "../../../Context/ApiContext"
import ModalQuit from "../CommonComponent/modalQuit";
import ModalExpire from "../CommonComponent/modalExpire";
import ModalServeur from "../CommonComponent/modalServeur";
import ModalValidation from "./modalValidation";
import "../ComponentRecapitulatif/Recapitulatif.css";

class formProposition extends Component {
  constructor(props) {
    super(props);

    global.TabRenteRachat = [];
    global.TabRente = [];
    this.state = {
      showLoadinBtnTel: false,
      modalServeur: false,
      modalExpire: false,
      loading: false,
      checkDoc: true,
      name: "",
      lastName: "",
      errorName: -1,
      errorLastName: -1,
      cotisation: "",
      montantAchat: "",
      disabledMontant: true,
      montantAchatMin1: 1,
      montantAchatMin2: 1000,
      montantAchatMax: 6000,
      errorMontant: -1,
      DDN: "",
      errorDDE: -1,
      DEC: "",
      errorDEC: -1,
      DES: "",
      errorDES: -1,
      isIndependant: false,
      showModal: false,
      disabledBtn: true,
      Proje_num: "",
      dateNeMax: "",
      dateNeMin: "",
      dateSouhaiteMin: "",
      dateContratMax: "",
      dateContratMin: "",
      rente: "",

      errorMsgDateNaisInvalid:false,
      disabledDDN:false,
      Div_Stat: false,

      tab_Totale_Final: [],
      tab_Paritelle: [],
      Tab_TabRenteRachat: [],
      Tab_TabRenteRachatParitelle: [],
      idProposition: ""
    };
  }


  componentDidMount() {
    const date = new Date();
    const currentYear = date.getFullYear();
    const currentMonth = date.getMonth() + 1;
    const currentDate = date.getDate();



    //DDN
    let numMonth = "";
    if (currentMonth < 10) {
      numMonth = "0"
    }
    let numDay = "";
    if (currentDate < 10) {
      numDay = "0"
    }

    //DEC
    let dateC = new Date();
    dateC.setMonth(parseInt(dateC.getMonth()) + 1)
    dateC = new Date(dateC)
    let numMonthC = "";
    if ((dateC.getMonth() + 1) < 10) {
      numMonthC = "0";
    }
    //DES


    let dateS = new Date();
    dateS.setMonth(parseInt(dateS.getMonth()) + 1)
    dateS = new Date(dateS)
    let numMonthS = "";
    if ((dateS.getMonth() + 1) < 10) {
      numMonthS = "0"
    }

    //
    this.setState({
      dateNeMax: currentYear - 18 + "-12-31" ,
      dateNeMin: currentYear - 74 + "-01-01" ,
      dateSouhaiteMin: dateS.getFullYear() + "-" + numMonthS + (dateS.getMonth() + 1) + "-01",
      dateContratMax: dateC.getFullYear() + "-" + numMonthC + (dateC.getMonth() + 1) + "-01",
      dateContratMin: "2022-11-01"
    });

    let id = "";
    let parsed_visualiser = JSON.parse(localStorage.getItem("data_visualiser"));

    /*  <--------------------------------------------- IF PUT ----------------------------------------------> */
    if (parsed_visualiser) {
      id = parsed_visualiser[0].id;
      //this.setState({loading: true})
      fetch(`${process.env.REACT_APP_URL}/proposition/${id}/${getToken()}`)
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else if (response.status === 404) {
            this.props.navigate("/Page_404");
          } else {
            this.props.navigate("/Page_500");
          }
        })
        .then((data) => {
          let item = data.proposition;
          this.setState({
            name: item.client["nom"],
            lastName: item.client["prenom"],
            DDN: item.client["date_de_naissance"],
            DEC: item.contrat["date_effet_contrat"],
            cotisation: item.contrat["cotisation"],
            DES: item.contrat["date_effet_souhaite"],
            montantAchat: parseFloat(item.contrat["montant_achat"]),
            isIndependant: item.contrat["isIndependant"],
            disabledMontant: false,
            errorName: 0,
            errorLastName: 0,
            errorDDE: 0,
            errorDEC: 0,
            errorDES: 0,
            errorMontant: 0,
            loading: false,
            disabledBtn: false
          });
          // DES
          const date = new Date(item.contrat["date_effet_souhaite"]);
          const currentYear = date.getFullYear();
          const currentMonth = date.getMonth() + 1;
          const currentDate = date.getDate();
          //DEC

          const dateC = new Date(item.contrat["date_effet_contrat"]);
          const currentYearC = dateC.getFullYear();
          const currentMonthC = dateC.getMonth() + 1;
          const currentDateC = dateC.getDate();
          //DDN
          let numMonth = "";
          if (currentMonth < 10) {
            numMonth = "0"
          }
          let numDay = "";
          if (currentDate < 10) {
            numDay = "0"
          }
          //DES
          let numMonthS = "";
          if (currentMonthC < 10) {
            numMonthS = "0"
          }
          let numDayS = "";
          if (currentDateC < 10) {
            numDayS = "0"
          }
          this.setState({
            dateSouhaiteMin: currentYearC + "-" + numMonthS + currentMonthC + "-" + numDayS + currentDateC,
          })
          let YearN = (new Date(item.client["date_de_naissance"]) ).getFullYear()
          let yearJ =(new Date()).getFullYear()
          if(YearN > (yearJ-18)||YearN < (yearJ-74)){
            this.setState({
              errorMsgDateNaisInvalid:true,
              disabledDDN:true,
              errorDDE : true,
              dateNeMax:"" ,
              dateNeMin: "" ,
            })
          }else{
            let dateJ = new Date();
            let currentYear = dateJ.getFullYear();
            let currentMonth = dateJ.getMonth() + 1;
            let currentDate = dateJ.getDate();

            let v_month_1Day = "";
            let v_day_1Day = "";

            if (currentMonth < 10) {
              v_month_1Day = "0";
            }
            if (currentDate < 10) {
              v_day_1Day = "0";
            }


            this.setState({
             disabledDDN:false,
             errorMsgDateNaisInvalid: false,
             dateNeMax: currentYear - 18 + "-12-31" ,
             dateNeMin: currentYear - 74 + "-01-01" ,
            });
          }
        })
        .catch((error) => {
          this.props.navigate("/Page_500");
        });
    }
  }

  /*  <--------------------------------------------- TEST BTN ENREGISTRER ----------------------------------------------> */

  onShowSave = (errorDDE, errorDEC, errorDES, cotisation, errorMontant) => {

    if ((this.state.errorName === 0) && this.state.name
      && (this.state.errorLastName === 0) && this.state.lastName
      && (cotisation != "")
      && (cotisation != null)
      && (errorMontant === 0)
      && (errorDDE === 0)
      && (errorDEC === 0)
      && (errorDES === 0)) {
      this.setState({ disabledBtn: false, Div_Stat: false });
    } else {
      this.setState({ disabledBtn: true,Div_Stat: false });
    }


  };
  /*  <--------------------------------------------- CHANGE NAME ----------------------------------------------> */
  onchangeName = (event) => {
    const parent = event.currentTarget.parentNode.parentNode;
    this.setState({
      name: event.target.value,
    });

    if (!/^[a-z- àâäéèêëîïôöùûüÿçæœ-]*$/i.test(event.target.value)) {
      parent.classList.add("error-valide");
      this.setState({
        errorName: 1,
      });
    } else if (!event.target.value.replace(/\s+/, "").length) {
      parent.classList.add("error-valide");
      this.setState({
        errorName: 2,
      });
    } else {
      if (event.target.value.length > 1 && event.target.value.length < 51) {
        parent.classList.remove("error-valide");
        this.setState({
          name: event.target.value,
          errorName: 0,
        });
      } else {
        parent.classList.add("error-valide");
        this.setState({
          errorName: 2
        });
      }
    }

  };
  /*  <--------------------------------------------- CHANGE LASTNAME ----------------------------------------------> */
  onchangeLastName = (event) => {
    const parent = event.currentTarget.parentNode.parentNode;
    this.setState({
      lastName: event.target.value,
    });
    if (!/^[a-z- àâäéèêëîïôöùûüÿçæœ-]*$/i.test(event.target.value)) {
      parent.classList.add("error-valide");
      this.setState({
        errorLastName: 1
      });
    } else if (!event.target.value.replace(/\s+/, "").length) {
      parent.classList.add("error-valide");
      this.setState({
        errorLastName: 2
      });
    } else {
      if (event.target.value.length > 1 && event.target.value.length < 51) {
        parent.classList.remove("error-valide");
        this.setState({
          lastName: event.target.value,
          errorLastName: 0
        });
      } else {
        parent.classList.add("error-valide");
        this.setState({
          errorLastName: 2
        });
      }
    }


  };
  /*  <--------------------------------------------- CHANGE DDN ----------------------------------------------> */
  onchangeDDN = (event) => {
    event.preventDefault();
    const valDate = event.target.value;
    this.setState({ Div_Stat: false, DDN: event.target.value });
    const dateDDN = new Date(valDate);

    if ((dateDDN.getFullYear() > (new Date(this.state.dateNeMax)).getFullYear()) || (dateDDN.getFullYear() < (new Date(this.state.dateNeMin)).getFullYear())) {
      this.setState({ errorDDE: 1, montantAchat: "", disabledMontant: true, errorMontant: -1 });
      this.onShowSave(1, this.state.errorDEC, this.state.errorDES, this.state.cotisation, this.state.errorMontant);
      document
        .getElementById("TextFieldDDn")
        .parentNode.parentNode.classList.add("error-valide");
    } else {
      this.setState({
        errorDDE: 0
      });
      document
        .getElementById("TextFieldDDn")
        .parentNode.parentNode.classList.remove("error-valide");
      this.onShowSave(0, this.state.errorDEC, this.state.errorDES, this.state.cotisation, this.state.errorMontant);

    }


    this.onchangeMontant(event.target.value, this.state.DEC, this.state.DES, this.state.cotisation)



  };
  /*  <--------------------------------------------- CHANGE DEC ----------------------------------------------> */
  onchangeDEC = (event) => {
    event.preventDefault();

    this.setState({ DEC: event.target.value });
    let date = new Date(event.target.value);
    if (date.getDate() > 1) {
      date.setMonth(parseInt(date.getMonth() + 1))
    }
    if ((new Date(event.target.value)).getFullYear() > 999) {
      let numMonth = "";
      if ((date.getMonth()) < 10) {
        numMonth = "0"
      }
      const valDate = date.getFullYear() + "-" + numMonth + (date.getMonth() + 1) + "-01";
      this.setState({ DEC: valDate });
      const dateDEC = new Date(valDate);
      if ((dateDEC > new Date(this.state.dateContratMax)) || (dateDEC < new Date(this.state.dateContratMin))) {
        this.setState({ errorDEC: 1, montantAchat: "", disabledMontant: true, errorMontant: -1 });
        this.onShowSave(this.state.errorDDE, 1, this.state.errorDES, this.state.cotisation, this.state.errorMontant)
        document
          .getElementById("TextFieldDEc")
          .parentNode.parentNode.classList.add("error-valide");
      } else {
        this.setState({
          errorDEC: 0,
          dateSouhaiteMin: valDate,
        });
        document
          .getElementById("TextFieldDEc")
          .parentNode.parentNode.classList.remove("error-valide");
        this.onShowSave(this.state.errorDDE, 0, this.state.errorDES, this.state.cotisation, this.state.errorMontant)
        // DES
        if (( new Date(this.state.DES)< new Date(valDate))  || (new Date(this.state.DES) < new Date())) {
          this.setState({ errorDES: 1, montantAchat: "", disabledMontant: true, errorMontant: -1 });
          this.onShowSave(this.state.errorDDE, this.state.errorDEC, 1, this.state.cotisation, this.state.errorMontant)
          document
            .getElementById("TextFieldDEs")
            .parentNode.parentNode.classList.add("error-valide");
        } else {
          this.setState({
            errorDES: 0
          });
          document
            .getElementById("TextFieldDEs")
            .parentNode.parentNode.classList.remove("error-valide");
          this.onShowSave(this.state.errorDDE, this.state.errorDEC, 0, this.state.cotisation, this.state.errorMontant)

        }
      }
    } else {
      this.setState({ errorDEC: 1, montantAchat: "", disabledMontant: true, errorMontant: -1 });
      this.onShowSave(this.state.errorDDE, 1, this.state.errorDES, this.state.cotisation, this.state.errorMontant)
      document
        .getElementById("TextFieldDEc")
        .parentNode.parentNode.classList.add("error-valide");
    }

    this.onchangeMontant(this.state.DDN, event.target.value, this.state.DES, this.state.cotisation)



  };
  /*  <--------------------------------------------- CHANGE DES ----------------------------------------------> */
  onchangeDES = (event) => {
    event.preventDefault();
    this.setState({ DES: event.target.value });
    let valDate = new Date(event.target.value);
    const dateDES = new Date(valDate);

    if ((dateDES < new Date(this.state.dateSouhaiteMin)) || (dateDES < new Date())) {
        this.setState({ errorDES: 1, montantAchat: "", disabledMontant: true, errorMontant: -1 });
        this.onShowSave(this.state.errorDDE, this.state.errorDEC, 1, this.state.cotisation, this.state.errorMontant)
        document
          .getElementById("TextFieldDEs")
          .parentNode.parentNode.classList.add("error-valide");
      } else {
        this.setState({
          errorDES: 0
        });
        document
          .getElementById("TextFieldDEs")
          .parentNode.parentNode.classList.remove("error-valide");
        this.onShowSave(this.state.errorDDE, this.state.errorDEC, 0, this.state.cotisation, this.state.errorMontant)

      }


    this.onchangeMontant(this.state.DDN, this.state.DEC, event.target.value, this.state.cotisation)



  };
  /*  <--------------------------------------------- CHANGE COTISATION ----------------------------------------------> */
  changeCotisation = (event) => {
    if (event.target.getAttribute("data-value") != null && event.target.getAttribute("data-value") != "") {
      this.setState({ cotisation: event.target.getAttribute("data-value") })
      this.onchangeMontant(this.state.DDN, this.state.DEC, this.state.DES, event.target.getAttribute("data-value"))

      this.onShowSave(this.state.errorDDE, this.state.errorDEC, this.state.errorDES,  event.target.getAttribute("data-value") , this.state.errorMontant)
    } else {
      this.setState({ cotisation: "", montantAchat: "", disabledMontant: true, errorMontant: -1 })
      this.onchangeMontant(this.state.DDN, this.state.DEC, this.state.DES, "")

      this.onShowSave(this.state.errorDDE, this.state.errorDEC, this.state.errorDES, null, this.state.errorMontant)

    }
  };
  /*  <--------------------------------------------- TEST MONTANT ----------------------------------------------> */
  onchangeMontant = (DDN, DEC, DES, cotisation) => {
    if ((DDN != "") && (DEC != "") && (DES != "") && (cotisation != "") && (cotisation != null)) {
      this.setState({
        disabledMontant: false
      })
    } else {
      this.setState({
        disabledMontant: true,
        montantAchat: "",
        errorMontant: -1
      })
    }
  }
  /*  <--------------------------------------------- CHANGE MONTANT ----------------------------------------------> */
  onchangeMontantValue = (event) => {
    this.setState({ montantAchat: event.target.value })

    let des = new Date(this.state.DES)
    let dec = new Date(this.state.DEC)
    let montant = event.target.value
    let max = 20 * 12 * this.state.cotisation
    if (max > 6000) {
      max = 6000
    }
    let desM = moment([des.getFullYear(), des.getMonth(), des.getDate()]);
    let decM = moment([dec.getFullYear(), dec.getMonth(), dec.getDate()]);
    let result = desM.diff(decM, "months");
    if (((new Date(this.state.DDN)).getFullYear()> (new Date(this.state.dateNeMin)).getFullYear()) && (result <= 3)) {
      // age < 75 ||  diff< 3 mois

      if ((montant < this.state.montantAchatMin1) || (montant > max)) {
        this.setState({ errorMontant: 1 })
        document
          .getElementById("TextFieldMontant")
          .parentNode.parentNode.classList.add("error-valide");
      } else {
        this.setState({ errorMontant: 0 })
        document
          .getElementById("TextFieldMontant")
          .parentNode.parentNode.classList.remove("error-valide");
      }
    } else {
      // age >= 75 ||  diff >3mois
      if ((montant < this.state.montantAchatMin2) || (montant > max)) {
        this.setState({ errorMontant: 2 })
        document
          .getElementById("TextFieldMontant")
          .parentNode.parentNode.classList.add("error-valide");
      } else {
        this.setState({ errorMontant: 0 })
        document
          .getElementById("TextFieldMontant")
          .parentNode.parentNode.classList.remove("error-valide");
      }
    }

  }
  /*  <--------------------------------------------- CLICK BTN ENREGISTRER ----------------------------------------------> */
  saveForm = () => {
    this.setState({ loading: true })
    const dataContrat = {
      nom: this.state.name,
      prenom: this.state.lastName,
      date_de_naissance: this.state.DDN,
      etat: "contrat",
      date_effet_souhaite: this.state.DES,
      cotisation: this.state.cotisation,
      date_effet_contrat: this.state.DEC,
      montant_achat: this.state.montantAchat,
      isIndependant: this.state.isIndependant,

    };
    let requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataContrat)
    };
    let base_urlcontrat = `${process.env.REACT_APP_URL}/propositionMacro/contrat/${getToken()}`;
    let parsed_visualiser = JSON.parse(localStorage.getItem("data_visualiser"));

    if (parsed_visualiser || (this.state.idProposition!= "")) {
      requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(dataContrat)
      };
      let id
      if(parsed_visualiser){
         id = parsed_visualiser[0].id
      }else{
        id = this.state.idProposition
      }
      base_urlcontrat = `${process.env.REACT_APP_URL}/propositionMacro/${id}/contrat/${getToken()}`;
    }
    fetch(base_urlcontrat, requestOptions)
      .then((response) => {

        if (response.status === 200) {
          return response.text()
        } else if (response.status === 404) {
          this.props.navigate("/Page_404");
        } else {
          this.props.navigate("/Page_500");
        }
      })
      .then((data) => {


        if (data.code == "-2") {
          this.setState({
            modalExpire: true,
            loading: false
          });
        } else if (data.code == "-1") {
          this.setState({
            modalServeur: true,
            loading: false
          })
        } else {
          this.setState({ idProposition: JSON.parse(data).proposition.id, Proje_num: JSON.parse(data).proposition.reference, })

          const base_urlcotisation = `${process.env.REACT_APP_URL_NODE}/simulation`;

            let vdDDN = new Date(this.state.DDN)


              let numM = "";
              if ((vdDDN.getMonth()+1) < 10) {
                numM = "0"
              }
              let numD = "";
              if (vdDDN.getDate() < 10) {
                numD = "0"
              }
             let valDDN = vdDDN.getFullYear() + "-" + numM + (vdDDN.getMonth()+1) + "-" + numD + vdDDN.getDate()

          let TabFinal = [];
          let TabSum = [];

          let tab_Paritelle = [];
          let tab_Totale_Final = [];
          this.setState({ tabSimu: [], dataSum: [] });

          let cotisation = this.state.cotisation;
          var varTest = false;

          const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json", "token": getToken() },
            body: JSON.stringify({
              dateDeNaissance: valDDN,
              cotisation: this.state.cotisation,
              primeInitiale: this.state.montantAchat
            })
          };

          fetch(base_urlcotisation, requestOptions)
            .then((response) => {
              if (response.status === 200) {
                return response.text()
              } else if (response.status === 404) {
                this.props.navigate("/Page_404");
              } else {
                this.props.navigate("/Page_500");
              }
            })
            .then((result) => {
              if (result === "BUS003") {
                varTest = true;
              } else {
                varTest = false;
                let Tab_init = [];
                let Tab_val_final = [];
                let resultParsed = JSON.parse(result);

                resultParsed.resultatSimulation.map((items) => {
                  if (
                    items.ageSortieCotisation === 65 ||
                    items.ageSortieCotisation === 70 ||
                    items.ageSortieCotisation === 75 ||
                    items.ageSortieCotisation === 80 ||
                    items.ageSortieCotisation === 85
                  ) {
                    Tab_init.push({
                      age: items.ageSortieCotisation,
                      Rente: items.renteAccumulee,
                      RenteRachat: items.niveauRenteRachat,
                      RenteTotal: items.renteTotale,
                    });
                  }
                  return true;
                });

                // console.log(Tab_init);
                /* ----------------------------------------------- Remplissage  TabRenteRachattemp \ TabRentetemp \ Tab_val_final -------------------------------------------------------- */
                let TabRenteRachattemp = [];
                let TabRentetemp = [];
                if (Tab_init.length < 4) {
                  Tab_val_final = [{ age: "65", RenteTotal: 0 }, { age: "70", RenteTotal: 0 }];
                  TabRenteRachattemp = [{ RenteRachat: 0 }, { RenteRachat: 0 }];
                  TabRentetemp = [{ Rente: 0 }, { Rente: 0 }];
                  Tab_val_final = Tab_val_final.concat(Tab_init);
                  TabRenteRachattemp = TabRenteRachattemp.concat(Tab_init);
                  TabRentetemp = TabRentetemp.concat(Tab_init);
                } else  if (Tab_init.length < 5) {
                  Tab_val_final = [{ age: "65", RenteTotal: 0 }];
                  TabRenteRachattemp = [{ RenteRachat: 0 }];
                  TabRentetemp = [{ Rente: 0 }];
                  Tab_val_final = Tab_val_final.concat(Tab_init);
                  TabRenteRachattemp = TabRenteRachattemp.concat(Tab_init);
                  TabRentetemp = TabRentetemp.concat(Tab_init);
                } else {
                  Tab_val_final = Tab_init;
                  TabRenteRachattemp = Tab_init;
                  TabRentetemp = Tab_init;
                }
                global.TabRenteRachat = TabRenteRachattemp;
                global.TabRente = TabRentetemp;
                Tab_val_final.map((items) => {
                  let item_val_partielle = parseFloat(0.25 * parseFloat(items.RenteTotal)).toFixed(2);
                  let val_partielle = "";
                  let val_final_partielle = "";
                  let v0_P = parseFloat(item_val_partielle % 1000).toFixed(2).split(".")[0];
                  let v1_P = parseFloat(item_val_partielle % 1000).toFixed(2).split(".")[1];

                  if (!(item_val_partielle > 0)) { val_final_partielle = "-"; }
                  else {
                    if (item_val_partielle / 1000 > 1) {
                      if (item_val_partielle % 1000 < 100) { val_partielle = "0" + v0_P + "," + v1_P; }
                      if (item_val_partielle % 1000 < 10) { val_partielle = "00" + v0_P + "," + v1_P; }
                      if (item_val_partielle % 1000 >= 100) { val_partielle = v0_P + "," + v1_P; }
                      if (Math.floor(item_val_partielle / 1000) > 0) { val_final_partielle = Math.floor(item_val_partielle / 1000) + " " + val_partielle + " €"; }
                      else { val_final_partielle = " " + val_partielle + " €"; }
                    } else {
                      val_partielle = v0_P + "," + v1_P;
                      if (Math.floor(item_val_partielle / 1000) > 0) {
                        val_final_partielle = Math.floor(item_val_partielle / 1000) + " " + val_partielle + " €";
                      } else {
                        val_final_partielle = " " + val_partielle + " €";
                      }
                    }
                  }

                  /* ---------------------------------------------------- val_Dépendance_totale ------------------------------------------------------- */
                  let item_val = items.RenteTotal;
                  let val = "";
                  let val_total = "";
                  let v0 = parseFloat(item_val % 1000).toFixed(2).split(".")[0];
                  let v1 = parseFloat(item_val % 1000).toFixed(2).split(".")[1];

                  if (item_val === 0) { val_total = "-"; } else {
                    if (item_val / 1000 > 1) {
                      if (item_val % 1000 < 100) { val = "0" + v0 + "," + v1; }
                      if (item_val % 1000 < 10) { val = "00" + v0 + "," + v1; }
                      if (item_val % 1000 >= 100) { val = v0 + "," + v1; }
                      if (Math.floor(item_val / 1000) > 0) { val_total = Math.floor(item_val / 1000) + " " + val + " €"; }
                      else { val_total = " " + val + " €"; }
                    } else {
                      val = v0 + "," + v1;
                      if (Math.floor(item_val / 1000) > 0) {
                        val_total = Math.floor(item_val / 1000) + " " + val + " €";
                      } else {
                        val_total = " " + val + " €";
                      }
                    }
                  }

                  tab_Paritelle.push(val_final_partielle);
                  tab_Totale_Final.push(val_total);


                  return true;
                });
              }
              if (varTest) {
                if (this.state.DDN) {
                  this.setState({
                    errorDDE: 1,
                    loading: false,
                  });
                  document
                    .getElementById("TextFieldDDn")
                    .parentNode.parentNode.classList.add("error-valide");
                }
              } else {
                this.setState({
                  Div_Stat: true,
                  errorDDE: 0,
                  tab_Paritelle: tab_Paritelle,
                  tab_Totale_Final: tab_Totale_Final,
                  tabSimu: TabFinal,
                  dataSum: TabSum,
                  loading: false
                });
                this.renderSimulation();
                this.renderSimulationtotale();
              }


              let Tab_TabRenteRachat = [];
              let Tab_TabRenteRachatParitelle = [];
              /* ------------------------------- update champ rente-------------------------------------------- */

              global.TabRenteRachat.map((items) => {
                let item_val = items.RenteRachat;
                let val = "";
                let val_total = "";
                let v0 = parseFloat(item_val % 1000)
                  .toFixed(2)
                  .split(".")[0];
                let v1 = parseFloat(item_val % 1000)
                  .toFixed(2)
                  .split(".")[1];

                if (item_val === 0) {
                  val_total = "-";
                } else {
                  if (item_val / 1000 > 1) {
                    if (item_val % 1000 < 100) {
                      val = "0" + v0 + "," + v1;
                    }
                    if (item_val % 1000 < 10) {
                      val = "00" + v0 + "," + v1;
                    }
                    if (item_val % 1000 >= 100) {
                      val = v0 + "," + v1;
                    }
                    if (Math.floor(item_val / 1000) > 0) {
                      val_total = Math.floor(item_val / 1000) + " " + val + " €";
                    } else {
                      val_total = " " + val + " €";
                    }
                  } else {
                    val = v0 + "," + v1;
                    if (Math.floor(item_val / 1000) > 0) {
                      val_total = Math.floor(item_val / 1000) + " " + val + " €";
                    } else {
                      val_total = " " + val + " €";
                    }
                  }
                }
                Tab_TabRenteRachat.push(val_total);
                if(val_total == '-'){
                  Tab_TabRenteRachatParitelle.push('-')
                }else{
                Tab_TabRenteRachatParitelle.push(parseFloat(parseFloat(val_total)*25/100).toFixed(2) + " €")
                }
                this.setState({  Tab_TabRenteRachat: Tab_TabRenteRachat, Tab_TabRenteRachatParitelle : Tab_TabRenteRachatParitelle});
                return true;
              });

              let valRente = "";
              valRente = Tab_TabRenteRachat[4] === "-" ? "0" : Tab_TabRenteRachat[4];
              this.setState({ rente: valRente });

              /***scroll tableau****/
              setTimeout(() => {
                var element = document.querySelector(".DivStat");
                element.scrollIntoView();
              }, 50);

            })
            .catch((error) => {
              this.setState({ loading: false, Div_Stat: false });

              this.props.navigate("/Page_500");
            });
        }


      })
      .catch((error) => {

        this.props.navigate("/Page_500");
      });
  };

  /*  <--------------------------------------------- STAT----------------------------------------------> */
  renderSimulation = () => {
    if (this.state.Tab_TabRenteRachatParitelle.length === 5) {
      return this.state.Tab_TabRenteRachatParitelle.map((u) => (
        <td className="" key={u}>
          {u.replace('.',',')}
        </td>
      ));
    }
  };
  /*  <--------------------------------------------- STAT ----------------------------------------------> */
  renderSimulationtotale = () => {
    if (this.state.Tab_TabRenteRachat.length === 5) {
      return this.state.Tab_TabRenteRachat.map((u) => (
        <td className="" key={u}>
          {u}
        </td>
      ));
    }
  };
  /*  <--------------------------------------------- BTN PRECEDENT ----------------------------------------------> */
  OnPrecedent = (event) => {

    event.preventDefault();
    let tag = event.currentTarget.dataset.tag;
    this.setState({
      showModal: true,
      namePage: tag,
    })
  };

  /* ------------------------------ FN_PDF ------------------------------ */
  handleClickPdf2() {
    this.setState({
      showLoadinBtnTel: true,
    });


    if (this.state.checkDoc) {
      const dataContrat = {
        nom: this.state.name,
        prenom: this.state.lastName,
        date_de_naissance: this.state.DDN,
        etat: "contrat1",
        date_effet_souhaite: this.state.DES,
        cotisation: this.state.cotisation,
        date_effet_contrat: this.state.DEC,
        montant_achat: this.state.montantAchat,
        isIndependant: this.state.isIndependant,

      };
      let requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(dataContrat)
      };
      let base_urlcontrat = `${process.env.REACT_APP_URL}/propositionMacro/${this.state.idProposition}/contrat/${getToken()}`;

      fetch(base_urlcontrat, requestOptions)
        .then((response) => {
          if (response.status === 200) {
            return response.text()
          } else if (response.status === 404) {
            this.props.navigate("/Page_404");
          } else {
            this.props.navigate("/Page_500");
          }
        })
        .then((data) => {
          if (data.code == "-2") {
            this.setState({
              modalExpire: true,
              showLoadinBtnTel: false
            });
          } else if (data.code == "-1") {
            this.setState({
              modalServeur: true,
              showLoadinBtnTel: false
            })
          } else {

            let infos = {
              nom: this.state.name,
              prenom: this.state.lastName,
              date_de_naissance: this.state.DDN,
              date_effet_contrat: this.state.DEC,
              date_effet_souhaite: this.state.DES,
              cotisation: this.state.cotisation,
              montantAchat: this.state.montantAchat,
              projet: this.state.Proje_num,
              checkClient: this.state.isIndependant,
              RenteTotal: this.state.rente,
              RentePartielle:  this.state.rente == "-"? "-":(parseFloat(parseFloat(this.state.rente)*25/100).toFixed(2)).replace(".", ","),
              courtier: getInformations().split("|")[2],
              adresse: getInformations().split("|")[0],
              postal: "",
              ville: "",
              mail: getInformations().split("|")[4],
              code: getInformations().split("|")[1],
            }
            const requestOptions = {
              method: "PUT",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify(infos),
            };
            fetch(`${process.env.REACT_APP_URL}/pdf/2/2/2/1`, requestOptions)
              .then((response0) => {
                if (response0.status === 200) {
                  return response0.json();
                } else if (response0.status === 404) {
                  this.props.navigate("/Page_404");
                } else {
                  this.props.navigate("/Page_500");
                }
              })
              .then((data0) => {
                let result = data0.result;
                let name = data0.name;
                for (var i = 0; i < result.length; i++) {
                  let linkSource = result[i];
                  let downloadLink = document.createElement("a");
                  downloadLink.href = "data:application/pdf;base64," + linkSource;
                  downloadLink.download = name[i];
                  downloadLink.click();
                }
                this.setState({
                  showLoadinBtnTel: false,
                });
              }).catch((error) => {
                this.props.navigate("/Page_500");
              });
          }


        })
        .catch((error) => {

          this.props.navigate("/Page_500");
        });

    }

  }


  render() {

    const btnLoading = this.state.showLoadinBtnTel ? "toggledLoading" : "";
    return (
      <>

        <h2 className="title_section">Informations du contrat existant</h2>
        <form id="formSimulation" onSubmit={this.OnShowDivStat} method="POST">
          <div className="row  r-15">
            <div className="col-xs-12 col-sm-8 col-md-6 boxLeftFormNp">
              <div className="form_n1">
                <div className="row r-15">
                  <div className="col-xs-12 col-sm-6 col-md-6  txt_form ">
                    Nom*
                  </div>
                  <div className="col-xs-12 col-sm-6 col-md-6  box_form">
                    <TextField
                      label="Nom"
                      placeholder="Nom"
                      type="text"
                      onKeyUp={() => this.onShowSave(this.state.errorDDE, this.state.errorDEC, this.state.errorDES, this.state.cotisation, this.state.errorMontant)}
                      onChange={(e) => this.onchangeName(e)}
                      value={this.state.name}
                    />
                    {(this.state.errorName === 1) && (
                      <span className="errorMsg errorMsgStyle1">
                        (*)Le format du champ renseigné n’est pas valide
                      </span>
                    )}
                    {(this.state.errorName === 2) && (
                      <span className="errorMsg errorMsgStyle1">
                        (*)Votre nom doit comporter au moins 2 caractères et un
                        maximum de 51 caractères.
                      </span>
                    )}
                  </div>
                </div>
                <div className="row r-15">
                  <div className="col-xs-12 col-sm-6 col-md-6 txt_form ">
                    Prénom*
                  </div>
                  <div className="col-xs-12 col-sm-6 col-md-6  box_form">
                    <TextField
                      id=""
                      label="Prénom"
                      type="text"
                      onKeyUp={() => this.onShowSave(this.state.errorDDE, this.state.errorDEC, this.state.errorDES, this.state.cotisation, this.state.errorMontant)}
                      onChange={(e) => this.onchangeLastName(e)}
                      value={this.state.lastName}
                    />
                    {(this.state.errorLastName === 1) && (
                      <span className="errorMsg errorMsgStyle1">
                        (*)Le format du champ renseigné n’est pas valide
                      </span>
                    )}
                    {(this.state.errorLastName === 2) && (
                      <span className="errorMsg errorMsgStyle1">
                        (*)Votre prénom doit comporter au moins 2 caractères et
                        un maximum de 51 caractères.
                      </span>
                    )}
                  </div>
                </div>
                <div className="row r-15">
                  <div className="col-xs-12 col-sm-6 col-md-6  txt_form ">
                    Date de naissance de l’adhérent*
                  </div>
                  <div className="col-xs-12 col-sm-6 col-md-6  box_form">
                    <TextField
                      id="TextFieldDDn"
                      label="Date de naissance*"
                      name="date_de_naissance"
                      type="date"
                      disabled={this.state.disabledDDN}
                      onChange={this.onchangeDDN}
                      className="dateNaissance"
                      value={this.state.DDN}
                      inputProps={{
                        max: this.state.dateNeMax,
                        min: this.state.dateNeMin
                      }}
                    />

                  {this.state.errorMsgDateNaisInvalid && (
                    <span className="errorMsg errorMsgStyle1">
                      (*)L'âge de l'assuré est devenue invalide.
                    </span>
                  )}
                    {this.state.errorDDE === 1 ?
                      <span className="errorMsg errorMsgStyle1">
                        (*)La date de naissance est invalide. L'âge à la date d'effet de l'achat d'unités de rente additionnelles doit être au minimum de 18 ans et au
                        maximum de 74 ans (inclus).
                      </span>
                      : null}

                  </div>
                </div>

                <div className="row r-15">
                  <div className="col-xs-12 col-sm-6 col-md-6 txt_form ">
                    Date d’effet du contrat existant
                  </div>
                  <div className="col-xs-12 col-sm-6 col-md-6 box_form">
                    <TextField
                      id="TextFieldDEc"
                      label="Date d’effet du contrat"
                      name="date_effet_contrat"
                      type="date"
                      variant="outlined"
                      className="DateEffet"
                      onChange={this.onchangeDEC}
                      defaultValue={this.state.dateContratMax}
                      value={this.state.DEC}
                      inputProps={{
                        min: this.state.dateContratMin,
                        max: this.state.dateContratMax,
                      }}
                    />
                    {(this.state.errorDEC === 1) ?
                      <span className="errorMsg errorMsgStyle1">
                        (*)La date d’effet du contrat est invalide, elle doit être au 1er du mois.
                      </span>
                      : null}
                  </div>
                </div>
                <div className="row r-15">
                  <div className="col-xs-12 col-sm-6 col-md-6   txt_form ">
                    Cotisation mensuelle du contrat existant*
                  </div>
                  <div className="col-xs-12 col-sm-6 col-md-6   box_form">
                    <Box>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Cotisation mensuelle
                        </InputLabel>
                        <Select
                          labelId="select-Cotisation"
                          id="demo-simple-select"
                          className="cot"
                          name="cotisation"
                          label=""
                          value={this.state.cotisation}
                          onClose={this.changeCotisation}
                          onChange={(e) =>
                            this.setState({
                              cotisation: e.target.value,
                            })
                          }
                        >
                          <MenuItem value="20">20 €</MenuItem>
                          <MenuItem value="40">40 €</MenuItem>
                          <MenuItem value="60">60 €</MenuItem>
                          <MenuItem value="80">80 €</MenuItem>
                          <MenuItem value="100">100 €</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </div>
                </div>

                <h2 className="title_section">
                  Informations achat unités de rente additionnelles
                </h2>

                <div className="row r-15">
                  <div className="col-xs-12 col-sm-6 col-md-6 txt_form ">
                    Date d'effet souhaitée*
                  </div>
                  <div className="col-xs-12 col-sm-6 col-md-6   box_form">
                    <TextField
                      id="TextFieldDEs"
                      label="Date d’effet du versement"
                      name="date_effet_souhaite"
                      type="date"
                      className="DateEffet"
                      onChange={this.onchangeDES}
                      defaultValue={this.state.dateSouhaiteMin}
                      value={this.state.DES}
                      inputProps={{
                        min: this.state.dateSouhaiteMin,
                      }}
                    />
                    {(this.state.errorDES === 1) ?
                      <span className="errorMsg errorMsgStyle1">
                        (*)La date d'effet souhaitée est invalide, elle doit être postérieure à la date du jour et à la date d'effet du contrat.</span>
                      : null}
                    <Tippy content="La date d'effet souhaitée doit être au plus tôt au  lendemain de la réception de la demande d'achat d'unités de rente additionnelles par REPAM. ">
                      <HelpRounded className="iconhelpR ms-2" />
                    </Tippy>
                  </div>
                </div>


                <div className="row r-15">
                  <div className="col-xs-12 col-sm-6 col-md-6 txt_form ">
                    Versement complémentaire souhaité*
                  </div>
                  <div className="col-xs-12 col-sm-6 col-md-6 box_form">
                    <TextField
                      id="TextFieldMontant"
                      label="Montant de l'achat"
                      variant="outlined"
                      type="number"
                      className="inputVersement"
                      value={this.state.montantAchat}
                      disabled={this.state.disabledMontant}
                      onChange={(e) => this.onchangeMontantValue(e)}
                      onKeyUp={() => this.onShowSave(this.state.errorDDE, this.state.errorDEC, this.state.errorDES, this.state.cotisation, this.state.errorMontant)}


                    />
                    <span className="units-value" id="unitsValue">
                      €
                    </span>
                    {(this.state.errorMontant === 1) ?
                      <>
                        <span
                          className="units-value error-valide"
                          id="unitsValue"
                        >
                          €
                        </span>
                        <span className="errorMsg errorMsgStyle1">
                          Le montant du versement est limité à 20 fois la cotistion annuelle dans la limite de 6000  €.
                        </span>
                      </> : ((this.state.errorMontant === 2) ? (
                        <>
                          <span
                            className="units-value error-valide"
                            id="unitsValue"
                          >
                            €
                          </span>
                          <span className="errorMsg errorMsgStyle1">
                            Le montant du versement est limité à 20 fois la cotistion annuelle avec un minimum de 1 000 € et dans la limite 6000 €.
                          </span>
                        </>
                      ) : null)}
                    <Tippy content={<>Le montant de ce versement est limité à 20 fois le montant de la cotisation annuelle avec un maximum de 6 000 € (dans la limite de 18 000 € sur toute la durée du contrat) et un minimum de : <br />  - dans les 3 mois suivant la souscription du contrat : pas de minimum  <br />  - au-delà des 3 mois suivant la souscription du contrat : 1 000 €  </>} >
                      <HelpRounded className="iconhelpR ms-2" />
                    </Tippy>
                  </div>
                </div>

                <div className="col-xs-12 col-sm-12 colstart rw">
                  <div className="toggle r-15">
                    <input
                      type="checkbox"
                      value={this.state.isIndependant}
                      checked={this.state.isIndependant}
                      onChange={(e) =>
                        this.setState({ isIndependant: !this.state.isIndependant })
                      }
                      className="inputCheckbox"
                    />
                  </div>
                  <p className="txt_form_Toggle">
                    Mon client est travailleur indépendant et souhaite
                    bénéficier du dispositif Madelin
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xs-12 xs-offset-0 col-sm-4 sm-offset-0 col-md-5 offset-md-1 boxRightFormNp box_blue">
              <h2>
                <img src={info} alt={info} className="img-responsive" />
                ACHETER DES UNITES DE RENTE SUR UN CONTRAT EXISTANT
              </h2>
              <p>
                <b>Pourquoi ?</b> <br />
                L’achat d’unités de rente Dépendance additionnelles permet à
                l’assuré d’augmenter le montant de sa rente Dépendance en
                complément de ce qu’il aura acquis par ses versements
                périodiques.
                <br />
                <b> Comment faire ?</b>
                <br /> L’achat se fait sous forme d’un versement, réalisé
                en complément de la cotisation mensuelle sélectionnée.
                <br />
                <b>Le montant est-il limité ?</b>
                <br />
                Le montant de ce versement est limité à 20 fois le montant de la cotisation annuelle avec un maximum de 6 000 € (dans la limite de <br />18 000 € sur toute la durée du contrat) et un minimum de :
                <br />
                - dans les 3 mois suivant la souscription du contrat : pas de minimum
                <br />
                - au-delà des 3 mois suivant la souscription du contrat : 1 000 €
                <br />
                <b>Quelle est la fréquence des versements ?</b>
                <br />
                Cette faculté est ouverte tous les 2 ans à l’assuré ayant moins
                de 75 ans.
                <br />
                <b>Y a-t-il des formalités médicales ?</b>
                <br />
                Cet achat sera soumis à un questionnaire de santé et le cas
                échéant à un questionnaire médical.
              </p>
            </div>
          </div>

        </form>
        {/*    <-------------------------------------------------- Div Stat -----------------------------------------------------------> */}
        {this.state.Div_Stat && (
          <div className="row r-15 p-relative">
              <div className="DivStat">
                <div className="row r-15 ">
                  <div className="col-12 textTitle">
                    Simulation de la rente mensuelle *
                  </div>
                  <span style={{ color: "#445aa5" }} > Cette rente s'ajoutera au montant de la rente du contrat initialement souscrit.</span>

                </div>

                <div className="row r-15">
                  <div className="col-12">
                    <div className="divTableCotisation">

                      <div className="row r-15">
                        <div className="clsTable box3 table-responsive">
                          <table className="table tableRentes tableJob">
                            <tr>
                              <th rowSpan="2">TYPE DE DÉPENDANCE</th>
                              <th colSpan="5">
                                ÂGE DE RECONNAISSANCE DE LA DÉPENDANCE
                              </th>
                            </tr>
                            <tr>
                              <td className="td1"> 65 ans </td>
                              <td className="td1"> 70 ans </td>
                              <td className="td1"> 75 ans </td>
                              <td className="td1"> 80 ans </td>
                              <td className="td1"> 85 ans </td>
                            </tr>
                            <tr>
                              <th>
                                DÉPENDANCE PARTIELLE
                                <Tippy content="La dépendance partielle correspond à un versement de 25% de la rente">
                                  <HelpRounded className="iconhelpR ms-2" />
                                </Tippy>
                              </th>
                              {this.renderSimulation()}
                            </tr>
                            <tr>
                              <th>
                                DÉPENDANCE TOTALE
                                <Tippy content="La dépendance totale correspond à un versement de 100% de la rente">
                                  <HelpRounded className="iconhelpR ms-2" />
                                </Tippy>
                              </th>
                              {this.renderSimulationtotale()}
                            </tr>
                          </table>
                          <p>
                            <small>
                              *Cette simulation est réalisée sur la base de la valeur d'acquisition de l'unité de rente en vigueur en 2023.
                            </small>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

            {/* <--------------------------------------------------Start Div Document à télécharger----------------------------------------------------------> */}
            {(
              <div className="row blocDocTelecharge">
                <div className="row r-15">
                  <hr className="hr w-100" />
                </div>
                <div className="row">
                  <h3 className="textBudget">Document à télécharger</h3>
                </div>
                <div className="row listeChoixDocDnw">
                  <div className="col-xs-12 col-md-5">
                    <div className="boxCeckbox">
                      <input
                        value="demande"
                        name="Val_Doc_Telecharge"
                        id="checkDoc"
                        type="checkbox"
                        onChange={() =>
                          this.setState({ checkDoc: !this.state.checkDoc })
                        }
                        checked={this.state.checkDoc}
                        className="formCheck"
                      />
                    </div>
                    <div className="divCheck">
                      <text className="textDT">Demande achat unités de rente additionnelles</text>
                    </div>
                  </div>

                </div>


                <div className="row row_btn">
                  <Button
                    variant="contained"
                    className={`btnActiveTele btn-default btn_form ${btnLoading}`}
                    onClick={() =>
                      this.handleClickPdf2()
                    }
                    disabled={!this.state.checkDoc}
                  >
                    Télécharger
                    <div className="ld-spin "></div>
                  </Button>
                </div>

                <div className="row r-15">
                  <hr className="hr w-100" />
                </div>


                <div className="row r-15 navigationSection ">
                  <div className="col-xs-12 col-md-12 offset-md-12 colend">
                    <Link
                      className="btn btn-default"
                      variant="btn"
                      to={`/dependance/${getToken()}`}
                    >
                      Précédent
                    </Link>
                    <ModalValidation />
                  </div>
                </div>
              </div>
            )}


          </div>
        )}


        {!this.state.Div_Stat ? <div className="row r-15 navigationSection">
          <div className="col-xs-12 offset-xs-0 col-md-126 offset-md-12 colend">
            <Link
              className="btn btn-default"
              variant="btn"
              to={`/dependance/${getToken()}`}
            >
              Précédent
            </Link>
            {this.state.disabledBtn ?
              <Tippy
                content="Un ou plusieurs champs sont en erreur ou ne sont pas renseignés."
                maxWidth={180}
              >
                <Button className="btn btn-default " id="disabled">
                  Enregistrer
                </Button>
              </Tippy>
              :
              <Button
                className="btn btn-default btn_form"
                disabled={this.state.disabledBtn}
                onClick={this.saveForm}
              >
                Enregistrer
              </Button>
            }
          </div>
        </div> : null
        }
        <ModalExpire show={this.state.modalExpire} />
        {this.state.modalServeur ? (
          <ModalServeur
            handleCloseModalServeur={() => this.handleCloseModalServeur()}
          />
        ) : null}

        {this.state.loading && (
          <div className="loading">
            <div className="boxLoading">
              <img src={rotation} alt={rotation} className="svgrotation" />
              <img src={repamSvg} alt={repamSvg} />
            </div>
          </div>
        )}

        {this.state.showModal && (
          <ModalQuit
            page={this.state.namePage}
            OnchangeModal={this.OnchangeModal}
          />
        )}
      </>
    );
  }
}
export default formProposition;
