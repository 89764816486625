import React, { Component } from "react";
import ModalQuit from "./CommonComponent/modalQuit";
import { withRouter } from "./whitRouter";
import "./ComponentSimulation/Simulation.css";
import $ from "jquery";
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import rotation from "../../assets/img/rotation.svg";
import repamSvg from "../../assets/img/repam.svg";
import { Button, Form } from "react-bootstrap";
import { HelpRounded, Close, InfoOutlined, ConnectedTvOutlined } from "@mui/icons-material";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional
import "react-phone-input-2/lib/material.css";
import FormHelperText from "@mui/material/FormHelperText";
import icoFile from "../../assets/img/icoFile.svg";
import { getToken, docDownload } from "../../Context/ApiContext";
import ModalExpire from "./CommonComponent/modalExpire";
import ModalServeur from "./CommonComponent/modalServeur";

class Souscription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      APE: "",
      AFE: "",
      DDE: "",
      DDN: "",
      dateEffetMax:"",
      dateEffetMin:"",
      errorMsgDateEffet: false,
      modalExpire: false,
      modalServeur: false,
      CheckClient: false,
      /* <-------------- state - periodicite ---------------> */
      Periodicite: "Sélectionner",
      valide_Periodicite: true,
      focusPeriodicite: false,
      ColorPeriodicite: "#9AA0AC",
      Arrow_Style_Periodicite: "Select_BK",

      /* <---------- state - Jour de prélèvement -----------> */
      jour_de_prelevement: "Sélectionner",
      valide_Prelevement: true,
      focusPrelevement: false,
      ColorPrelevement: "#9AA0AC",
      Arrow_Style_Prelevement: "Select_BK",

      /* <----------- state - Nom du titulaire -------------> */
      Nom_du_titulaire: "",
      focusNDT: false,
      valide_NDT: true,
      ColorNDT: "#9AA0AC",

      /* <------------------ state - IBAN ------------------> */
      IBAN: "fr0000000000000000000000000",
      focusIBAN: false,
      valide_IBAN: true,
      ColorIBAN: "#9AA0AC",

      /* <--------------------- state - BIC ----------------> */
      BIC: "aaaaaaaaaaa",
      focusBIC: false,
      valide_BIC: true,
      ColorBIC: "#9AA0AC",

      /* <---------- state - Nom de la banque --------------> */
      Nom_de_banque: "aaaaaaaaaaa",
      focusNDLB: false,
      valide_NDLB: true,
      ColorNDLB: "#9AA0AC",

      /* <-------------------- state - btn -----------------> */
      Val_Doc_Telecharge: "",
      Btn_Eng_Tele: false,
      ShowDivBtnEng: true,
      DocTelecharge: false,
      btnObtenir: false,
      ShowDivMensuelle: false,

      /* <----------- state - tabe-form -------------------> */
      TabForm: [],

      /* <-------------- state - modal --------------------> */
      show_Modal: false,
      Div_Signature: false,
      dataStorage: [],
      dataStorageDep: [],
      ID_visualiser_sous: "",
      id_Souscription: "",
      Response: "",
      BTN_visualiser: false,
      id_Proposition: "",
      loading: false,
      showModal: "",
      namePage: "",
    };
  }

  componentDidMount() {

    /* --------------------------------- Input Disabled ------------------------------------------ */
    $("div.Disabled  fieldset").addClass("without_fieldset");
    $("div.Disabled div.MuiInputBase-formControl").css("background-color", "#f1f1f1");
    $("div.Disabled input ").css("-webkit-text-fill-color", "#000");

    /*  <----------------------------------- show / hidden Q1----------------------------------------> */
    $("#helpQ1").hover(function () {
      $("div#divQ1").removeClass("divHidden");
      $("div#divQ2").addClass("divHidden");
    });
    $("#closeQ1").click(function () {
      $("div#divQ1").addClass("divHidden");
    });
    /*  <----------------------------------- show / hidden Q2----------------------------------------> */
    $("#helpQ2").hover(function () {
      $("div#divQ2").removeClass("divHidden");
      $("div#divQ1").addClass("divHidden");
    });
    $("#closeQ2").click(function () {
      $("div#divQ2").addClass("divHidden");
    });

    /* ------------------------------------- Data LocalStorage data_visualiser_sous --------------------------------------- */
    let id = "";
    let parsed_dependance = JSON.parse(localStorage.getItem("Data_Dependance"));
    let parsed_visualiser = JSON.parse(localStorage.getItem("data_visualiser"));
      /* ---------------------------------------- DDE ------------------------------------------------ */
      const date = new Date();
      const currentYear = date.getFullYear();
      const currentMonth = date.getMonth() + 1;
      let v_month_DDE = "";
      if (currentMonth < 10) {
        v_month_DDE = "0";
      }
      this.setState({
        dateEffetMin: currentYear +"-"+v_month_DDE+(currentMonth+1 )+"-01",
   })
    if (parsed_visualiser) {

      id = parsed_visualiser[0].id;

      fetch(`${process.env.REACT_APP_URL}/proposition/${id}/${getToken()}`)
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else if (response.status === 404) {
            this.props.navigate("/Page_404");
          } else {
            this.props.navigate("/Page_500");
          }
        })
        .then((data) => {
          if (data.code == "-2") {
            this.setState({ loading: false, modalExpire: true, });
          }
          else if (data.code == "-1") {
            this.setState({
              modalServeur: true,
              loading: false,
            })
          }
          else {
            this.setState({ modalExpire: false, }); let items = data.proposition;

            let val_APE2, val_AFE2, val_isTNS2;


            if (items.souscription["isAPF"] !== null) {
              val_APE2 = items.souscription["isAPF"] === true ? "true" : "false";
            }
            if (items.souscription["isAFE"] !== null) {
              val_AFE2 = items.souscription["isAFE"] === true ? "true" : "false";
            }
            if (items.souscription["isTNS"] !== null) {
              val_isTNS2 = items.souscription["isTNS"] === true ? "true" : "false";
            }
            
            //
            let dateM = new Date(items.client["date_de_naissance"]);
            let currentYearM = dateM.getFullYear();
          
            //
            this.setState({
              dateEffetMax: currentYearM+74 +"-12-01",
              DDN: items.client["date_de_naissance"],
              APE: val_APE2,
              AFE: val_AFE2,
              CheckClient: items.souscription["isTNS"],
              Periodicite: items.souscription["periodicite"],
              jour_de_prelevement: items.souscription["jour_de_prelevement"],
              Nom_du_titulaire: items.souscription["nom_du_titulaire"],
              IBAN: items.souscription["IBAN"],
              BIC: items.souscription["BIC"],
              id_Souscription: items.souscription["id"],
              Nom_de_banque: items.souscription["nom_de_la_banque"],
              DDE: items.souscription["date_effet_souhaite"],
              id_Proposition: items.id,
              etat: items.etat,
              BTN_visualiser: true,
            });
            if (items.souscription["periodicite"]) {
              $("div.Periodicite  ,div.Prelevement  ").addClass("Select_Bl");
              $("div.Periodicite  fieldset,div.Prelevement fieldset").addClass("Select_Bl input_valid");
            }

          }
        })
        .catch((error) => {
          this.props.navigate("/Page_500");
        });
    } else if (parsed_dependance) {
      id = parsed_dependance[0].id;

      fetch(`${process.env.REACT_APP_URL}/proposition/${id}/${getToken()}`)
        .then((response) => {

          if (response.status === 200) {
            return response.json();
          } else if (response.status === 404) {
            this.props.navigate("/Page_404");
          } else {
            this.props.navigate("/Page_500");
          }
        }).then((data) => {

          if (data.code == "-2") {
            this.setState({ loading: false, modalExpire: true, });
          } else if (data.code == "-1") {
            this.setState({
              modalServeur: true,
              loading: false,
            })

          } else {

            let items = data.proposition;
 
            //
            let dateM = new Date(items.client["date_de_naissance"]);
            let currentYearM = dateM.getFullYear();
            
            //
            this.setState({ modalExpire: false, DDN: items.client["date_de_naissance"],
            dateEffetMax: currentYearM +74+"-12-01",
            
          });
           
            if (
              items.souscription["isAPF"] !== null &&
              items.souscription["isAFE"] !== null &&
              items.souscription["isTNS"] !== null &&
              items.souscription["periodicite"] !== null &&
              items.souscription["jour_de_prelevement"] !== null &&
              items.souscription["nom_du_titulaire"] !== null &&
              items.souscription["IBAN"] !== null &&
              items.souscription["BIC"] !== null &&
              items.souscription["nom_de_la_banque"] !== null
            ) {

              let dateE = new Date(items.souscription["date_effet_souhaite"]);
              let currentYearE = dateE.getFullYear();
              let currentMonthE = dateE.getMonth() + 1;
              let currentDateE= dateE.getDate();
              let v_month_E = "";
              let v_day_E = "";
              if (currentMonthE < 10) {
                v_month_E = "0";
              }
              if (currentDateE < 10) {
                v_day_E= "0";
              }

              this.setState({
                APE: items.souscription["isAPF"] === true ? "true" : "false",
                AFE: items.souscription["isAFE"] === true ? "true" : "false",
                CheckClient: items.souscription["isTNS"],
                Periodicite: items.souscription["periodicite"],
                jour_de_prelevement: items.souscription["jour_de_prelevement"],
                Nom_du_titulaire: items.souscription["nom_du_titulaire"],
                IBAN: items.souscription["IBAN"],
                BIC: items.souscription["BIC"],
                id_Souscription: items.souscription["id"],
                DDE:  currentYearE +"-"+v_month_E+currentMonthE +"-" + v_day_E + currentDateE,
                Nom_de_banque: items.souscription["nom_de_la_banque"],
                id_Proposition: items.id,
                etat: items.etat,
                BTN_visualiser: true,
              });
              $("div.Periodicite  ,div.Prelevement  ").addClass("Select_Bl");
              $("div.Periodicite  fieldset,div.Prelevement fieldset").addClass("Select_Bl input_valid");
            } else {
              this.setState({
                Nom_du_titulaire:
                  items.client["prenom"] + " " + items.client["nom"],
                id_Proposition: items.id,
                id_Souscription: items.souscription["id"],
              });
            }
          }
        })
        .catch((error) => {
          this.props.navigate("/Page_500");
        });
    }

 

  }
  /* <---------------------  close modal serveur error   ------------------> */
  handleCloseModalServeur = () => {
    this.setState({
      modalServeur: false
    })
  }
  /*  <-----------------------------------FN Modal Show -----------------------------------------------> */
  Modal_show = () => {
    this.setState({ show_Modal: !this.state.show_Modal });
  };

  /* <---------------------  Function Change Nom de naissance / Ville_de_naissance / Ville  ------------------> */

  OnChange = (e, val1, val2) => {
    let val_bool = true;
    switch (val2) {
      case "Nom_du_titulaire":
        this.setState({
          Nom_du_titulaire: e.target.value,
          valide_NDT: val_bool,
        });
        this.valNTu = true;

        break;
      case "Nom_de_banque":
        this.setState({ Nom_de_banque: e.target.value, valide_NDLB: val_bool });
        this.valNBank = true;
        break;

      default:
        break;
    }

    if (
      e.target.value !== "" &&
      e.target.value.length > 1 &&
      e.target.value.length < 51
    ) {
      val_bool = true;
      $("div." + val1 + "  fieldset").addClass("input_valid");
      $("div." + val1 + "  fieldset").removeClass("input_invalid");
    } else {

      val_bool = false;
      $("div." + val1 + "  fieldset").removeClass("input_valid");
      $("div." + val1 + "  fieldset").addClass("input_invalid");
      $("div." + val1 + " div.MuiInputBase-formControl").css(
        "background-color",
        "#fff"
      );
    }
  };

  onBlur = (e, val1, val2) => {
    let val_input = "";
    switch (val2) {
      case "Nom_du_titulaire":
        val_input = this.state.Nom_du_titulaire;
        break;
      case "Nom_de_banque":
        val_input = this.state.Nom_de_banque;
        break;
      default:
        break;
    }

    let val_bool = true;
    let val_Color = "";
    if (
      val_input !== "" &&
      e.target.value.length > 2 &&
      e.target.value.length < 51
    ) {
      val_bool = true;
      val_Color = "#445aa5";
    } else {
      val_bool = false;
      val_Color = "#ED1C24";
      $("div." + val1 + "  fieldset").addClass("input_invalid");
    }

    switch (val1) {
      case "NDT":
        this.setState({ valide_NDT: val_bool, ColorNDT: val_Color });
        break;
      case "NDLB":
        this.setState({ valide_NDLB: val_bool, ColorNDLB: val_Color });
        break;
      default:
        break;
    }
  };

  /*  <----------------------------------- On Change APE-----------------------------------------------> */
  OnChangeAPE = (e) => {
    this.setState({
      APE: e.target.value,
    })
  };

  /*  <----------------------------------- On Change AFE ----------------------------------------------> */
  OnChangeAFE = (e) => {
    this.setState({
      AFE: e.target.value,
    })
  };

  /*  <----------------------------------- On Change Periodicite----------------------------------------> */

  OnChangePER = (e) => {
    this.setState({ Periodicite: e.target.value });

    if (e.target.value !== "Sélectionner") {
      this.setState({
        valide_Periodicite: true,
        Arrow_Style_Periodicite: "Select_Bl",
      });
      $("div.Periodicite  fieldset").addClass("input_valid");
      this.valPer = true;
    } else {
      this.setState({
        valide_Periodicite: false,
        Arrow_Style_Periodicite: "Select_R",
      });
      this.valPer = false;
    } /*   <------------------------ Show Div Mensuelle-----------------------------> */

    if (e.target.value === "Mensuelle") {
      this.setState({ ShowDivMensuelle: true });
    } else {
      this.setState({ ShowDivMensuelle: false });
    }
  };

  onFocusPER = () => {
    if (this.state.Periodicite !== "Sélectionner") {
      this.setState({
        focusPeriodicite: true,
        ColorPeriodicite: "#445aa5",
        Arrow_Style_Periodicite: "Select_Bl",
      });
      this.valPer = true;
    } else {
      this.setState({
        focusPeriodicite: false,
        ColorPeriodicite: "#ed1c24",
        Arrow_Style_Periodicite: "Select_R",
      });
      this.valPer = false;
      $("div.Periodicite.Select_R  .MuiSelect-select").css("opacity", "1");
    }
  };
  onBlurPER = () => {
    if (this.state.Periodicite !== "Sélectionner") {
      $("div.Periodicite  fieldset").removeClass("input_invalid");
      $("div.Periodicite  fieldset").addClass("input_valid");
      this.setState({ valide_Periodicite: true, ColorPeriodicite: "#445aa5" });
      this.valPer = true;
    } else {
      $("div.Periodicite  fieldset").addClass("input_invalid");
      $("div.Periodicite  fieldset").removeClass("input_valid");
      this.setState({ valide_Periodicite: false, ColorPeriodicite: "#ED1C24" });
      this.valPer = false;
    }
  };

  /*  <--------------------------------------- On Change JDP--------------------------------------------> */

  OnChangeJDP = (e) => {
    this.setState({ jour_de_prelevement: e.target.value });

    if (e.target.value !== "Sélectionner") {
      this.setState({
        valide_Prelevement: true,
        Arrow_Style_Prelevement: "Select_Bl",
      });
      this.valJour = true;

      $("div.Prelevement  fieldset").addClass("input_valid");
    } else {
      this.setState({
        valide_Prelevement: false,
        Arrow_Style_Prelevement: "Select_R",
      });
      this.valJour = false;
    }
  };

  onFocusJDP = () => {
    if (this.state.jour_de_prelevement !== "Sélectionner") {
      this.setState({
        focusPrelevement: true,
        ColorPrelevement: "#445aa5",
        Arrow_Style_Prelevement: "Select_Bl",
      });
      this.valJour = true;
    } else {
      this.setState({
        focusPrelevement: false,
        ColorPrelevement: "#ed1c24",
        Arrow_Style_Prelevement: "Select_R",
      });
      this.valJour = false;

      $("div.Prelevement.Select_R  .MuiSelect-select").css("opacity", "1");
    }
  };
  onBlurJDP = () => {
    if (this.state.jour_de_prelevement !== "Sélectionner") {
      $("div.Prelevement  fieldset").removeClass("input_invalid");
      $("div.Prelevement  fieldset").addClass("input_valid");
      this.setState({ valide_Prelevement: true, ColorPrelevement: "#445aa5" });
      this.valJour = true;
    } else {
      $("div.Prelevement  fieldset").addClass("input_invalid");
      $("div.Prelevement  fieldset").removeClass("input_valid");
      this.setState({ valide_Prelevement: false, ColorPrelevement: "#ED1C24" });
      this.valJour = false;
    }
  };

  /*  <--------------------------------------- On Change NDT--------------------------------------------> */
  OnChangeNDT = (e) => {
    if (!e.target.value.replace(/\s+/, "").length) {
      this.setState({
        Error_ndt: "Nom du titulaire est invalide.",
      });
      $("div.NDT  fieldset").addClass("input_invalid");
      $("div.NDT  fieldset").removeClass("input_valid");
      this.valNTu = false;
    } else if (e.target.value !== "") {
      this.setState({ NDT: e.target.value, Error_ndt: "" });
      $("div.NDT  fieldset").addClass("input_valid");
      $("div.NDT  fieldset").removeClass("input_invalid");
      this.valNTu = true;
    } else {
      $("div.NDT  fieldset").addClass("input_invalid");
      $("div.NDT  fieldset").removeClass("input_valid");
      this.valNTu = false;
    }
  };

  /*  <-------------------------------------- On Change IBAN---------------------------------------------> */
  OnChangeIBAN = (e) => {
    this.setState({ IBAN: e.target.value });

    if (
      e.target.value !== "" &&
      e.target.value.length === 27 &&
      /^[F][R][A-Z-a-z-0-9]*$/i.test(e.target.value)
    ) {
      this.setState({
        valide_IBAN: true,
      })
      $("div.IBAN  fieldset").addClass("input_valid");
      $("div.IBAN  fieldset").removeClass("input_invalid");
      this.valIban = true;
    } else {
      this.setState({ valide_IBAN: false });
      $("div.IBAN  fieldset").removeClass("input_valid");
      $("div.IBAN  fieldset").addClass("input_invalid");
      this.valIban = false;
    }
  };

  onBlurIBAN = (e) => {
    if (
      this.state.IBAN !== "" &&
      this.state.IBAN.length === 27 &&
      /^[F][R][A-Z-a-z-0-9]*$/i.test(this.state.IBAN)
    ) {
      this.setState({ valide_IBAN: true, ColorIBAN: "#445aa5" });
      $("div.IBAN  fieldset").removeClass("input_invalid");
      this.valIban = true;
    } else {
      $("div.IBAN  fieldset").addClass("input_invalid");
      this.setState({ valide_IBAN: false, ColorIBAN: "#ED1C24" });
      this.valIban = false;
    }
  };
  /*  <--------------------------------------- On Change BIC---------------------------------------------> */
  OnChangeBIC = (e) => {
    this.setState({ BIC: e.target.value });

    if (/^[A-Z-a-z]*$/i.test(e.target.value) && e.target.value.length === 8) {
      this.setState({ BIC: e.target.value, valide_BIC: true });
      $("div.BIC  fieldset").addClass("input_valid");
      $("div.BIC  fieldset").removeClass("input_invalid");
      this.valBic = true;
    } else if (
      /^[A-Z-a-z]*$/i.test(e.target.value) &&
      e.target.value.length === 11
    ) {
      this.setState({ BIC: e.target.value, valide_BIC: true });
      $("div.BIC  fieldset").addClass("input_valid");
      $("div.BIC  fieldset").removeClass("input_invalid");
      this.valBic = true;
    } else {
      this.setState({ valide_BIC: false });
      $("div.BIC  fieldset").removeClass("input_valid");
      $("div.BIC  fieldset").addClass("input_invalid");
      this.valBic = false;
      // $("div.BIC div.MuiInputBase-formControl").css("background-color", "#fff");
    }
  };

  onBlurBIC = (e) => {
    if (/^[A-Z-a-z]*$/i.test(this.state.BIC) && this.state.BIC.length === 8) {
      this.setState({ BIC: this.state.BIC, valide_BIC: true });
      $("div.BIC  fieldset").addClass("input_valid");
      $("div.BIC  fieldset").removeClass("input_invalid");
      this.valBic = true;
    } else if (
      /^[A-Z-a-z]*$/i.test(this.state.BIC) &&
      this.state.BIC.length === 11
    ) {
      this.setState({ valide_BIC: true });
      $("div.BIC  fieldset").addClass("input_valid");
      $("div.BIC  fieldset").removeClass("input_invalid");
      this.valBic = true;
    } else {
      this.setState({ valide_BIC: false });
      $("div.BIC  fieldset").removeClass("input_valid");
      $("div.BIC  fieldset").addClass("input_invalid");
      this.valBic = false;
      // $("div.BIC div.MuiInputBase-formControl").css("background-color", "#fff");
    }
  };

  /*  <-------------------------------------- On Change NDLB---------------------------------------------> */
  OnChangeNDLB = (e) => {
    let val_bool = true;

    this.setState({ Nom_de_banque: e.target.value, valide_NDLB: val_bool });

    if (
      e.target.value !== "" &&
      e.target.value.length > 1 &&
      e.target.value.length < 51
    ) {
      this.setState({ NDLB: e.target.value, Error_ndlb: "" });
      $("div.NDLB  fieldset").addClass("input_valid");
      $("div.NDLB  fieldset").removeClass("input_invalid");
      this.valNBank = true;
    } else {
      $("div.NDLB  fieldset").addClass("input_invalid");
      $("div.NDLB  fieldset").removeClass("input_valid");
      this.setState({
        Error_ndlb: "Nom de la banque est invalide.",
      });
      this.valNBank = false;
    }
  };
  OnBlurNDLB = () => {
    if (!this.state.NDLB) {
      this.setState({ Error_ndlb: "Nom de la banque est invalide." });
      this.valNBank = false;
    }
  };
  /* <----------------------------------------  Function Change Val_Doc_Telecharge ---------------------------------------> */
  OnChangeVal_Doc_Telecharge = (e) => {
    this.setState({ Val_Doc_Telecharge: e.target.value, btnObtenir: true });
  };
  saveSouscription = (e) => {
    const base_url = `${process.env.REACT_APP_URL}/souscription/${this.state.id_Souscription
      }/${getToken()}`;
    this.setState({ loading: true });
    let val_APE = this.state.APE === "true" ? 1 : 0;

    let val_AFE = this.state.AFE === "true" ? 1 : 0;

    let val_isTNS = this.state.CheckClient === true ? 1 : 0;
    const data = { 
      isAPF: val_APE,
      isAFE: val_AFE,
      isTNS: val_isTNS,
      date_effet_souhaite: this.state.DDE,
      periodicite: this.state.Periodicite,
      jour_de_prelevement: this.state.jour_de_prelevement,
      nom_du_titulaire: this.state.Nom_du_titulaire,
      IBAN: this.state.IBAN,
      BIC: this.state.BIC,
      nom_de_la_banque: this.state.Nom_de_banque,
      etat: "souscription1_1",
    };

    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data)
    };
    fetch(base_url, requestOptions).then((response) => {
      if (response.status === 200) {
        return response.json();
      } else if (response.status === 404) {
        this.props.navigate("/Page_404");
      } else {
        this.props.navigate("/Page_500");
      }
    })
      .then((data) => {
        if (data.code == "-2") {
          this.setState({
            loading: false,
            modalExpire: true,
          });
        } else if (data.code == "-1") {
          this.setState({
            modalServeur: true,
            loading: false,
          })

        } else {
          this.setState({
            modalExpire: false,
          });

          let data_souscription = [
            { id: this.state.id_Proposition, Precedent: false },
          ];
          let Data_dependance = localStorage.getItem("Data_Dependance");
          Data_dependance &&
            localStorage.setItem(
              "Data_Dependance",
              JSON.stringify(data_souscription)
            );
          this.setState({ loading: false });
          this.props.navigate("/recapitulatif");
        }

      }).catch((error) => {

        this.props.navigate("/Page_500");

      });
  };
  OnPrecedent = (event) => {
    event.preventDefault();

    let Data_dependance = localStorage.getItem("Data_Dependance");
    Data_dependance &&
      localStorage.setItem(
        "Data_Dependance",
        JSON.stringify([{ id: this.state.id_Proposition, Precedent: true }])
      );

    this.props.navigate("/proposition");
    /*this.setState({
      showModal: true,
      namePage: "#/proposition/",
    });*/
  };

  OnchangeModal = () => {
    if (this.state.showModal) {
      this.setState({
        showModal: false,
      });
    }
  };


    /* <----------------------------------------------FN OnChange Date d'effet -----------------------------------------------------> */
    onchangeDate = (event) => {
      event.preventDefault();
  
      const parent = event.currentTarget.parentNode.parentNode.parentNode;
      this.setState({ DDE: event.target.value });
      let date = new Date(event.target.value);
      if (date.getDate() > 1) {
        date.setMonth(parseInt(date.getMonth() + 1))
      }
      let dateNaissance = new Date(this.state.DDN);
      let diffYear = date.getFullYear()-dateNaissance.getFullYear()
      if ((new Date(event.target.value)).getFullYear() > 999) {
        let numMonth = "";
        if ((date.getMonth()) < 10) {
          numMonth = "0"
        }
        const valDate = date.getFullYear() + "-" + numMonth + (date.getMonth() + 1) + "-01";
        this.setState({ DDE: valDate });
        
        const dateDEE = new Date(valDate);
        if((dateDEE < (new Date(this.state.dateEffetMin)))||(diffYear >74 )){
          this.setState({ errorMsgDateEffet: true });
          parent.classList.add("error-valide");
        }else{
          parent.classList.remove("error-valide");
          this.setState({ errorMsgDateEffet: false });

        }
      }else{
        this.setState({ errorMsgDateEffet: true });
        parent.classList.add("error-valide");
     
      }
  
      
      }
 
  render() {
    const {
      Arrow_Style_Periodicite,
      valide_NDT,
      ColorNDT,
      ColorIBAN,
      valide_IBAN,
      ColorBIC,
      valide_BIC,
      valide_NDLB,
      ColorNDLB,
      ShowDivBtnEng,
      valide_Periodicite,
      ColorPeriodicite,
      valide_Prelevement,
      Arrow_Style_Prelevement,
      ColorPrelevement,
    } = this.state;


   
    return (
      <>
        <div className="row r-15 ">
          <div className="row r-15  boxTopTitle">
            <div className="col-xs-12 col-md-9 "></div>
            <div className="col-xs-12 col-md-3 ">
              <Button className="btn btn_file" onClick={() => docDownload("PlaquetteProduit_Dépendance.pdf")}>
                <img src={icoFile} className="icoFile" alt={icoFile} />
                Plaquette produit
              </Button>
            </div>
          </div>

          <div className="row content_section">
            <div className="row colcenter colcenter_3 r-15">
              <div className="col-2 boxStep active active_r">
                <div className="content-box-step">
                  <div className="divNum">
                    <span>1.</span>
                  </div>
                  <span className="textBox">Simulation</span>
                </div>
              </div>

              <div className="cls_separation active"></div>

              <div className="col-2 boxStep  active active_l active_r">
                <div className="content-box-step ">
                  <div className="divNum">
                    <span>2.</span>
                  </div>
                  <span className="textBox">PROJET</span>
                </div>
              </div>

              <div className="cls_separation active"></div>

              <div className="col-2 boxStep active active_l">
                <div className="content-box-step ">
                  <div className="divNum">
                    <span>3.</span>
                  </div>
                  <span className="textBox">Souscription</span>
                </div>
              </div>
            </div>
            {/* <-------------------------------------------- Informations complémentaires -----------------------------------------> */}
            <div className="row mt_20 mb_10 r-15">
              <h2 className="textBudget">Informations complémentaires</h2>
            </div>
            <div className="row mb_10 r-15 boxCheckScrt">
              <div className="col-xs-12 col-md-8 align-center">
                <div className="labeltext">
                  <p>
                    L’assuré est-il une Personne Politiquement Exposée ?
                    <HelpRounded id="helpQ1" className="iconhelpR ms-2" />
                  </p>
                </div>
              </div>
              <div className="col-xs-12 col-md-4 colstart">
                <Form.Check
                  className="MR"
                  inline
                  label="Oui"
                  value="true"
                  name="APE"
                  type="Radio"
                  checked={this.state.APE === "true" && true}
                  onChange={(e) => this.OnChangeAPE(e)}
                />
                <Form.Check
                  inline
                  label="Non"
                  value="false"
                  name="APE"
                  type="Radio"
                  checked={this.state.APE === "false" && true}
                  onChange={(e) => this.OnChangeAPE(e)}
                />
              </div>
            </div>
            <div className="row mb_10 r-15 boxCheckScrt">
              <div className="col-xs-12 col-md-8 align-center">
                <div className="labeltext">
                  <p>
                    L’assuré est-il membre de la famille d’une Personne
                    Politiquement Exposée et/ou entretient-il des liens étroits
                    avec une Personne Politiquement Exposée ?
                    <HelpRounded id="helpQ2" className="iconhelpR ms-2" />
                  </p>
                </div>
              </div>
              <div className="col-xs-12 col-md-4 colstart">
                <Form.Check
                  className="MR"
                  inline
                  label="Oui"
                  value="true"
                  name="AFE"
                  type="Radio"
                  checked={this.state.AFE === "true" && true}
                  onChange={(e) => this.OnChangeAFE(e)}
                />
                <Form.Check
                  inline
                  label="Non"
                  value="false"
                  name="AFE"
                  type="Radio"
                  checked={this.state.AFE === "false" && true}
                  onChange={(e) => this.OnChangeAFE(e)}
                />
              </div>
            </div>
            <div className="row mt_15 mb_40 r-15">{this.state.CheckClient}
              <div className="col-xs-12 col-md-12 colstart">
                <div className="toggle r-15">
                  <input
                    type="checkbox"
                    value={this.state.CheckClient}
                    checked={this.state.CheckClient}
                    onChange={(e) =>
                      this.setState({ CheckClient: !this.state.CheckClient })
                    }
                    className="inputCheckbox"
                  />
                </div>
                <p className="textToggle2">
                  Mon client est TNS et souhaite bénéficier du dispositif
                  Madelin
                </p>
              </div>
            </div>
            {/* <-------------------------------------------- Date d’effet --------------------------------------------> */}
            <div className="row mb_10 r-15">
              <h2 className="textBudget">Date d’effet</h2>
            </div>
            <div className="row r-15 mb_40">
                <div className="col-xs-12 col-md-6">
                
                  <div className="contentGris2">
                    <div className="row r-15">
                      <div className="col-xs-12 col-md-6 divTxB">
                        Date d'effet souhaitée*
                      </div>
                      <div className="col-xs-12 col-md-6 divInput ">
                        <div className="col-xs-12 col-md-12">
                            <TextField
                              id=""
                              label=""
                              variant="outlined"
                              type="date"
                              name="date_effet_souhaite"
                              onChange={this.onchangeDate}
                              className="DateEffet"
                              value={this.state.DDE}
                              inputProps={{
                                min:this.state.dateEffetMin,
                                max:"9999-12-01"
                              }}
                            />
                        </div>
                        {this.state.errorMsgDateEffet && (
                          <span className="errorMsg errorMsgStyle1">
                            (*)La date d’effet est invalide, elle doit être au plus tôt au 1er jour du mois qui suit la date du jour. A la date d’effet du contrat, l’adhérent doit être âgé de minimum 18 ans et maximum 74 ans (inclus).
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-md-6">
                  <div className="contentGris3 mt-50">
                    <h5 className="infoH5">
                      <span>i</span> La date d’effet doit être au plus tôt au 1er jour du mois qui suit la date du jour et au 1er du mois. A la date d’effet du contrat, l’adhérent doit être âgé de minimum 18 ans et maximum 74 ans par différence de millésime (millésime = année date d'effet - année de naissance).
                    </h5>
                  </div>
                </div>
            </div>
            {/* <-------------------------------------------- Informations de prélèvement --------------------------------------------> */}
            <div className="row mb_10 r-15">
              <h2 className="textBudget">Informations de prélèvement</h2>
            </div>
            <div className="row d-top r-15 ">
              <div className="col-xs-12 col-md-3 mb_24">
                <FormControl
                  required
                  className={`inputSmall Periodicite  ${Arrow_Style_Periodicite}`}
                >
                  <InputLabel
                    style={{ color: ColorPeriodicite }}
                    id="demo-simple-select-required-label"
                  >
                    Périodicité
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-required-label"
                    id="demo-simple-select-required"
                    value={this.state.Periodicite}
                    label="Périodicité *"
                    error={!valide_Periodicite}
                    onChange={(e) => this.OnChangePER(e)}
                    onFocus={() => this.onFocusPER()}
                    onBlur={() => this.onBlurPER()}
                  >
                    <MenuItem value="Sélectionner">
                      <span className="Selectionner_Opacity">
                        -Sélectionner-
                      </span>
                    </MenuItem>
                    <MenuItem value="Mensuelle">Mensuelle</MenuItem>
                    <MenuItem value="Trimestrielle">Trimestrielle</MenuItem>
                    <MenuItem value="Semestrielle">Semestrielle</MenuItem>
                    <MenuItem value="Annuelle">Annuelle</MenuItem>
                  </Select>
                  {!valide_Periodicite && (
                    <FormHelperText className="Inalid_Label">
                      *Champ obligatoire
                    </FormHelperText>
                  )}
                </FormControl>
                {this.state.Periodicite === "Mensuelle" && (
                  <div className="row ">
                    <div className="col-xs-12 col-md-12  divIconInfo ">
                      <p className="textInfo">
                        <InfoOutlined className="iconInfo" />
                        En cas de paiement mensuel, des frais de fractionnement
                        de 1,5€ seront ajoutés à la cotisation
                      </p>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-xs-12 col-md-3 mb_24">
                <FormControl
                  required
                  className={`inputSmall Prelevement  ${Arrow_Style_Prelevement}`}
                >
                  <InputLabel
                    style={{ color: ColorPrelevement }}
                    id="demo-simple-select-required-label"
                  >
                    Jour de prélèvement
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-required-label"
                    id="demo-simple-select-required"
                    value={this.state.jour_de_prelevement}
                    label="Jour de prélèvement *"
                    error={!valide_Prelevement}
                    onChange={(e) => this.OnChangeJDP(e)}
                    onFocus={() => this.onFocusJDP()}
                    onBlur={() => this.onBlurJDP()}
                  >
                    <MenuItem value="Sélectionner">
                      <span className="Selectionner_Opacity">
                        -Sélectionner-
                      </span>
                    </MenuItem>
                    <MenuItem value="1er">1er</MenuItem>
                    <MenuItem value="5em">5</MenuItem>
                    <MenuItem value="10">10</MenuItem>
                    <MenuItem value="15">15</MenuItem>
                  </Select>
                  {!valide_Prelevement && (
                    <FormHelperText className="Inalid_Label">
                      *Champ obligatoire
                    </FormHelperText>
                  )}
                </FormControl>
              </div>
            </div>
            {/* <-------------------------------------------- Coordonnées bancaires --------------------------------------------------> */}
            <div className="row hideV0 r-15">
              <h2 className="textBudget">Coordonnées bancaires</h2>
            </div>
            <div className="row mb_40 hideV0 r-15">
              <div className="col-xs-12 col-md-9">
                <TextField
                  className="inputSmall NDT "
                  onChange={(e) => this.OnChange(e, "NDT", "Nom_du_titulaire")}
                  required
                  id="NDT"
                  type="text"
                  error={!valide_NDT}
                  onFocus={() => {
                    this.setState({ focusNDT: true, ColorNDT: "#445aa5" });
                  }}
                  onBlur={(e) => this.onBlur(e, "NDT", "Nom_du_titulaire")}
                  helperText={!valide_NDT ? "*Champ obligatoire" : null}
                  label="Nom du titulaire"
                  placeholder="Nom du titulaire"
                  value={this.state.Nom_du_titulaire}
                  InputLabelProps={{
                    shrink: true,
                    style: { color: ColorNDT },
                  }}
                />
              </div>
            </div>
            <div className="row mb_40 hideV0 r-15 ">
              <div className="col-xs-12 mb_20 col-md-4">
                <TextField
                  className="inputSmall IBAN "
                  onChange={(e) => this.OnChangeIBAN(e)}
                  required
                  id="IBAN"
                  type="text"
                  error={!valide_IBAN}
                  onFocus={() => {
                    this.setState({ focusIBAN: true, ColorIBAN: "#445aa5" });
                  }}
                  onBlur={(e) => this.onBlurIBAN(e)}
                  helperText={!valide_IBAN ? "IBAN est invalide." : null}
                  label="IBAN"
                  placeholder="IBAN"
                  InputLabelProps={{
                    shrink: true,
                    style: { color: ColorIBAN },
                  }}
                  inputProps={{ maxLength: 27 }}
                  value={this.state.IBAN}
                />
              </div>
              <div className="col-xs-12 mb_20 col-md-2">
                <TextField
                  className="inputSmall BIC "
                  onChange={(e) => this.OnChangeBIC(e)}
                  required
                  id="BIC"
                  type="text"
                  error={!valide_BIC}
                  onFocus={() => {
                    this.setState({ focusBIC: true, ColorBIC: "#445aa5" });
                  }}
                  onBlur={(e) => this.onBlurBIC(e)}
                  helperText={!valide_BIC ? "BIC est invalide." : null}
                  label="BIC"
                  placeholder="BIC"
                  InputLabelProps={{
                    shrink: true,
                    style: { color: ColorBIC },
                  }}
                  inputProps={{ maxLength: 11 }}
                  value={this.state.BIC}
                />
              </div>
              <div className="col-xs-12 mb_20 col-md-3">
                <TextField
                  className="inputSmall NDLB "
                  onChange={(e) => this.OnChangeNDLB(e)}
                  required
                  id="NDLB"
                  type="text"
                  error={!valide_NDLB}
                  onFocus={() => {
                    this.setState({ focusNDLB: true, ColorNDLB: "#445aa5" });
                  }}
                  onBlur={(e) => this.onBlur(e, "NDLB", "Nom_de_banque")}
                  helperText={!valide_NDLB ? "*Champ obligatoire" : null}
                  label="Nom de la banque"
                  placeholder="Nom de la banque"
                  InputLabelProps={{
                    shrink: true,
                    style: { color: ColorNDLB },
                  }}
                  value={this.state.Nom_de_banque}
                />
              </div>
            </div>

            {/* <-------------------------------------------------- Div  Btn ------------------------------------------------------------> */}

            {ShowDivBtnEng && (
              <div className="row mb_24 r-15 navigationSection">
                <div className="col-xs-12 col-sm-12 colend">
                  <a
                    className="btn btn-default"
                    variant="btn"
                    onClick={this.OnPrecedent}
                  >
                    Précédent
                  </a>

                  {(() => {
                    if (this.state.BTN_visualiser) {
                      if (
                        this.state.Periodicite &&
                        this.state.jour_de_prelevement &&
                        this.state.jour_de_prelevement !== "Sélectionner" &&
                        this.state.Periodicite !== "Sélectionner" &&
                        this.state.APE &&
                        this.state.AFE &&
                        this.state.DDE &&
                        !this.state.errorMsgDateEffet
                      ) {
                        return (
                          <Button
                            variant="contained"
                            onClick={this.saveSouscription}
                            className="BtnEngTeleActive"
                          >
                            Enregistrer et continuer
                          </Button>
                        );
                      } else {
                        return (
                          <Tippy content="Un ou plusieurs champs sont en erreur ou ne sont pas renseignés." maxWidth={180}>
                            <Button
                              variant="secondary"
                              className="BtnEngTele"
                              id="disabled"
                            >
                              Enregistrer et continuer
                            </Button>
                          </Tippy>
                        );
                      }
                    } else {

                      if (
                        this.valPer &&
                        this.valJour &&
                        this.state.jour_de_prelevement !== "Sélectionner" &&
                        this.state.Periodicite !== "Sélectionner" &&
                        this.state.APE &&
                        this.state.AFE
                      ) {
                        return (
                          <Button
                            variant="contained"
                            onClick={this.saveSouscription}
                            className="BtnEngTeleActive"
                          >
                            Enregistrer et continuer
                          </Button>
                        );
                      } else {
                        return (
                          <Tippy content="Un ou plusieurs champs sont en erreur ou ne sont pas renseignés." maxWidth={180}>
                            <Button
                              variant="secondary"
                              className="BtnEngTele"
                              id="disabled"
                            >
                              Enregistrer et continuer
                            </Button>
                          </Tippy>
                        );
                      }

                    }
                  })()}

                </div>
              </div>
            )}

            {/*  <------------------------------------------------------ Div Q1---------------------------------------------------------------> */}
            <div className="divShow divHidden " id="divQ1">
              <div className="row mb_15 r-15">
                <div className="col-xs-12 col-md-6 colstart">
                  <Close
                    className="iconClose "
                    id="closeQ1"
                    sx={{ fontSize: 30 }}
                  />
                </div>
                <div className="col-xs-12 col-md-6 colend">
                  <HelpRounded className="iconhelpR " sx={{ fontSize: 30 }} />
                </div>
              </div>

              <div className="row mb_24 r-15">
                <span className="titleBoxDiv">
                  L’assuré est-il une Personne Politiquement Exposée ?
                </span>
              </div>

              <div className="QdivBody row mb_24 r-15">
                <span>
                  Une Personne Politiquement Exposée (PPE) est une personne
                  physique qui exerce ou a cessé d’exercer depuis moins d’un an
                  l’une des fonctions suivantes :
                </span>

                <li className="mt-3">
                  Chef d’état, chef de gouvernement, membre d’un gouvernement
                  national ou de la Commission européenne.
                </li>
                <li className="mt-3">
                  Membre d’une assemblée parlementaire nationale, ou du
                  Parlement européen, membre de l’organe dirigeant d’un parti ou
                  groupement politique soumis aux dispositions de la loi
                  n°88-227 du 11 mars 1988 ou d’un parti ou groupement politique
                  étranger.
                </li>
                <li className="mt-3">
                  Membre d’une cour suprême, d’une cour constitutionnelle ou
                  d’une autre haute juridiction dont les décisions ne sont pas,
                  sauf circonstances exceptionnelles, susceptibles de recours.
                </li>
                <li className="mt-3">Membre d’une cour des comptes.</li>
                <li className="mt-3">
                  Dirigeant ou membre de l’organe de direction d’une banque
                  centrale.
                </li>
                <li className="mt-3">Ambassadeur ou chargé d’affaires.</li>
                <li className="mt-3">
                  Officier général ou officier supérieur assurant le
                  commandement d’une armée.
                </li>
                <li className="mt-3">
                  Membre d’un organe d’administration, de direction ou de
                  surveillance d’une entreprise publique.
                </li>
                <li className="mt-3">
                  Directeur, directeur adjoint, membres du conseil d’une
                  organisation internationale créée par un traité, ou une
                  personne qui occupe une position équivalente en son sein.
                </li>
              </div>
            </div>
            {/* <------------------------------------------------------ Div Q2----------------------------------------------------------------> */}
            <div className="divShow   divHidden " id="divQ2">
              <div className="row ">
                <div className="col-xs-12 col-md-6 colstart">
                  <Close
                    className="iconClose "
                    id="closeQ2"
                    sx={{ fontSize: 30 }}
                  />
                </div>
                <div className="col-xs-12 col-md-6 colend">
                  <HelpRounded className="iconhelpR " sx={{ fontSize: 30 }} />
                </div>
              </div>

              <div className="row mt-3">
                <span className="titleBoxDiv">
                  L’assuré est-il membre de la famille d’une Personne
                  Politiquement Exposée et/ou entretient-il des liens étroits
                  avec une Personne Politiquement Exposée ?
                </span>
              </div>

              <div className="QdivBody mt-2">
                <span>
                  Sont considérées comme personne connues pour être des membres
                  directs de la famille d’une PPE :{" "}
                </span>

                <li className="Mt_Max">Le conjoint ou le concubin notoire.</li>
                <li className="Mt_Max">
                  Le partenaire lié par un pacte civil de solidarité ou par un
                  contrat de partenariat enregistré en vertu d’une loi
                  étrangère.
                </li>
                <li className="Mt_Max">
                  Les enfants, ainsi que leur conjoint, leur partenaire lié par
                  un pacte de solidarité ou par un contrat de partenariat
                  enregistré en vertu d’une loi étrangère.
                </li>
                <li className="Mt_Max">
                  Les ascendants au premier degré.
                  <hr />
                  Sont considérées comme des personnes étroitement associées à
                  une PPE :
                </li>
                <li className="Mt_Max">
                  Les personnes physiques qui, conjointement avec la PPE, sont
                  bénéficiaires effectifs d’une personne morale, d’un placement
                  collectif, d’une fiducie ou d’un dispositif juridique
                  comparable de droit étranger.
                </li>
                <li className="Mt_Max">
                  Les personnes physiques qui sont les seuls bénéficiaires
                  effectifs d’une personne morale, d’un placement collectif,
                  d’une fiducie ou d’un dispositif comparable de droit étranger
                  connu pour avoir été établi au profit de la PPE.
                </li>
                <li className="Mt_Max">
                  Toute personne physique connue comme entretenant des liens
                  d’affaires étroits avec la PPE.
                </li>
              </div>
            </div>
          </div>
        </div>

        <ModalExpire show={this.state.modalExpire} />
        {this.state.modalServeur ? <ModalServeur handleCloseModalServeur={() => this.handleCloseModalServeur()} /> : null}

        {this.state.loading && (
          <div className="loading">
            <div className="boxLoading">
              <img src={rotation} alt={rotation} className="svgrotation" />
              <img src={repamSvg} alt={repamSvg} />
            </div>
          </div>
        )}
        {this.state.showModal && (
          <ModalQuit
            page={this.state.namePage}
            OnchangeModal={this.OnchangeModal}
          />
        )}
      </>
    );
  }
}
export default withRouter(Souscription);