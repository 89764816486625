import React, { Component } from "react";
import "./App.css";
import "./assets/fonts/stylesheet.css";
import "bootstrap/dist/css/bootstrap.min.css";
import NavBar from "./Components/navBar";
import Main from "./Components/main";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMenu: true,
    };
  }
  openMenu = () => {
    this.setState({
      isMenu: !this.state.isMenu,
    });
  };
  componentDidUpdate() {
  }
  componentDidMount() {
  }
  render() {
    const menuopen = this.state.isMenu ? "toggled" : "";
    return (
      <div className={`wrapper toggledd`}>
       
        <Main  />
      </div>
    );
  }
}

export default App;
