import React from "react";
import { Modal } from "react-bootstrap";

const ModalExpire = (props) => {
  return (
    <Modal id="ModalProposition" className="ModalExpire" show={props.show}>
      <h3>Votre session a expiré </h3>

      <Modal.Body>
        <p>
          Vous avez été inactif pendant une trop longue période,
          <br /> votre session s’est fermée automatiquement
        </p>
      </Modal.Body>
      <Modal.Footer>
        <a
          className="btn btn-primary"
          href={`${process.env.REACT_APP_URL_SESSION}`}
        >
          Restaurer la session
        </a>
      </Modal.Footer>
    </Modal>
  );
};
export default ModalExpire;
