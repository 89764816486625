import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { withRouter } from "../whitRouter";
class ModalQuit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: true,
    };
  }

  handleClose = () => {
    this.props.OnchangeModal()
    this.setState({
      show: false,
    });
  };

  redirectionproposition = (page) => {
    this.props.OnchangeModal()
    this.props.navigate("/" + page);
  };

  render() {
    const { page, showPoup } = this.props;

    return (
      <Modal
        id="ModalProposition"
        className="ModalQuit"
        show={this.state.show}
        onHide={this.handleClose}
      >
        <Modal.Body>
          <p>Êtes-vous sûr de vouloir quitter cette page ?</p>
        </Modal.Body>
        <Modal.Footer>
          {page === "sumilation" || page === "proposition" ? (
            <Button
              className="btn"
              variant="primary"
              onClick={() => this.redirectionproposition(page)}
            >
              OK
            </Button>
          ) : (
            <a
              href={page}
              className="btn btn-primary"
              onClick={this.redirectionproposition}
            >
              OK
            </a>
          )}

          <Button
            className="btn"
            variant="secondary"
            onClick={this.props.OnchangeModal}
          >
            Annuler
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default withRouter(ModalQuit);
