import React, { Component } from "react";
import ModalQuit from "./CommonComponent/modalQuit";
import "./ComponentSimulation/Simulation.css";
import $ from "jquery";
import { withRouter } from "./whitRouter";
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import { Button } from "react-bootstrap";
import rotation from "../../assets/img/rotation.svg";
import repamSvg from "../../assets/img/repam.svg";
import FormHelperText from "@mui/material/FormHelperText";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional
import "react-phone-input-2/lib/material.css";
import ModalExpire from "./CommonComponent/modalExpire";
import ModalServeur from "./CommonComponent/modalServeur";
import icoFile from "../../assets/img/icoFile.svg";
import { Data_Departement, Data_Pays_de_Naissance } from "./DataProposition";
import { getToken, docDownload } from "../../Context/ApiContext";
import DatalistInput , { useComboboxControls }from 'react-datalist-input';


const countryCodes = require("country-codes-list");

class Proposition extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalExpire: false,
      modalServeur: false,
      /*<------- State civilite ----------> */
      Civilite: "",

      /*<----------- State Nom -----------> */
      Nom: "",
      /*<-------- State nom_de_naissance ---------> */

      NDN: "",
      focusNDN: false,
      valide_NDN: true,
      ColorNDN: "#9AA0AC",
      msgError_nom_de_naissance: "",

      /*<-------------- State Prenom -------------> */
      Prenom: "",
      /*<------- State situation_familale --------> */
      situation_familale: "Sélectionner",
      valide_SF: true,
      focusSF: false,
      ColorSF: "#9AA0AC",
      Arrow_Style_SF: "Select_BK",

      /*<------- State Date_de_naissance ---------> */
      Date_De_Naissance: "",
      /*<--------- State Pays_de_naissance -------> */
      Pays_de_naissance: "France",

      /*<--------- State Ville_de_naissance -------> */

      ville_de_naissance: "",
      focusVDN: false,
      valide_VDN: true,
      ColorVDN: "#9AA0AC",
      msgError_Ville_de_naissance: "",

      /*<---------- State Departement ------------> */
      Departement: "Sélectionner",
      valide_Departement: true,
      focusDepartement: false,
      ColorDepartement: "#9AA0AC",
      Arrow_Style_Departement: "Select_BK",
      value_char: "",

      /*<---------- State  NumEtVoiet ------------> */
      Numero_et_voie: "",
      focusNEV: false,
      valide_NEV: true,
      ColorNEV: "#9AA0AC",
      msgError_Numero_et_voie: "",

      /*<------------ State Code Postal-----------> */
      CodeP: "",
      valide_CodePostal: true,
      focusCodeP: false,
      Blur_CodeP: false,
      ColorCodePosta: "#9AA0AC",
      msgError_Ville: "",

      /*<-------------- State Ville -------------> */
      data_ville: "",
      focusVille: false,
      valide_Ville: true,
      ColorVille: "#9AA0AC",
      msgError_CodeP: "",
      Arrow_Style_Ville: "Select_Ville",

      /*<-------------- State Email -------------> */

      Email: "",
      focusEmail: false,
      Valide_Email: true,
      ColorEmail: "#9AA0AC",

      /*<-------------- State Btn -------------> */
      Btn_Eng_Tele: false,
      ShowDivBtnEng: true,
      btnSuivant: false,
      btnTelecharge: false,
      DocTelecharge: false,
      validation: false,
      Val_Doc_Telecharge: "",

      /*<----------- State Table Form ---------> */
      TabForm: [],

      checkedInpt: 0,

      /*<--------- State Select Phone ---------> */
      codeCountry: "+33",
      ColorIndicatif: "#9AA0AC",
      Arrow_Style_Phone: "Select_BK",
      /*<--------- State Phone ---------> */

      /*<--------- State local simulation ---------> */
      DDE: "",
      cotisation: "",
      ref: "",
      proje: "",
      etat: "",
      sVersement: "",
      isAdherent: false,
      /*<--------- State local simulation ---------> */
      Numero: "",
      focusNumero: false,
      valide_Numero: true,
      Msg_valide_Numero: "",
      ColorNumero: "#9AA0AC",
      id_Proposition: "",
      id_Souscription: "",
      dataStorage: [],
      ID_visualiser_prop: "",
      BTN_visualiser: false,
      addData: true,
      rente: "",
      loading: true,
      showModal: "",
      namePage: "",
      listdata:[  ]
    };
  }
  /* <----------------------------------------------  component  DidMount ---------------------------------------> */

  componentDidMount() {
    /* --------------------------------- Input Disabled ------------------------------------------ */
    $("div.Disabled  fieldset").addClass("without_fieldset");
    $("div.Disabled div.MuiInputBase-formControl").css(
      "background-color",
      "#f1f1f1"
    );
    $("div.Disabled input ").css("-webkit-text-fill-color", "#000");

    if(this.state.Pays_de_naissance === "France"){
      $("div.Ville  fieldset").addClass("without_fieldset");
      $("div.Ville div.MuiInputBase-formControl").css(
        "background-color",
        "#f1f1f1"
      );
      $("div.Ville input ").css("-webkit-text-fill-color", "#000");
    }
    /* --------------------------- Data LocalStorage prop -------------------------- */

    let id = "";

    let parsed_dependance = JSON.parse(localStorage.getItem("Data_Dependance"));
    let parsed_visualiser = JSON.parse(localStorage.getItem("data_visualiser"));

    this.setState({ loading: false });
    if (parsed_visualiser) {
      id = parsed_visualiser[0].id;
    } else {
      id = parsed_dependance[0].id;
    }
    id &&
      fetch(`${process.env.REACT_APP_URL}/proposition/${id}/${getToken()}`)
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else if (response.status === 404) {
            this.props.navigate("/Page_404");
          } else {
            this.props.navigate("/Page_500");
          }
        })
        .then((data) => {
          let items = data.proposition;
          if (items.souscription["id"]) {
            this.setState({
              Civilite:
                items.simulation["civilite"] === "1" ? "Monsieur" : "Madame",
              Nom: items.client["nom"],
              NDN: items.souscription["nom_de_naissance"],
              Prenom: items.client["prenom"],
              situation_familale: items.souscription["situation_familiale"],
              Date_De_Naissance:
                items.client["date_de_naissance"].split("-")[2] +
                "-" +
                items.client["date_de_naissance"].split("-")[1] +
                "-" +
                items.client["date_de_naissance"].split("-")[0],
                DDE:
                  items.souscription["date_effet_souhaite"].split("-")[2] +
                  "-" +
                  items.souscription["date_effet_souhaite"].split("-")[1] +
                  "-" +
                  items.souscription["date_effet_souhaite"].split("-")[0],
              Pays_de_naissance: items.souscription["pays_de_naissance"],
              Departement: items.souscription["departement_de_naissance"],
              ville_de_naissance: items.souscription["ville_de_naissance"],
              Numero_et_voie: items.souscription["numero_et_voie"],
              CodeP: items.souscription["code_postal"],
              data_ville: items.souscription["ville"],
              Email: items.souscription["email"],
              codeCountry: items.souscription["indicatif"],
              Numero: items.souscription["mobile"],
              BTN_visualiser: true,
              addData: false,
              id_Proposition: items.id,
              id_Souscription: items.souscription["id"],
              etat: items.etat,
              loading: false,
            });

            $("div.SF   ,div.Pays_de_naissance ,div.Departement").addClass(
              "Select_Bl"
            );

            if(items.souscription["pays_de_naissance"] === "France"){
              $("div.Ville  fieldset").addClass("without_fieldset");
              $("div.Ville div.MuiInputBase-formControl").css(
                "background-color",
                "#f1f1f1"
              );
              $("div.Ville input ").css("-webkit-text-fill-color", "#000");
            } else {
              $("div.Ville  fieldset").removeClass("without_fieldset");
              $("div.Ville div.MuiInputBase-formControl").css(
                "background-color",
                "#fff"
              );
              $("div.Ville input ").css("-webkit-text-fill-color", "#000");
            }
        
          
          } else {
            this.setState({
              Civilite:
                items.simulation["civilite"] === "1" ? "Monsieur" : "Madame",
              Nom: items.client["nom"],
              Prenom: items.client["prenom"],
              Date_De_Naissance:
                items.client["date_de_naissance"].split("-")[2] +
                "-" +
                items.client["date_de_naissance"].split("-")[1] +
                "-" +
                items.client["date_de_naissance"].split("-")[0],
              id_Proposition: items.id,
            });
          }
     if(items.souscription["pays_de_naissance"] == "France"){
          fetch(`https://apicarto.ign.fr/api/codes-postaux/communes/${items.souscription["code_postal"]}`)
          .then((response) => {
            if (response.status === 200) {
              return response.json();
            } else if (response.status === 404) {
              //this.props.navigate("/Page_404");
            } else  {
              //this.props.navigate("/Page_500");
            }
          })
            .then((data) => {
              if (JSON.stringify(data).length === 0) {
                this.setState({
                  valide_CodePostal: false,
                  listdata:[],
                  data_ville: "",
                  msgError_CodeP: "En Eours Ee Vérification ... ",
                });
              }
    
              if (JSON.stringify(data).includes("nomCommune")) {
                
                let list = []
                if(data.length>1){
                  for(let i = 0; i<data.length; i++){
                    list.push({id: i, value:JSON.stringify(data[i].nomCommune).slice(
                      JSON.stringify(data[i].nomCommune).indexOf('"') + 1,
                      JSON.stringify(data[i].nomCommune).length - 1
                    )})
                  }
                }
                this.setState({
                  listdata: list,
                  valide_CodePostal: true,
                  msgError_CodeP: "",
                });
                $("div.CodePostal fieldset").addClass("input_valid");
                $("div.CodePostal fieldset").removeClass("input_invalid");
                $("div.Ville input").css("-webkit-text-fill-color", "#000");

                this.setState({
                  Arrow_Style_Ville: "Select_Bl",
                });
  
              }
          
            
            })
            .catch((error) => {
              this.setState({
                valide_CodePostal: false,
                listdata:[],
                data_ville: "",
                msgError_CodeP: "Code postal invalide",
              });
              $("div.CodePostal fieldset").addClass("input_invalid");
              $("div.CodePostal fieldset").removeClass("input_valid");

              this.setState({
                Arrow_Style_Ville: "Select_R",
              });
              
              //this.props.navigate("/Page_500");
              
    
            });
          }
          
        })
        .catch((error) => {
          this.props.navigate("/Page_500");
        });
  }
  componentDidUpdate() {
    /* --------------------------------- Input Disabled ------------------------------------------ */
    $("div.Disabled  fieldset").addClass("without_fieldset");
    $("div.Disabled div.MuiInputBase-formControl").css(
      "background-color",
      "#f1f1f1"
    );
    $("div.Disabled input ").css("-webkit-text-fill-color", "#000");
  }
  /* <---------------------  close modal serveur error   ------------------> */
  handleCloseModalServeur = () => {
    this.setState({
      modalServeur: false,
    });
  };
  /* <---------------------  Function  ADD Souscription   ------------------> */
  AddSouscription = (event) => {
    event.preventDefault();
    this.setState({ loading: true });

    const base_url_put = `${process.env.REACT_APP_URL}/souscription/${
      this.state.id_Souscription
    }/${getToken()}`;

    const base_url = `${process.env.REACT_APP_URL}/souscription/${
      this.state.id_Proposition
    }/${getToken()}`;
    const data = {
      situation_familiale: this.state.situation_familale,
      pays_de_naissance: this.state.Pays_de_naissance,      
      date_effet_souhaite: this.state.DDE,
      departement_de_naissance: this.state.Departement,
      ville_de_naissance: this.state.ville_de_naissance,
      nom_de_naissance: this.state.NDN,
      numero_et_voie: this.state.Numero_et_voie,
      code_postal: this.state.CodeP,
      ville: this.state.data_ville,
      email: this.state.Email,
      indicatif: this.state.codeCountry,
      mobile: this.state.Numero,
      etat: "souscription1",
    };

    if (this.state.addData) {
      /* ------------------------------- ADD PROPOSISTION-------------------------------- */
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      };
      fetch(base_url, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else if (response.status === 404) {
          this.props.navigate("/Page_404");
        } else {
          this.props.navigate("/Page_500");
        }
      })
        .then((data) => {
          if (data.code == "-2") {
            this.setState({
              loading: false,
              modalExpire: true,
            });
          } else if (data.code == "-1") {
            this.setState({
              modalServeur: true,
              loading: false,
            });
          } else {
            this.setState({
              loading: false,
              modalExpire: true,
            });
            this.setState({ id_Souscription: data.souscription.id });
            let Data_dependance = localStorage.getItem("Data_Dependance");
            Data_dependance &&
              localStorage.setItem(
                "Data_Dependance",
                JSON.stringify([
                  { id: this.state.id_Proposition, Precedent: false },
                ])
              );
            this.setState({ loading: false });
            this.props.navigate("/Souscription");
          }
        })
        .catch((error) => {
          this.props.navigate("/Page_500");
        });
    } else {
      /* ------------------------------- PUT PROPOSISTION-------------------------------- */
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      };
      fetch(base_url_put, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else if (response.status === 404) {
          this.props.navigate("/Page_404");
        } else {
          this.props.navigate("/Page_500");
        }
      })
        .then((data) => {
          if (data.code == "-2") {
            this.setState({ loading: false, modalExpire: true });
          } else if (data.code == "-1") {
            this.setState({
              modalServeur: true,
              loading: false,
            });
          } else {
            this.setState({ modalExpire: false });
            this.setState({ id_Souscription: data.souscription.id });
            let Data_dependance = localStorage.getItem("Data_Dependance");
            Data_dependance &&
              localStorage.setItem(
                "Data_Dependance",
                JSON.stringify([
                  { id: this.state.id_Proposition, Precedent: false },
                ])
              );
            this.setState({ loading: false });
            this.props.navigate("/Souscription");
          }
        })
        .catch((error) => {
          console.log("error " + error);
        });
    }
  };

  /* <---------------------  Function Change Nom de naissance  ------------------> */
  OnChange_NDN = (e) => {
    const validRegex = /^([^0-9]*)$/;
    this.setState({ NDN: e.target.value });
    if (e.target.value === "") {
      $("div.NDN  fieldset").addClass("input_valid");
      $("div.NDN   fieldset").removeClass("input_invalid");
      this.setState({ msgError_nom_de_naissance: "*Champ obligatoire" });
      this.setState({ valide_NDN: false });
    } else if (
      e.target.value !== "" &&
      e.target.value.match(validRegex) &&
      e.target.value.replace(/\s+/, "").length > 1 &&
      e.target.value.replace(/\s+/, "").length < 51
    ) {
      this.setState({ valide_NDN: true });
      $("div.NDN fieldset").addClass("input_valid");
      $("div.NDN fieldset").removeClass("input_invalid");
      this.setState({ msgError_nom_de_naissance: "" });
    } else {
      this.setState({ valide_NDN: false });
      $("div.NDN fieldset").removeClass("input_valid");
      $("div.NDN fieldset").addClass("input_invalid");

      this.setState({
        msgError_nom_de_naissance: "*Ce champ n'accepte pas les chiffres.",
      });
    }
  };

  onBlur_NDN = (e) => {
    const validRegex = /^([^0-9]*)$/;

    if (e.target.value === "") {
      $("div.NDN   fieldset").addClass("input_invalid");
      this.setState({ valide_NDN: false, ColorNDN: "#ED1C24" });
      this.setState({ msgError_nom_de_naissance: "*Champ obligatoire" });
    } else if (
      e.target.value !== "" &&
      e.target.value.match(validRegex) &&
      e.target.value.replace(/\s+/, "").length > 1 &&
      e.target.value.replace(/\s+/, "").length < 51
    ) {
      this.setState({
        valide_NDN: true,
        ColorNDN: "#445aa5",
        msgError_nom_de_naissance: "",
      });
      $("div.NDN   fieldset").removeClass("input_invalid");
    } else {
      $("div.NDN   fieldset").addClass("input_invalid");
      this.setState({
        valide_NDN: false,
        ColorNDN: "#ED1C24",
        msgError_nom_de_naissance: "*Ce champ n'accepte pas les chiffres.",
      });
    }
  };

  /* <------------------------------------  Function onChange situation_familale --------------------------------------> */

  OnChangeSF = (e) => {
    this.setState({ situation_familale: e.target.value });

    if (e.target.value !== "Sélectionner") {
      this.setState({ valide_SF: true, Arrow_Style_SF: "Select_Bl" });
      $("div.SF  fieldset").addClass("input_valid");
      !this.state.TabForm.includes("situation_familale") &&
        this.state.TabForm.push("situation_familale");
      if (this.state.TabForm.length === 8) {
        this.setState({ Btn_Eng_Tele: true });
      }
    } else {
      this.setState({ valide_SF: false, Arrow_Style_SF: "Select_R" });
      $("div.SF  fieldset").addClass("input_invalid");
      this.state.TabForm.includes("situation_familale") &&
        this.setState({
          TabForm: this.state.TabForm.filter(
            (item) => item !== "situation_familale"
          ),
        });
      this.setState({
        Btn_Eng_Tele: false,
        ShowDivBtnEng: true,
        DocTelecharge: false,
        btnSuivant: false,
      });
    }
  };

  onFocusSF = () => {
    if (this.state.situation_familale !== "Sélectionner") {
      this.setState({
        focusSF: true,
        ColorSF: "#445aa5",
        Arrow_Style_SF: "Select_Bl",
      });
    } else {
      this.setState({
        focusSF: false,
        ColorSF: "#ed1c24",
        Arrow_Style_SF: "Select_R",
      });
      $("div.SF.Select_R  .MuiSelect-select").css("opacity", "1");
    }
  };
  onBlurSF = () => {
    if (this.state.situation_familale !== "Sélectionner") {
      $("div.SF  fieldset").removeClass("input_invalid");
      $("div.SF  fieldset").addClass("input_valid");
      this.setState({ valide_SF: true, ColorSF: "#445aa5" });
    } else {
      $("div.SF  fieldset").addClass("input_invalid");
      $("div.SF  fieldset").removeClass("input_valid");
      this.setState({ valide_SF: false, ColorSF: "#ED1C24" });
    }
  };

  /* <------------------------------------  Function onChange Pays_de_naissance --------------------------------------> */
  Pays_de_naissance = (e) => {
    this.setState({ Pays_de_naissance: e.target.value });
  

    if (e.target.value === "France") {
      $("div.Ville  fieldset").addClass("without_fieldset");
      $("div.Ville div.MuiInputBase-formControl").css(
        "background-color",
        "#f1f1f1"
      );
      $("div.Ville input ").css("-webkit-text-fill-color", "#000");


      $("div.Departement  fieldset,div.CodePostal  fieldset").removeClass("input_valid");

      this.setState({
        data_ville: "",
        focusVille: false,
        valide_Ville: true,
        ColorVille: "#9AA0AC",
        msgError_Ville: "",
  
        CodeP: "",
        valide_CodePostal: true,
        Blur_CodeP: false,
        ColorCodePosta: "#9AA0AC",
        msgError_CodeP: "",
  
        Departement: "Sélectionner",
        valide_Departement: true,
        focusDepartement: false,
        ColorDepartement: "#9AA0AC",
        Arrow_Style_Departement: "Select_BK",
      });
  

      
      
    } else {
      $("div.Ville  fieldset").removeClass("without_fieldset");
      $("div.Ville div.MuiInputBase-formControl").css(
        "background-color",
        "#fff"
      );
      $("div.Ville input ").css("-webkit-text-fill-color", "#000");

      this.setState({
        data_ville: "",
        focusVille: false,
        valide_Ville: true,
        ColorVille: "#9AA0AC",
        msgError_Ville: ""
      })
    }
  };
  /* <------------------------------------  Function onChange Departement --------------------------------------> */
  OnChangeDepartement = (e) => {
    this.setState({ Departement: e.target.value, value_char: "" });

    if (e.target.value !== "Sélectionner") {
      this.setState({
        valide_Departement: true,
        Arrow_Style_Departement: "Select_Bl",
      });
      $("div.Departement  fieldset").addClass("input_valid");
      !this.state.TabForm.includes("Departement") &&
        this.state.TabForm.push("Departement");
      if (this.state.TabForm.length === 8) {
        this.setState({ Btn_Eng_Tele: true });
      }
    } else {
      this.setState({
        valide_Departement: false,
        Arrow_Style_Departement: "Select_R",
      });
      $("div.Departement  fieldset").addClass("input_invalid");
      this.state.TabForm.includes("Departement") &&
        this.setState({
          TabForm: this.state.TabForm.filter((item) => item !== "Departement"),
        });
      this.setState({
        Btn_Eng_Tele: false,
        ShowDivBtnEng: true,
        DocTelecharge: false,
        btnSuivant: false,
      });
    }
  };
  onFocusDepartement = () => {
    if (this.state.Departement !== "Sélectionner") {
      this.setState({
        focusDepartement: true,
        ColorDepartement: "#445aa5",
        Arrow_Style_Departement: "Select_Bl",
      });
    } else {
      this.setState({
        focusDepartement: false,
        ColorDepartement: "#ed1c24",
        Arrow_Style_Departement: "Select_R",
      });
      $("div.Departement.Select_R  .MuiSelect-select").css("opacity", "1");
    }
  };
  onBlurDepartement = () => {
    if (this.state.Departement !== "Sélectionner") {
      $("div.Departement  fieldset").removeClass("input_invalid");
      $("div.Departement  fieldset").addClass("input_valid");
      this.setState({ valide_Departement: true, ColorDepartement: "#445aa5" });
    } else {
      $("div.Departement  fieldset").addClass("input_invalid");
      $("div.Departement  fieldset").removeClass("input_valid");
      this.setState({ valide_Departement: false, ColorDepartement: "#ed1c24" });
    }
  };
  /* <--------------------------------------- Function onChange Ville de naissance -------------------------------------> */

  OnChange_VDN = (e) => {
    const validRegex = /^([^0-9]*)$/;
    this.setState({ ville_de_naissance: e.target.value });
    if (e.target.value === "") {
      $("div.VDN  fieldset").addClass("input_valid");
      $("div.VDN  fieldset").removeClass("input_invalid");
      this.setState({ msgError_Ville_de_naissance: "*Champ obligatoire" });
      this.setState({ valide_VDN: false });
    } else if (
      e.target.value !== "" &&
      e.target.value.match(validRegex) &&
      e.target.value.replace(/\s+/, "").length > 1 &&
      e.target.value.replace(/\s+/, "").length < 51
    ) {
      this.setState({ valide_VDN: true });
      $("div.VDN  fieldset").addClass("input_valid");
      $("div.VDN  fieldset").removeClass("input_invalid");
      this.setState({ msgError_Ville_de_naissance: "" });
    } else {
      this.setState({ valide_VDN: false });
      $("div.VDN   fieldset").removeClass("input_valid");
      $("div.VDN   fieldset").addClass("input_invalid");

      this.setState({
        msgError_Ville_de_naissance: "*Ce champ n'accepte pas les chiffres.",
      });
    }
  };

  onBlur_VDN = (e) => {
    const validRegex = /^([^0-9]*)$/;

    if (e.target.value === "") {
      $("div.VDN  fieldset").addClass("input_invalid");
      this.setState({ valide_VDN: false, ColorVDN: "#ED1C24" });
      this.setState({ msgError_Ville_de_naissance: "*Champ obligatoire" });
    } else if (
      e.target.value !== "" &&
      e.target.value.match(validRegex) &&
      e.target.value.replace(/\s+/, "").length > 1 &&
      e.target.value.replace(/\s+/, "").length < 51
    ) {
      this.setState({
        valide_VDN: true,
        ColorVDN: "#445aa5",
        msgError_Ville_de_naissance: "",
      });
      $("div.VDN  fieldset").removeClass("input_invalid");
    } else {
      $("div.VDN  fieldset").addClass("input_invalid");
      this.setState({
        valide_VDN: false,
        ColorVDN: "#ED1C24",
        msgError_Ville_de_naissance: "*Ce champ n'accepte pas les chiffres.",
      });
    }
  };
  /* <------------------------------------  Function onChange Numero_et_voie --------------------------------------> */

  OnChangeNEV = (e) => {
    this.setState({ Numero_et_voie: e.target.value });
    if (e.target.value === "") {
      $("div.NEV  fieldset").addClass("input_valid");
      $("div.NEV  fieldset").removeClass("input_invalid");
      this.setState({ msgError_Numero_et_voie: "*Champ obligatoire" });
      this.setState({ valide_NEV: false });
    } else if (
      e.target.value !== "" &&
      e.target.value.replace(/\s+/, "").length > 2 &&
      e.target.value.replace(/\s+/, "").length < 51
    ) {
      this.setState({ valide_NEV: true });
      $("div.NEV  fieldset").addClass("input_valid");
      $("div.NEV  fieldset").removeClass("input_invalid");
      this.setState({ msgError_Numero_et_voie: "" });
    } else {
      this.setState({ valide_NEV: false });
      $("div.NEV   fieldset").removeClass("input_valid");
      $("div.NEV   fieldset").addClass("input_invalid");

      this.setState({
        msgError_Numero_et_voie:
          "*ce champ doit contenir aux moins 3 caractères ",
      });
    }
  };

  onBlurNEV = (e) => {
    if (e.target.value === "") {
      $("div.NEV  fieldset").addClass("input_invalid");
      this.setState({ valide_NEV: false, ColorNEV: "#ED1C24" });
      this.setState({ msgError_Numero_et_voie: "*Champ obligatoire" });
    } else if (
      this.state.Numero_et_voie !== "" &&
      e.target.value.length > 2 &&
      e.target.value.length < 51
    ) {
      this.setState({
        valide_NEV: true,
        ColorNEV: "#445aa5",
        msgError_Numero_et_voie: "",
      });
      $("div.NEV  fieldset").removeClass("input_invalid");
    } else {
      $("div.NEV  fieldset").addClass("input_invalid");
      this.setState({
        valide_NEV: false,
        ColorNEV: "#ED1C24",
        msgError_Numero_et_voie:
          "*ce champ doit contenir aux moins 3 caractères ",
      });
    }
  };

  /* <------------------------------------  Function validation Code Postal --------------------------------------> */

  ValidationCodePostal = (e, val) => {
    const reg = new RegExp("^[0-9]{5}$");
    this.setState({ CodeP: e.target.value });
    if (e.target.value.length !== 5) {
      this.setState({
        valide_CodePostal: false,
        data_ville: "",
        msgError_CodeP:
          "Le code postal de l’adresse doit être composé de 5 caractères numériques.",
      });
      $("div.CodePostal   fieldset").addClass("input_invalid");
      $("div.CodePostal   fieldset").removeClass("input_valid");
    } else if (e.target.value.length === 5 && val === "FR") {
      fetch(`https://apicarto.ign.fr/api/codes-postaux/communes/${e.target.value}`)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else if (response.status === 404) {
          //this.props.navigate("/Page_404");
        } else  {
          //this.props.navigate("/Page_500");
        }
      })
        .then((data) => {
          if (JSON.stringify(data).length === 0) {
            this.setState({
              valide_CodePostal: false,
              listdata:[],
              data_ville: "",
              msgError_CodeP: "En Eours Ee Vérification ... ",
            });
          }

          if (JSON.stringify(data).includes("nomCommune")) {
            
            let list = []
            if(data.length>1){
              for(let i = 0; i<data.length; i++){
                list.push({id: i, value:JSON.stringify(data[i].nomCommune).slice(
                  JSON.stringify(data[i].nomCommune).indexOf('"') + 1,
                  JSON.stringify(data[i].nomCommune).length - 1
                )})
              }
              
              this.setState({
                listdata: list,
                valide_CodePostal: true,
                data_ville: "Sélectionner",
                msgError_CodeP: "",
                valide_Ville: false,
                msgError_Ville: "*Champ obligatoire"
              });
              
            $("div.CodePostal fieldset").addClass("input_valid");
            $("div.CodePostal fieldset").removeClass("input_invalid");
            }else{
              this.setState({
                listdata: list,
                valide_CodePostal: true,
                data_ville: JSON.stringify(data[0].nomCommune).slice(
                  JSON.stringify(data[0].nomCommune).indexOf('"') + 1,
                  JSON.stringify(data[0].nomCommune).length - 1
                ),
                msgError_CodeP: "",
              });
            $("div.CodePostal fieldset").addClass("input_valid");
            $("div.CodePostal fieldset").removeClass("input_invalid");
            $("div.Ville fieldset").css("-webkit-text-fill-color", "#000");
            $("div.Ville input").css("-webkit-text-fill-color", "#000");
            }

            this.setState({
              Arrow_Style_Ville: "Select_Bl",
            });

          }
        })
        .catch((error) => {
          this.setState({
            valide_CodePostal: false,
            listdata:[],
            data_ville: "",
            msgError_CodeP: "Code postal invalide",
          });
          $("div.CodePostal fieldset").addClass("input_invalid");
          $("div.CodePostal fieldset").removeClass("input_valid");
          
          this.setState({
            Arrow_Style_Ville: "Select_R",
          });

          
          //this.props.navigate("/Page_500");
          

        });
    } else if (e.target.value.length === 5 && val === "NOT_FR") {
      if (reg.test(e.target.value)) {
        this.setState({ valide_CodePostal: true, msgError_CodeP: "" });
        this.setState({ valide_CodePostal: true });
        $("div.CodePostal   fieldset").addClass("input_valid");
        $("div.CodePostal   fieldset").removeClass("input_invalid");
      } else {
        $("div.CodePostal   fieldset").addClass("input_invalid");
        $("div.CodePostal   fieldset").removeClass("input_valid");
        this.setState({
          valide_CodePostal: false,
          listdata:[],
          msgError_CodeP: "Code postal invalide",
        });
      }
    }
  };

  onBlurCodePostal = (e, val) => {
    const reg = new RegExp("^[0-9]{5}$");

    if (e.target.value.length !== 5) {
      $("div.CodePostal  fieldset").addClass("input_invalid");
      this.setState({
        valide_CodePostal: false,
        listdata:[],
        ColorCodePosta: "#ED1C24",
        msgError_CodeP:
          "Le code postal de l’adresse doit être composé de 5 caractères numériques.",
      });
    } else if (e.target.value.length === 5 && val === "FR") {
      fetch(`https://apicarto.ign.fr/api/codes-postaux/communes/${e.target.value}`) .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else if (response.status === 404) {
          //this.props.navigate("/Page_404");
        } else  {
          //this.props.navigate("/Page_500");
        }
      })
        .then((data) => {
          if (JSON.stringify(data).includes("nomCommune")) {
              
             let list = []
            if(data.length>1){
              for(let i = 0; i<data.length; i++){
                list.push({id: i, value:JSON.stringify(data[i].nomCommune).slice(
                  JSON.stringify(data[i].nomCommune).indexOf('"') + 1,
                  JSON.stringify(data[i].nomCommune).length - 1
                )})
              }
            }
            this.setState({
              listdata: list,
              valide_CodePostal: true,
              ColorCodePosta: "#445aa5",
              msgError_CodeP: "",
            });
            $("div.CodePostal fieldset").removeClass("input_invalid");
            
            $("div.Ville fieldset").css("-webkit-text-fill-color", "#000");
            $("div.Ville input").css("-webkit-text-fill-color", "#000");
          }
        })
        .catch((error) => {
          $("div.CodePostal fieldset").addClass("input_invalid");
          this.setState({
            valide_CodePostal: false,
            listdata:[],
            ColorCodePosta: "#ED1C24",
            msgError_CodeP: "Code postal invalide",
          });
          //this.props.navigate("/Page_500");
        });
    } else if (e.target.value.length === 5 && val === "NOT_FR") {
      if (reg.test(e.target.value)) {
        this.setState({
          valide_CodePostal: true,
          ColorCodePosta: "#445aa5",
          msgError_CodeP: "",
        });
        $("div.CodePostal  fieldset").removeClass("input_invalid");
      } else {
        this.setState({
          valide_CodePostal: false,
          listdata:[],
          ColorCodePosta: "#ED1C24",
          msgError_CodeP: "Code postal invalide",
        });
        $("div.CodePostal  fieldset").addClass("input_invalid");
      }
    }
  };

  /* <------------------------------------  Function onChange Ville --------------------------------------> */

  OnChangeVille = (e) => {
    this.setState({ data_ville: e.target.value });
    if (e.target.value === "") {
      $("div.Ville fieldset").addClass("input_valid");
      $("div.Ville fieldset").removeClass("input_invalid");
      $("div.Ville input").css("-webkit-text-fill-color", "#000");
      this.setState({ msgError_Ville: "*Champ obligatoire" });
      this.setState({ valide_Ville: false });
    } else if (
      e.target.value !== "" &&
      e.target.value.replace(/\s+/, "").length > 1 &&
      e.target.value.replace(/\s+/, "").length < 51
    ) {
      this.setState({ valide_Ville: true,msgError_Ville: "" });
      $("div.Ville fieldset").addClass("input_valid");
      $("div.Ville fieldset").removeClass("input_invalid");
      $("div.Ville input ").css("-webkit-text-fill-color", "#000");
    } else {
      this.setState({ valide_Ville: false });
      $("div.Ville fieldset").removeClass("input_valid");
      $("div.Ville fieldset").addClass("input_invalid");
      $("div.Ville input ").css("-webkit-text-fill-color", "#ED1C24");
      this.setState({
        msgError_Ville: "*Ce champ doit contenir au moins 2 caractères",
      });
    }
  };

  OnSelectVilleDataList = (e) => {
    this.setState({ data_ville: e.target.value });
    $("div.Ville  input").addClass("input_valid");
    $("div.Ville  input").removeClass("input_invalid");
    $("div.Ville input").css("-webkit-text-fill-color", "#000");
    this.setState({ msgError_Ville: "" });
    this.setState({ valide_Ville: true });
  }
  OnChangeVilleDataList = (e) => {
    this.setState({ data_ville: e.target.value });
    
    let tab = []
    for(let i=0; i<this.state.listdata.length; i++){
      tab.push(this.state.listdata[i].value)
    }
    if (tab.includes(e.target.value)
    ) {
      this.setState({ valide_Ville: true,msgError_Ville: "" });
      $("div.Ville input").addClass("input_valid");
      $("div.Ville input").removeClass("input_invalid");
      $("div.Ville input").css("-webkit-text-fill-color", "#000");
      this.setState({ msgError_Ville: "", valide_Ville: true });
    } else {
      this.setState({
        msgError_Ville: "*Ce champ doit contenir une ville", valide_Ville: false });
      $("div.Ville input").removeClass("input_valid");
      $("div.Ville input").addClass("input_invalid");
      $("div.Ville input").css("-webkit-text-fill-color", "#ED1C24");
     
    }


    
    if (e.target.value !== "Sélectionner") {
      this.setState({
        valide_Ville: true,
        Arrow_Style_Ville: "Select_Bl",
      });
      $("div.ville  fieldset").addClass("input_valid");
      !this.state.TabForm.includes("ville") &&
        this.state.TabForm.push("ville");
      if (this.state.TabForm.length === 8) {
        this.setState({ Btn_Eng_Tele: true });
      }
    } else {
      this.setState({
        valide_Ville: false,
        Arrow_Style_Ville: "Select_R",
      });
      $("div.ville  fieldset").addClass("input_invalid");
      this.state.TabForm.includes("ville") &&
        this.setState({
          TabForm: this.state.TabForm.filter((item) => item !== "ville"),
        });
      this.setState({
        Btn_Eng_Tele: false,
        ShowDivBtnEng: true,
        DocTelecharge: false,
        btnSuivant: false,
      });
    }
  }

  onFocusVilleDataListt = () => {
    if (this.state.ville !== "Sélectionner") {
      this.setState({
        focusVille: true,
        ColorVille: "#445aa5",
        Arrow_Style_Ville: "Select_Bl",
      });
    } else {
      alert("ffff")
      this.setState({
        focusVille: false,
        ColorVille: "#ed1c24",
        Arrow_Style_Ville: "Select_R",
      });
      $("div.ville.Select_R .MuiSelect-select").css("opacity", "1");
    }
  };
  onBlurVilleSelect = (e) => {
    if (e.target.value !== "Sélectionner") {
      $("div.ville  fieldset").removeClass("input_invalid");
      $("div.ville  fieldset").addClass("input_valid");
      this.setState({ valide_Ville: true, ColorVille: "#445aa5" });
    } else {
      $("div.ville  fieldset").addClass("input_invalid");
      $("div.ville  fieldset").removeClass("input_valid");
      this.setState({ valide_Ville: false, ColorVille: "#ed1c24" });
    }
  };

  onBlurVille = (e) => {
    if (e.target.value === "") {
      $("div.Ville  fieldset").addClass("input_invalid");
      this.setState({ valide_Ville: false, ColorVille: "#ED1C24" });
      this.setState({ msgError_Ville: "*Champ obligatoire" });
    } else if (
      e.target.value !== "" &&
      e.target.value.length > 1 &&
      e.target.value.length < 51
    ) {
      this.setState({
        valide_Ville: true,
        ColorVille: "#445aa5",
        msgError_Ville: "",
      });
      $("div.Ville  fieldset").removeClass("input_invalid");
    } else {
      $("div.Ville  fieldset").addClass("input_invalid");
      this.setState({
        valide_Ville: false,
        ColorVille: "#ED1C24",
        msgError_Ville: "*Ce champ doit contenir au moins 2 caractères",
      });
    }
  };

  /* <----------------------------------------  Function validation Email ----------------------------------------> */
  ValidationEmail = (e) => {
    this.setState({ Email: e.target.value });
    const validRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (e.target.value !== "" && e.target.value.match(validRegex)) {
      this.setState({ Valide_Email: true });
      $("div.email  div.MuiInputBase-formControl fieldset").addClass(
        "input_valid"
      );
      $("div.email  div.MuiInputBase-formControl fieldset").removeClass(
        "input_invalid"
      );

      !this.state.TabForm.includes("Email") && this.state.TabForm.push("Email");
      if (this.state.TabForm.length === 8) {
        this.setState({ Btn_Eng_Tele: true });
      }
    } else {
      this.setState({ Valide_Email: false });
      $("div.email  div.MuiInputBase-formControl fieldset").addClass(
        "input_invalid"
      );
      $("div.email  div.MuiInputBase-formControl fieldset").removeClass(
        "input_valid"
      );

      this.state.TabForm.includes("Email") &&
        this.setState({
          TabForm: this.state.TabForm.filter((item) => item !== "Email"),
        });
      this.setState({
        Btn_Eng_Tele: false,
        ShowDivBtnEng: true,
        DocTelecharge: false,
        btnSuivant: false,
      });
    }
  };
  onBlurEmail = (e) => {
    const validRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (this.state.Email !== "" && e.target.value.match(validRegex)) {
      this.setState({ Valide_Email: true, ColorEmail: "#445aa5" });
      $("div.email  fieldset").removeClass("input_invalid");
    } else {
      $("div.email  fieldset").addClass("input_invalid");
      this.setState({ Valide_Email: false, ColorEmail: "#ED1C24" });
    }
  };

  /* <----------------------------------------  Function Change Phone Fixe ---------------------------------------> */

  OnChangeNumero = (e) => {
    this.setState({ Numero: e.target.value });
    if (
      (e.target.value.length === 10 &&
        /^[0][1-7][0-9]*$/i.test(e.target.value)) ||
      (e.target.value.length === 10 && /^[0][9][0-9]*$/i.test(e.target.value))
    ) {
      this.setState({ valide_Numero: true, Msg_valide_Numero: "" });
      $("div.Numero  fieldset").addClass("input_valid");
      $("div.Select_fixe  fieldset").addClass("input_valid");
      $("div.Numero  fieldset").removeClass("input_invalid");
      !this.state.TabForm.includes("Numero") &&
        this.state.TabForm.push("Numero");
      if (this.state.TabForm.length === 8) {
        this.setState({ Btn_Eng_Tele: true });
      }
    } else {
      if (e.target.value.length !== 10) {
        this.setState({
          valide_Numero: false,
          Msg_valide_Numero:
            "Le numéro de téléphone doit comporter 10 chiffres",
        });
      } else {
        this.setState({
          valide_Numero: false,
          Msg_valide_Numero: "Numéro téléphone est invalide",
        });
      }

      $("div.Numero  fieldset").removeClass("input_valid");
      $("div.Select_fixe  fieldset").removeClass("input_valid");
      $("div.Numero  fieldset").addClass("input_invalid");
      this.state.TabForm.includes("Numero") &&
        this.setState({
          TabForm: this.state.TabForm.filter((item) => item !== "Numero"),
        });
      this.setState({
        Btn_Eng_Tele: false,
        ShowDivBtnEng: true,
        DocTelecharge: false,
        btnSuivant: false,
      });
    }
  };

  onBlurNumero = () => {
    if (this.state.Numero.length === 0) {
    } else {
      if (
        (this.state.Numero.length === 10 &&
          /^[0][1-7][0-9]*$/i.test(this.state.Numero)) ||
        (this.state.Numero.length === 10 &&
          /^[0][9][0-9]*$/i.test(this.state.Numero.value))
      ) {
        this.setState({
          valide_Numero: true,
          ColorNumero: "#445aa5",
          Arrow_Style_Phone: "Select_Bl",
          ColorIndicatif: "#445aa5",
        });
      } else {
        this.setState({
          valide_Numero: false,
          ColorNumero: "#ED1C24",
          Arrow_Style_Phone: "Select_BK",
          ColorIndicatif: "#9AA0AC",
        });
      }
    }
  };

  onChange_Select_Fixe = (e) => {
    this.setState({ codeCountry: e.target.value });
    if (this.state.Numero.length === 0) {
    } else {
      if (
        this.state.Numero !== "" &&
        this.state.Numero.length === 10 &&
        /^[0][1-7][0-9]*$/i.test(this.state.Numero)
      ) {
        $("div.Numero  fieldset").addClass("input_valid");
        $("div.Select_fixe  fieldset").addClass("input_valid");
        $("div.Numero  fieldset").removeClass("input_invalid");
        this.setState({
          valide_Numero: true,
          ColorNumero: "#445aa5",
          Arrow_Style_Phone: "Select_Bl",
          ColorIndicatif: "#445aa5",
        });
        !this.state.TabForm.includes("Numero") &&
          this.state.TabForm.push("Numero");
        if (this.state.TabForm.length === 8) {
          this.setState({ Btn_Eng_Tele: true });
        }
      } else {
        $("div.Numero  fieldset").removeClass("input_valid");
        $("div.Select_fixe  fieldset").removeClass("input_valid");
        $("div.Numero  fieldset").addClass("input_invalid");
        this.setState({
          valide_Numero: false,
          ColorNumero: "#ED1C24",
          Arrow_Style_Phone: "Select_BK",
          ColorIndicatif: "#9AA0AC",
        });
        this.state.TabForm.includes("Numero") &&
          this.setState({
            TabForm: this.state.TabForm.filter((item) => item !== "Numero"),
          });
      }
    }
  };

  /* <----------------------------------------  Function Change Val_Doc_Telecharge ---------------------------------------> */
  OnChangeVal_Doc_Telecharge = (e) => {
    this.setState({ Val_Doc_Telecharge: e.target.value, btnSuivant: true });
  };

  valDate1 = (event) => {
    event.preventDefault();
    const parent = event.currentTarget.parentNode.parentNode.parentNode;
    if (event.target.value) {
      parent.classList.add("focus-input");
    } else {
      parent.classList.remove("focus-input");
    }
    this.setState({ Date_de_naissance: event.target.value });
  };

  /*change checked */
  changeChecked = (event) => {
    var va = this.state.checkedInpt;

    if (event.target.checked) {
      va = va + 1;
    } else {
      va = va - 1;
    }
    if (va > 0) {
      this.setState({
        btnTelecharge: true,
      });
    } else {
      this.setState({
        btnTelecharge: false,
      });
    }

    this.setState({
      checkedInpt: va,
    });
  };
  OnPrecedent = (event) => {
    event.preventDefault();

    let Data_dependance = localStorage.getItem("Data_Dependance");
    Data_dependance &&
      localStorage.setItem(
        "Data_Dependance",
        JSON.stringify([{ id: this.state.id_Proposition, Precedent: true }])
      );
      this.props.navigate("/simulation");
    /*this.setState({
      showModal: true,
      namePage: "#/simulation/",
    });*/
  };

  OnchangeModal = () => {
    if (this.state.showModal) {
      this.setState({
        showModal: false,
      });
    }
  };

  render() {
    const {
      Civilite,
      Nom,
      valide_CodePostal,
      Prenom,
      situation_familale,
      
      Date_De_Naissance,
      Pays_de_naissance,
      DocTelecharge,
      Valide_Email,
      ColorEmail,
      valide_VDN,
      valide_NDN,
      valide_SF,
      ColorSF,
      Arrow_Style_SF,
      Departement,
      valide_Departement,
      ColorDepartement,
      Arrow_Style_Departement,
      valide_NEV,
      valide_Ville,
      codeCountry,
      ColorIndicatif,
      valide_Numero,
      Msg_valide_Numero,
      ColorNumero,
      Arrow_Style_Phone,
      data_ville,
      msgError_CodeP,
      BTN_visualiser,
      Arrow_Style_Ville,
      ColorVille
    } = this.state;

    return (
      <>
        <div className="row r-15">
          <div className="row r-15  boxTopTitle">
            <div className="col-xs-12 col-md-12 ">
              <Button className="btn btn_file" onClick={()=>docDownload("PlaquetteProduit_Dépendance.pdf")}>
                <img src={icoFile} className="icoFile" alt={icoFile} />
                Plaquette produit
              </Button>
            </div>
          </div>

          <div className="row content_section content_section_proposition">
            <div className="row colcenter colcenter_3 r-15">
              {!DocTelecharge && (
                <div className="col-2 boxStep active active_r">
                  <div className="content-box-step">
                    <div className="divNum">
                      <span>1.</span>
                    </div>
                    <span className="textBox">Simulation</span>
                  </div>
                </div>
              )}
              {DocTelecharge && (
                <div className="col-2 boxStep active ">
                  <div className="content-box-step">
                    <div className="divNum">
                      <span>1.</span>
                    </div>
                    <span className="textBox">Simulation</span>
                  </div>
                </div>
              )}

              {!DocTelecharge && <div className="cls_separation active"></div>}
              {DocTelecharge && <div className="cls_separation"></div>}

              {!DocTelecharge && (
                <div className="col-2 boxStep active active_l active_r">
                  <div className="content-box-step ">
                    <div className="divNum">
                      <span>2.</span>
                    </div>
                    <span className="textBox">Projet</span>
                  </div>
                </div>
              )}
              {DocTelecharge && (
                <div className="col-2 boxStep">
                  <div className="content-box-step ">
                    <div className="divNum">
                      <span>2.</span>
                    </div>
                    <span className="textBox">Projet</span>
                  </div>
                </div>
              )}

              {!DocTelecharge && <div className="cls_separation active"></div>}
              {DocTelecharge && <div className="cls_separation"></div>}

              {!DocTelecharge && (
                <div className="col-2 boxStep active active_l">
                  <div className="content-box-step ">
                    <div className="divNum">
                      <span>3.</span>
                    </div>
                    <span className="textBox">Souscription</span>
                  </div>
                </div>
              )}
              {DocTelecharge && (
                <div className="col-2 boxStep  ">
                  <div className="content-box-step ">
                    <div className="divNum">
                      <span>3.</span>
                    </div>
                    <span className="textBox">Souscription</span>
                  </div>
                </div>
              )}
            </div>

            <div className="row r-15 mbt-15">
              <div className="col-12">
                <h2 className="textBudget">Adhérent</h2>
              </div>
            </div>

            <div className="row r-15 mb-40">
              {/*   <--------------------- Div civilite----------------------------> */}
              <div className="col-xs-12 col-md-3">
                <TextField
                  className="inputSmall  Disabled "
                  required
                  disabled={true}
                  value={Civilite}
                  id="Civilite"
                  type="text"
                  label="Civilité"
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              {/*   <--------------------- Div Nom----------------------------> */}
              <div className="col-xs-12 col-md-3">
                <TextField
                  className="inputSmall  Disabled "
                  required
                  disabled={true}
                  value={Nom}
                  id="Nom"
                  type="text"
                  label="Nom"
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              {/*   <--------------------- Div Nom de naissance----------------------------> */}
              <div className="col-xs-12 col-md-3">
                <TextField
                  className="inputSmall NDN "
                  onChange={(e) => this.OnChange_NDN(e)}
                  required
                  id="NDN"
                  type="text"
                  value={this.state.NDN}
                  error={!valide_NDN}
                  onFocus={() => {
                    this.setState({ focusNDN: true, ColorNDN: "#445aa5" });
                  }}
                  onBlur={(e) => this.onBlur_NDN(e)}
                  helperText={this.state.msgError_nom_de_naissance}
                  label="Nom de naissance"
                  placeholder="Nom de naissance"
                  InputLabelProps={{
                    shrink: true,
                    style: { color: this.state.ColorNDN },
                  }}
                  inputProps={{ maxLength: 25 }}
                />
              </div>

              {/*   <--------------------- Div Prenom----------------------------> */}
              <div className="col-xs-12 col-md-3">
                <TextField
                  className="inputSmall  Disabled "
                  required
                  disabled={true}
                  value={Prenom}
                  id="Prenom"
                  type="text"
                  label="Prénom"
                  InputLabelProps={{ shrink: true }}
                />
              </div>
            </div>
            {/*   <--------------------- Div Situation familale----------------------------> */}
            <div className="row r-15  mb-40">
              <div className="col-xs-12 col-md-3  ">
                <FormControl
                  required
                  className={`inputSmall SF  ${Arrow_Style_SF}`}
                >
                  <InputLabel
                    /*  style={{ color: this.state.valide_Departement && "#445AA5" }} */
                    style={{ color: ColorSF }}
                    id="demo-simple-select-required-label"
                  >
                    Situation familale
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-required-label"
                    id="demo-simple-select-required"
                    value={situation_familale}
                    label="Situation familale *"
                    error={!valide_SF}
                    onChange={(e) => this.OnChangeSF(e)}
                    onFocus={() => this.onFocusSF()}
                    onBlur={() => this.onBlurSF()}
                  >
                    <MenuItem value="Sélectionner">
                      <span className="Selectionner_Opacity">
                        -Sélectionner-
                      </span>
                    </MenuItem>
                    <MenuItem value="célibataire">Célibataire</MenuItem>
                    <MenuItem value="marié(e)">Marié(e)</MenuItem>
                    <MenuItem value="Concubin(e)">Concubin(e)/Pacsé(e)</MenuItem>
                    <MenuItem value="Séparé(e)">Séparé(e)/Divorcé(s)</MenuItem>
                    <MenuItem value="Veuf(ve)">Veuf(ve)</MenuItem>
                  </Select>
                  {!valide_SF && (
                    <FormHelperText className="Inalid_Label">
                      *Champ obligatoire
                    </FormHelperText>
                  )}
                </FormControl>
              </div>
            </div>
            <div className="row r-15  mb-40">
              {/*   <--------------------- Div Date de naissance----------------------------> */}
              <div className="col-xs-12 col-md-3  ">
                <TextField
                  className="inputSmall  Disabled "
                  required
                  disabled={true}
                  value={Date_De_Naissance}
                  id="Date de naissance"
                  type="text"
                  label="Date de naissance"
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              {/*   <--------------------- Div   Pays de naissance----------------------------> */}
              <div className="col-xs-12 col-md-3  ">
                <FormControl
                  required
                  className="inputSmall Pays_de_naissance Select_BK"
                >
                  <InputLabel id="demo-simple-select-required-label">
                    Pays de naissance
                  </InputLabel>

                  <Select
                    id="demo-simple-select"
                    value={Pays_de_naissance}
                    label="Pays de naissance "
                    onChange={(e) => this.Pays_de_naissance(e)}
                  >
                    {Data_Pays_de_Naissance.map((items) => {
                      return (
                        <MenuItem value={items.id}>{items.value}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>

              {/*   <--------------------- Div  Département----------------------------> */}
              {this.state.Pays_de_naissance === "France" && (
                <div className="col-xs-12 col-md-3">
                  <FormControl
                    required
                    className={`inputSmall Departement  ${Arrow_Style_Departement}`}
                  >
                    <InputLabel
                      /*  style={{ color: this.state.valide_Departement && "#445AA5" }} */
                      style={{ color: ColorDepartement }}
                      id="demo-simple-select-required-label"
                    >
                      Département de naissance
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-required-label"
                      id="demo-simple-select-required"
                      value={Departement}
                      label="Département de naissance*"
                      error={!valide_Departement}
                      onChange={(e) => this.OnChangeDepartement(e)}
                      onFocus={() => this.onFocusDepartement()}
                      onBlur={() => this.onBlurDepartement()}
                    >
                      <MenuItem value="Sélectionner">
                        <span className="Selectionner_Opacity">
                          -Sélectionner-
                        </span>
                      </MenuItem>
                      {Data_Departement.map((items) => {
                        return (
                          <MenuItem value={items.value}>{items.value}</MenuItem>
                        );
                      })}
                    </Select>
                    {!valide_Departement && (
                      <FormHelperText className="Inalid_Label">
                        *Champ obligatoire
                      </FormHelperText>
                    )}
                  </FormControl>
                </div>
              )}
              {/*   <--------------------- Div  Ville de naissance----------------------------> */}
              <div className="col-xs-12 col-md-3">
                <TextField
                  className="inputSmall VDN "
                  onChange={(e) => this.OnChange_VDN(e)}
                  required
                  id="VDN"
                  type="text"
                  error={!valide_VDN}
                  value={this.state.ville_de_naissance}
                  onFocus={() => {
                    this.setState({ focusVDN: true, ColorVDN: "#445aa5" });
                  }}
                  onBlur={(e) => this.onBlur_VDN(e)}
                  helperText={this.state.msgError_Ville_de_naissance}
                  label="Ville de naissance"
                  placeholder="Ville de naissance"
                  InputLabelProps={{
                    shrink: true,
                    style: { color: this.state.ColorVDN },
                  }}
                  inputProps={{ maxLength: 25 }}
                />
              </div>
            </div>
            <div className="row r-15">
              <span className="textBudget">Coordonnées</span>
            </div>
            <div className="row r-15 mb-40">
              {/*   <--------------------- Div Numéro et voie----------------------------> */}

              <div className="col-xs-12 col-md-6">
                <TextField
                  className="inputSmall NEV "
                  onChange={(e) => this.OnChangeNEV(e)}
                  required
                  id="NEV"
                  type="text"
                  error={!valide_NEV}
                  value={this.state.Numero_et_voie}
                  onFocus={() => {
                    this.setState({ focusNEV: true, ColorNEV: "#445aa5" });
                  }}
                  onBlur={(e) => this.onBlurNEV(e)}
                  helperText={this.state.msgError_Numero_et_voie}
                  label="Numéro et voie"
                  placeholder="Numéro et voie"
                  InputLabelProps={{
                    shrink: true,
                    style: { color: this.state.ColorNEV },
                  }}
                  inputProps={{ maxLength: 50 }}
                />
              </div>
              {/*   <--------------------- Div Code Postal----------------------------> */}
              {this.state.Pays_de_naissance === "France" ? (
                <div className="col-xs-12 col-md-3">
                  <TextField
                    className="inputSmall CodePostal "
                    label="Code postal"
                    placeholder="Code postal"
                    onChange={(e) => this.ValidationCodePostal(e, "FR")}
                    required
                    value={this.state.CodeP}
                    id="fullWidth"
                    error={!valide_CodePostal}
                    onFocus={() =>
                      this.setState({
                        focusCodeP: true,
                        ColorCodePosta: "#445aa5",
                      })
                    }
                    onBlur={(e) => this.onBlurCodePostal(e, "FR")}
                    helperText={msgError_CodeP}
                    InputLabelProps={{
                      shrink: true,
                      style: { color: this.state.ColorCodePosta },
                    }}
                    inputProps={{ maxLength: 5 }}
                  />
                </div>
              ) : (
                <div className="col-xs-12 col-md-3">
                  <TextField
                    className="inputSmall CodePostal "
                    label="Code postal"
                    placeholder="Code postal"
                    onChange={(e) => this.ValidationCodePostal(e, "NOT_FR")}
                    required
                    id="fullWidth"
                    value={this.state.CodeP}
                    error={!valide_CodePostal}
                    onFocus={() =>
                      this.setState({
                        focusCodeP: true,
                        ColorCodePosta: "#445aa5",
                      })
                    }
                    onBlur={(e) => this.onBlurCodePostal(e, "NOT_FR")}
                    helperText={msgError_CodeP}
                    InputLabelProps={{
                      shrink: true,
                      style: { color: this.state.ColorCodePosta },
                    }}
                    inputProps={{ maxLength: 5 }}
                  />
                </div>
              )}

              {/*   <--------------------- Div Ville ----------------------------> */}
              {this.state.Pays_de_naissance === "France" ? (
                <div className="col-xs-12 col-md-3">
                  { this.state.listdata.length <2?
                  <TextField
                    className="inputSmall Ville   "
                    required
                    disabled={true}
                    value={data_ville}
                    id="Ville"
                    type="text"
                    label="Ville"
                    InputLabelProps={{ shrink: true }}
                  />

                  :
                  <FormControl
                  required
                  className={`inputSmall ville  ${Arrow_Style_Ville}`}
                >
                  <InputLabel
                    style={{ color: ColorVille }}
                    id="demo-simple-select-required-label"
                  >
                    Ville
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-required-label"
                    id="demo-simple-select-required"
                    value={this.state.data_ville}
                    label="Ville*"
                    error={!valide_Ville}
                    onChange={(e) => this.OnChangeVilleDataList(e)}
                    onFocus={() => this.onFocusVilleDataListt()}
                    onBlur={(e)=>this.onBlurVilleSelect(e)}
                  >
                    <MenuItem value="Sélectionner">
                        -Sélectionner-
                    </MenuItem>
                    {this.state.listdata.map((items) => {
                      return (
                        <MenuItem value={items.value}>{items.value}</MenuItem>
                      );
                    })}
                  </Select>
                  {!valide_Ville && (
                    <FormHelperText className="Inalid_Label">
                      *Champ obligatoire
                    </FormHelperText>
                  )}
                </FormControl>
                
                 
                }
                </div>
              ) : (
                <div className="col-xs-12 col-md-3">
                  <TextField
                    className="inputSmall Ville "
                    onChange={(e) => this.OnChangeVille(e)}
                    required
                    id="Ville"
                    type="text"
                    error={!valide_Ville}
                    value={data_ville}
                    onFocus={() => {
                      this.setState({
                        focusVille: true,
                        ColorVille: "#445aa5",
                      });
                    }}
                    onBlur={(e) => this.onBlurVille(e)}
                    helperText={this.state.msgError_Ville}
                    label="Ville"
                    placeholder="Ville"
                    InputLabelProps={{
                      shrink: true,
                      style: { color: this.state.ColorVille },
                    }}
                    inputProps={{ maxLength: 50 }}
                  />
                </div>
              )}
            </div>
            <div className="row r-15 mb-40">
              {/*   <--------------------- Div Email----------------------------> */}
              <div className="col-xs-12 col-md-6 colestart">
                <TextField
                  className="selectLarge email"
                  label="Email"
                  placeholder="Email"
                  value={this.state.Email}
                  onChange={(e) => this.ValidationEmail(e)}
                  required
                  id="fullWidth"
                  error={!Valide_Email}
                  onFocus={() => {
                    this.setState({ focusEmail: true, ColorEmail: "#445aa5" });
                  }}
                  onBlur={(e) => this.onBlurEmail(e)}
                  helperText={
                    !Valide_Email ? " L'adresse e-mail est invalide " : null
                  }
                  InputLabelProps={{
                    shrink: true,
                    style: { color: ColorEmail },
                  }}
                />
              </div>
            </div>

            <div className="row r-15">
              <span className="textBudget">Téléphone</span>
            </div>
            {/*     <--------------------------------------------- Div phone Number---------------------------------------------------> */}
            <div className="row r-15 mb-40  ">
              <div className="col-xs-12 col-md-2 ">
                <FormControl
                  required
                  className={`inputSmall Indicatif  ${Arrow_Style_Phone}`}
                >
                  <InputLabel
                    style={{ color: ColorIndicatif }}
                    id="demo-simple-select-required-label"
                  >
                    Indicatif
                  </InputLabel>
                  <Select
                    id="demo-simple-select"
                    className="Select_Phone_Code Select_fixe   "
                    value={codeCountry}
                    onChange={(e) => this.onChange_Select_Fixe(e)}
                  >
                    <MenuItem value="+33">+33 [France]</MenuItem>
                    <MenuItem value="+590">
                      +590 [Guadeloupe, Saint Martin, Saint Barthélémy]
                    </MenuItem>
                    <MenuItem value="+594">+594 [Guyane]</MenuItem>
                    <MenuItem value="+596">+596 [Martinique]</MenuItem>
                    <MenuItem value="+262">+262 [Réunion]</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="col-xs-12 col-md-4">
                <TextField
                  className="inputSmall Numero "
                  onChange={(e) => this.OnChangeNumero(e)}
                  required
                  id="Numero"
                  type="text"
                  value={this.state.Numero}
                  error={!valide_Numero}
                  // onFocus={() => {
                  //   this.setState({ focusNumero: true, ColorNumero: "#445aa5" });
                  // }}
                  // onBlur={() => this.onBlurNumero()}
                  helperText={!valide_Numero ? Msg_valide_Numero : null}
                  label="Numéro"
                  placeholder="ex.0652556325"
                  InputLabelProps={{
                    shrink: true,
                    style: { color: ColorNumero },
                  }}
                  inputProps={{ maxLength: 10 }}
                />
              </div>

              {/* --------------------------------------- DIV BTN ENG1 ---------------------------------- */}

              <div className="row r-15 navigationSection">
                <div className="col-12 colend">
                  <a
                    className="btn btn-default"
                    variant="btn"
                    onClick={this.OnPrecedent}
                  >
                    Précédent
                  </a>

                  {(() => {
                    if (BTN_visualiser) {
                      
                      if (Pays_de_naissance === "France") {
                        if (
                          this.state.NDN !== "" &&
                          this.state.valide_NDN &&
                          this.state.situation_familale !== "Sélectionner" &&
                          this.state.ville_de_naissance !== "" &&
                          this.state.valide_VDN &&
                          this.state.Departement !== "Sélectionner" &&
                          this.state.Numero_et_voie !== "" &&
                          this.state.valide_NEV &&
                          this.state.CodeP !== "" &&
                          this.state.valide_CodePostal &&
                          this.state.valide_Ville &&
                          this.state.Email !== "" &&
                          this.state.Valide_Email &&
                          this.state.Numero !== "" &&
                          this.state.valide_Numero 
                        ) {
                          return (
                            <Button
                              className="btn btnActive btn-default"
                              onClick={(event) => {
                                this.AddSouscription(event);
                              }}
                            >
                              Enregistrer
                            </Button>
                          );
                        } else {
                          return (
                            <Tippy
                              id="Tippy"
                              content="Un ou plusieurs champs sont en erreur ou ne sont pas renseignés."
                              maxWidth={180}
                            >
                              <Button
                                variant="secondary"
                                className="btn btn-default"
                                id="disabled"
                              >
                                Enregistrer
                              </Button>
                            </Tippy>
                          );
                        }
                      } else if (Pays_de_naissance !== "France") {
                        if (
                          this.state.NDN !== "" &&
                          this.state.valide_NDN &&
                          this.state.situation_familale !== "Sélectionner" &&
                          this.state.ville_de_naissance !== "" &&
                          this.state.valide_VDN &&
                          this.state.Departement !== "Sélectionner" &&
                          this.state.Numero_et_voie !== "" &&
                          this.state.valide_NEV &&
                          this.state.CodeP !== "" &&
                          this.state.valide_CodePostal &&
                          this.state.valide_Ville &&
                          this.state.Email !== "" &&
                          this.state.Valide_Email &&
                          this.state.Numero !== "" &&
                          this.state.valide_Numero 
                        ) {
                          return (
                            <Button
                            className="btn btnActive btn-default"
                            onClick={(event) => {
                              this.AddSouscription(event);
                            }}
                          >
                            Enregistrer
                          </Button>
                          );
                        } else {
                          return (
                            <Tippy
                              id="Tippy"
                              content="Un ou plusieurs champs sont en erreur ou ne sont pas renseignés."
                            >
                              <Button
                                variant="secondary"
                                className="btn btn-default"
                                id="disabled"
                              >
                                Enregistrer
                              </Button>
                            </Tippy>
                          );
                        }
                      }
                    } else {
                      if (Pays_de_naissance === "France") {
                        if (
                          this.state.NDN !== "" &&
                          this.state.valide_NDN &&
                          this.state.situation_familale !== "Sélectionner" &&
                          this.state.ville_de_naissance !== "" &&
                          this.state.valide_VDN &&
                          this.state.Departement !== "Sélectionner" &&
                          this.state.Numero_et_voie !== "" &&
                          this.state.valide_NEV &&
                          this.state.CodeP !== "" &&
                          this.state.valide_CodePostal &&
                          this.state.valide_Ville &&
                          this.state.Email !== "" &&
                          this.state.Valide_Email &&
                          this.state.Numero !== "" &&
                          this.state.valide_Numero 
                        ) {
                          return (
                            <Button
                              className="btn btnActive btn-default"
                              onClick={(event) => {
                                this.AddSouscription(event);
                              }}
                            >
                              Enregistrer
                            </Button>
                          );
                        } else {
                          return (
                            <Tippy
                              id="Tippy"
                              content="Un ou plusieurs champs sont en erreur ou ne sont pas renseignés."
                              maxWidth={180}
                            >
                              <Button
                                variant="secondary"
                                className="btn btn-default"
                                id="disabled"
                              >
                                Enregistrer
                              </Button>
                            </Tippy>
                          );
                        }
                      } else if (Pays_de_naissance !== "France") {
                        if (                          
                          this.state.NDN !== "" &&
                          this.state.valide_NDN &&
                          this.state.situation_familale !== "Sélectionner" &&
                          this.state.ville_de_naissance !== "" &&
                          this.state.valide_VDN &&
                          this.state.Departement !== "Sélectionner" &&
                          this.state.Numero_et_voie !== "" &&
                          this.state.valide_NEV &&
                          this.state.CodeP !== "" &&
                          this.state.valide_CodePostal &&
                          this.state.valide_Ville &&
                          this.state.Email !== "" &&
                          this.state.Valide_Email &&
                          this.state.Numero !== "" &&
                          this.state.valide_Numero 
                        ) {
                          return (
                            <Button
                              className="btn btnActive btn-default"
                              onClick={(event) => {
                                this.AddSouscription(event);
                              }}
                            >
                              Enregistrer
                            </Button>
                          );
                        } else {
                          return (
                            <Tippy
                              id="Tippy"
                              content="Un ou plusieurs champs sont en erreur ou ne sont pas renseignés."
                            >
                              <Button
                                variant="secondary"
                                className="btn btn-default"
                                id="disabled"
                              >
                                Enregistrer
                              </Button>
                            </Tippy>
                          );
                        }
                      }
                    }
                  })()}
                </div>
              </div>
            </div>
          </div>
        </div>

        <ModalExpire show={this.state.modalExpire} />
        {this.state.modalServeur ? (
          <ModalServeur
            handleCloseModalServeur={() => this.handleCloseModalServeur()}
          />
        ) : null}

        {this.state.loading && (
          <div className="loading">
            <div className="boxLoading">
              <img src={rotation} alt={rotation} className="svgrotation" />
              <img src={repamSvg} alt={repamSvg} />
            </div>
          </div>
        )}
        {this.state.showModal && (
          <ModalQuit
            page={this.state.namePage}
            OnchangeModal={this.OnchangeModal}
          />
        )}
      </>
    );
  }
}
export default withRouter(Proposition);
