import React, { Component } from "react";
import Button from "@mui/material/Button";
import icoFile from "../../assets/img/icoFile.svg";
import "./ComponentProposition/proposition.css";
import ModalAdmin from "./CommonComponent/modalAdmin";
import {docDownload, getToken, getAdmin} from "../../Context/ApiContext"
import DataTable from "react-data-table-component";
import { columnsAdmin } from "./ComponentAdmin/Data.js";
import rotation from "../../assets/img/rotation.svg";
import repamSvg from "../../assets/img/repam.svg";
import $ from "jquery";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional

class Admin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      columns: columnsAdmin,
      rows: [],
      NumPage: 1,
      NumRows: null,
      loading: true,
      idCourtier: null,
      typeApi:'',
      resultatTableDep: "Aucun administrateur",
      text:""
    }
  }


  
   /*  <--------------------------------------------- componentDidMount ----------------------------------------------> */
   componentDidMount() {


    const requestOptions = { method: "GET" };
    const  apiUrl = `${process.env.REACT_APP_URL}/courtiersAdmin/${getToken()}`
    fetch(apiUrl, requestOptions)
      .then((response) => {
        if (response.status == 200) {
          return response.json();
        } else if (response.status == 404) {
          this.props.navigate("/Page_404");
        } else {
          this.props.navigate("/Page_500");
        }
      })
      .then((data) => {
        if (data.code == "-2") {
          this.setState({
            loading: false,
            modalExpire: true,
          });
        } else if (data.code == "-1") {
          this.setState({
            modalServeur: true,
            loading: false,
          })

        } else {
       
          this.setState({
            loading: false,
            rows: data.result
          });

        this.clickAction()
        }
      })
      .catch((error) => {
        this.props.navigate("/Page_500");
      });
   }
    /*  <--------------------------------------------- componentDidUpdate ----------------------------------------------> */
    componentDidUpdate(prevProps, prevState){
      if(prevState.rows !== this.state.rows){
        this.clickAction()
      }
   }

    /*  <--------------------------------------------- active désactive ----------------------------------------------> */
    clickAction = () =>{
      let adminAct =$('.adminAct')
      console.log(adminAct)
      let adminDesact =$('.adminDesact')
      for(let i=0; i<adminAct.length; i++){
        let id = adminAct[i].getAttribute('id');
        $('#'+id).click(() => {
          this.setState({
            idCourtier : id.split('_')[1],
            typeApi: '1',
            text: "Êtes-vous sûr de vouloir activer ce administrateur ?"
          })
          this.openModal()
        });

      }
      for(let i=0; i<adminDesact.length; i++){
        let id = adminDesact[i].getAttribute('id');
        $('#'+id).click(() => {
          this.setState({
            idCourtier : id.split('_')[1],
            typeApi: '2',
            text: "Êtes-vous sûr de vouloir désactiver ce administrateur ?"
          })
          this.openModal()
        });
      }
    }
    /*  <--------------------------------------------- PAGINATION ----------------------------------------------> */
    FN_onChangePage = (page) => {
      this.setState({ NumPage: page });
    };
  
    FN_onChangeNumRows = (numRows) => {
      this.setState({ NumRows: numRows });
    };

 /*  <--------------------------------------------- MODAL ----------------------------------------------> */
    openModal  = () => {
      this.setState({
        showModal: true,
      });

    };
    OnCloseModal = () => {
        this.setState({
          showModal: false,
        });
      
    };
    api = () => {
      this.setState({
        showModal: false,
        loading: true
      });
      let url = ''
      if(this.state.typeApi == '1'){
        url =  `${process.env.REACT_APP_URL}/Activercourtier/${this.state.idCourtier}/${getToken()}`
      }else if(this.state.typeApi == '2'){
        url =  `${process.env.REACT_APP_URL}/desActivercourtier/${this.state.idCourtier}/${getToken()}`
      }else{
        url =""
      }
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" }
      };
      fetch(url, requestOptions)
      .then((response) => {
        if (response.status == 200) {
          return response.json();
        } else if (response.status == 404) {
          this.props.navigate("/Page_404");
        } else {
          this.props.navigate("/Page_500");
        }
      })
      .then((data) => {
        if (data.code == "-2") {
          this.setState({
            loading: false,
            modalExpire: true,
          });
        } else if (data.code == "-1") {
          this.setState({
            modalServeur: true,
            loading: false,
          })

        } else {
       
          this.setState({
            loading: false,
            rows: data.result
          });
          
        }
      })
      .catch((error) => {
        this.props.navigate("/Page_500");
      });
  
    }

  render() {
    setTimeout(() => {
      let sel = $("div.sc-lllmON select.sc-cwSeag")
        .children("option:selected")
        .val();
      /* <------------------- change span linges par page -------------------> */
      $("nav.rdt_Pagination span.sc-ilhmMj").empty();
      $("nav.rdt_Pagination span.sc-ilhmMj").append("Lignes par page");
      /* <-------- change span numero de page / nombre de linge  ------------> */
      $("nav.rdt_Pagination span.sc-kMjNwy ").empty();
      $("nav.rdt_Pagination span.sc-kMjNwy").append(
        `<div id='d-num-row'><div id='Id-S'>${this.state.NumPage}</div> de  ${sel} </div>`
      );
    }, 0);
    return (
      <>
        <div className="row   row-MV">
              <div className="col-4 colstart divPro">
                <span className="textPro ">Liste des administrateurs </span>
              </div>
        </div>
        <div >
          <DataTable
              title="List"
              columns={this.state.columns}
              data={this.state.rows}
              noDataComponent={this.state.resultatTableDep}
              pagination
              striped
              dense
              noHeader
              onChangePage={(page) => this.FN_onChangePage(page)}
              onChangeRowsPerPage={(numRows) =>
                this.FN_onChangeNumRows(numRows)
              }
            />
        </div>
        {this.state.showModal && (
          <ModalAdmin
            text = {this.state.text}
            api ={() =>this.api()}
            OnCloseModal={()=>this.OnCloseModal()}
          />
        )}
        {this.state.loading && (
            <div className="loading">
              <div className="boxLoading">
                <img src={rotation} alt={rotation} className="svgrotation" />
                <img src={repamSvg} alt={repamSvg} />
              </div>
            </div>
          )}
      </>
    );
  }
}

export default Admin;
