import icon_delete from "../../../assets/img/icon-delete.svg";
import icon_edit from "../../../assets/img/icon-edit.svg";
import icon_visualiser from "../../../assets/img/icon-visualiser.svg";
import "./Tabe.css";
import "../ComponentSimulation/Simulation.css";

const img = [icon_visualiser, icon_edit, icon_delete];
const test = ["show_icone", "update_icone", "delete_icone"];

export const columns = [
  {
    name: "N° de projet",
    selector: (row) => row.num,
    sortable: true,
  },
  {
    name: "Adhérent",
    selector: (row) => row.adherent,
    sortable: true,
  },
  {
    name: "Date de création",
    selector: (row) => row.ddc,
    sortable: true,
  },
  {
    name: "Date d'effet",
    selector: (row) => row.ddf,
    sortable: true,
  },
  {
    name: "Statut",
    selector: (row) => row.statut,
    sortable: true,
  },
  {
    name: "Cotisation mensuelle",
    selector: (row) => row.cmttc,
    sortable: true,
  },
  {
    name: "Actions",
    selector: (row) => row.actions,
    sortable: false,
  },
];
export const columnsAdmin = [
  {
    name: "Cabinet",
    selector: (row) => row.cabinet,
    sortable: true,
  },
  {
    name: "N° de projet",
    selector: (row) => row.num,
    sortable: true,
  },
  {
    name: "Adhérent",
    selector: (row) => row.adherent,
    sortable: true,
  },
  {
    name: "Date de création",
    selector: (row) => row.ddc,
    sortable: true,
  },
  {
    name: "Date d'effet",
    selector: (row) => row.ddf,
    sortable: true,
  },
  {
    name: "Statut",
    selector: (row) => row.statut,
    sortable: true,
  },
  {
    name: "Cotisation mensuelle",
    selector: (row) => row.cmttc,
    sortable: true,
  },
  {
    name: "Actions",
    selector: (row) => row.actions,
    sortable: false,
  },
];
export const data = [];
