import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import { withRouter } from "../whitRouter";
import arrowLeft from "../../../assets/img/arrow-left-solid.svg";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import RadialSeparators from "./RadialSeparators";
import Dnw from "../../../assets/img/check-solid.svg";


class ModalProposition extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      showM_2: false,
      disabledBtn: false,
      hStep: false,
      hStep2: false,
      donwload: false,
      BtnStep1: false,
      BtnStep2: false,
      Res: "",
      percentage: 0,

    };
  }

  DocumentPdf = () => {
    this.setState({ Res: "oui" });

  };

  handleClose = () => {
    this.setState({
      show: false,
      disabledBtn: false,
      hStep: false,
      hStep2: false,
      donwload: false,
      BtnStep1: false,
      BtnStep2: false,
    });
  };
  handleShow = () => {
    this.setState({
      show: true,
      disabledBtn: false,
      hStep: false,
      hStep2: false,
      donwload: false,
      BtnStep1: false,
      BtnStep2: false,
    });
  };

  choixstep1 = () => {
    this.setState({
      disabledBtn: true,
      BtnStep1: true,
      BtnStep2: false,
    });
  };
  choixstep2 = () => {
    this.setState({
      disabledBtn: true,
      BtnStep1: false,
      BtnStep2: true,
    });
  };

  step = () => {
    this.setState({
      hStep: true,
      hStep2: false,
    });
  };
  step2 = () => {
    this.setState({
      hStep: false,
      hStep2: true,
      stepShow2: true,
      show: false,
      showM_2: true,
    });
  };

  precStep = () => {
    this.setState({
      hStep: false,
      hStep2: false,
      BtnStep1: false,
      BtnStep2: false,
    });
  };

  precStep2 = () => {
    this.setState({
      hStep: true,
      hStep2: false,
      BtnStep1: false,
      BtnStep2: false,
    });
  };

  showPopDnw = () => {
    this.setState({
      show: false,
      showM_2: true,
      disabledBtn: false,
      hStep: false,
      hStep2: true,
      donwload: false,
      BtnStep1: false,
      BtnStep2: false,
    });
  
       
        let linkSource = `${process.env.REACT_APP_URL_PDF}/doc4_Fiche_Info_Conseil.pdf`;
        let downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = "Fiche Info Conseil.pdf";
        downloadLink.click();
        
      

  };

  fnDnw = (event) => {
    event.preventDefault();
    this.setState({
      donwload: false,
      BtnStep1: false,
      BtnStep2: false,
    });
  };
  componentWillUpdate(NewProps, NewState) {
    if (NewState.Res !== this.state.Res) {
      /*   localStorage.removeItem("data_prop"); */
      this.props.navigate("/simulation");
    }
  }
  render() {
    const stepHidden = this.state.hStep ? "stepHidden" : "";
    const stepHidden2 = this.state.hStep2 ? "stepHidden" : "";

    const stepShow = this.state.hStep ? "stepShow" : "";
    const stepShow2 = this.state.hStep2 ? "stepShow" : "";

    let data_Response = [{ Response: this.state.Res, Precedent: false }];
    localStorage.setItem("Data_Dependance", JSON.stringify(data_Response));

    return (
      <>
        <Button className="Nouvelle" onClick={this.handleShow}>
          Nouveau projet
        </Button>
        {/* Modal Download doc */}

        <Modal id="ModalDnw" show={this.state.donwload} >
          <Modal.Body>
            <div className="boxMRdnw">
              <a href="/" onClick={this.fnDnw} className="targetDnw">
                {!this.state.endDonwoload ? (
                  <CircularProgressbarWithChildren
                    value={this.state.percentage}
                    text={`${this.state.percentage}%`}
                    strokeWidth={10}
                    styles={buildStyles({
                      strokeLinecap: "butt",
                      textColor: "#000000",
                      pathColor: "#E73C4C",
                      trailColor: "#fff",
                    })}
                  >
                    <RadialSeparators
                      count={35}
                      style={{
                        background: "#fff",
                        width: "2px",
                        // This needs to be equal to props.strokeWidth
                        height: `${10}%`,
                      }}
                    />
                  </CircularProgressbarWithChildren>
                ) : (
                  <div className="succesDnw">
                    <img
                      src={Dnw}
                      className="svgDnw"
                      alt={Dnw}
                      onClick={this.precStep}
                    />
                  </div>
                )}
              </a>
              <div className="boxTitleDnw">
                {!this.state.endDonwoload ? (
                  <>
                    <h3>Téléchargement en cours</h3>
                    <p>Veuillez patienter</p>
                  </>
                ) : (
                  <>
                    <h3>Téléchargement terminé</h3>
                    <p>Document téléchargé</p>
                  </>
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* Modal initiale */}

        <Modal
          id="ModalProposition"
          show={this.state.show}
          onHide={this.handleClose}
        >
          <img
            src={arrowLeft}
            className={`arrowLeft ${this.stepShow}`}
            alt={arrowLeft}
            onClick={this.precStep}
          />
          <img
            src={arrowLeft}
            className={`arrowLeft ${this.stepShow2}`}
            alt={arrowLeft}
            onClick={this.precStep2}
          />
          <h3>Nouvelle proposition</h3>

          <Modal.Body>
            <div className={`step step1 ${stepHidden} ${stepHidden2}`}>
              <div className="p-30">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                    onClick={this.choixstep1}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault1"
                    onClick={this.choixstep1}
                  >
                    Souscrire un nouveau contrat Dépendance
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault2"

                    onClick={this.choixstep2}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault2"
                    onClick={this.choixstep2}
                  >
                    Réaliser un versement complémentaire pour acheter des unités de rente additionnelles sur un contrat existant
                  </label>
                </div>
              </div>
            </div>

            <div className={`step step2 ${stepShow}`}>
              <p>Souhaitez-vous utiliser le recueil des besoins REPAM?</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {!this.state.disabledBtn && (
              <Button
                className={`${stepHidden}  ${stepHidden2}`}
                variant="primary"
                onClick={this.step}
                disabled={!this.state.disabledBtn}
              >
                Confirmer
              </Button>
            )}
            {this.state.BtnStep1 && (
              <Button
                className={`${stepHidden}  ${stepHidden2}`}
                variant="primary"
                onClick={this.step}
              >
                Confirmer
              </Button>
            )}
            {this.state.BtnStep2 && (
              <Link className=" btn btn-primary" to="/newProposition">
                Confirmer
              </Link>
            )}

            <Button
              className={`${stepHidden}  ${stepHidden2}`}
              variant="secondary"
              onClick={this.handleClose}
            >
              Annuler
            </Button>

            {/* oui 1er */}
            <Button
              className={`btn_step2 btn btn-primary ${stepShow}`}
              onClick={this.showPopDnw}
            >
              Oui
            </Button>
            <Button
              className={`btn_step2 ${stepShow}`}
              variant="secondary"
              onClick={this.step2}
            >
              Non
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Modal Link simulation  */}

        <Modal id="ModalProposition" show={this.state.showM_2}>
          <h3>Nouvelle proposition</h3>

          <Modal.Body>
            {/* cont 3 */}
            <div className={`step step3 ${stepShow2}`}>
              <p>
                La nouvelle proposition concerne t-elle une personne déjà
                adhérente REPAM ?
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {/* oui 2eme */}

            <Button
              onClick={this.DocumentPdf}
              className={`btn_step3 btn btn-primary ${stepShow2}`}
            >
              Oui
            </Button>
            <Button
              onClick={() => {
                this.setState({ Res: "non" });
              }}
              className={`btn_step3  ${stepShow2}`}
              variant="secondary"
            >
              Non
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default withRouter(ModalProposition);
