/* -------------------- Data Pays de Naissance  ----------------------  */
export const Data_Pays_de_Naissance = [
  { id: "France", value: "France" },
  { id: "Algerie", value: "Algerie" },
  { id: "Maroc", value: "Maroc" },
  { id: "Belgique", value: "Belgique" },
  { id: "Suisse", value: "Suisse" },
  { id: "Espagne", value: "Espagne" },
  { id: "Allemagne", value: "Allemagne" },
  { id: "Afghanistan", value: "Afghanistan" },
  { id: "Afrique_Centrale", value: "Afrique_Centrale" },
  { id: "Afrique_du_sud", value: "Afrique_du_sud" },

  { id: "Albanie", value: "Albanie" },
  { id: "Andorre", value: "Andorre" },
  { id: "Angola", value: "Angola" },
  { id: "Arabie_Saoudite", value: "Arabie_Saoudite" },
  { id: "Argentine", value: "Argentine" },
  { id: "Armenie", value: "Armenie" },
  { id: "Australie", value: "Australie" },
  { id: "Autriche", value: "Autriche" },
  { id: "Azerbaidjan", value: "Azerbaidjan" },
  { id: "Bahamas", value: "Bahamas" },

  { id: "Bangladesh", value: "Bangladesh" },
  { id: "Barbade", value: "Barbade" },
  { id: "Bahrein", value: "Bahrein" },
  { id: "Belize", value: "Belize" },
  { id: "Benin", value: "Benin" },
  { id: "Bermudes", value: "Bermudes" },
  { id: "Bielorussie", value: "Bielorussie" },
  { id: "Bolivie", value: "Bolivie" },
  { id: "Botswana", value: "Botswana" },
  { id: "Bhoutan", value: "Bhoutan" },

  { id: "Boznie_Herzegovine", value: "Boznie_Herzegovine" },
  { id: "Bresil", value: "Bresil" },
  { id: "Brunei", value: "Brunei" },
  { id: "Bulgarie", value: "Bulgarie" },
  { id: "Burkina_Faso", value: "Burkina_Faso" },
  { id: "Burundi", value: "Burundi" },
  { id: "Caiman", value: "Caiman" },
  { id: "Cambodge", value: "Cambodge" },
  { id: "Cameroun", value: "Cameroun" },
  { id: "Canada", value: "Canada" },

  { id: "Canaries", value: "Canaries" },
  { id: "Cap_vert", value: "Cap_vert" },
  { id: "Chili", value: "Chili" },
  { id: "Chine", value: "Chine" },
  { id: "Chypre", value: "Chypre" },
  { id: "Colombie", value: "Colombie" },
  { id: "Comores", value: "Comores" },
  { id: "Congo", value: "Congo" },
  { id: "Congo_democratique", value: "Congo_democratique" },
  { id: "Cook", value: "Cook" },

  { id: "Coree_du_Nord", value: "Coree_du_Nord" },
  { id: "Coree_du_Sud", value: "Coree_du_Sud" },
  { id: "Costa_Rica", value: "Costa_Rica" },
  { id: "Cote_d_Ivoire", value: "Cote_d_Ivoire" },
  { id: "Croatie", value: "Croatie" },
  { id: "Cuba", value: "Cuba" },
  { id: "Danemark", value: "Danemark" },
  { id: "Djibouti", value: "Djibouti" },
  { id: "Dominique", value: "Dominique" },
  { id: "Egypte", value: "Egypte" },

  { id: "Emirats_Arabes_Unis", value: "Emirats_Arabes_Unis" },
  { id: "Equateur", value: "Equateur" },
  { id: "Erythree", value: "Erythree" },
  { id: "Estonie", value: "Estonie" },
  { id: "Etats_Unis", value: "Etats_Unis" },
  { id: "Ethiopie", value: "Ethiopie" },
  { id: "Falkland", value: "Falkland" },
  { id: "Feroe", value: "Feroe" },
  { id: "Fidji", value: "Fidji" },
  { id: "Finlande", value: "Finlande" },

  { id: "Gabon", value: "Gabon" },
  { id: "Gambie", value: "Gambie" },
  { id: "Georgie", value: "Georgie" },
  { id: "Ghana", value: "Ghana" },
  { id: "Gibraltar", value: "Gibraltar" },
  { id: "Grece", value: "Grece" },
  { id: "Grenade", value: "Grenade" },
  { id: "Groenland", value: "Groenland" },
  { id: "Guadeloupe", value: "Guadeloupe" },
  { id: "Guam", value: "Guam" },

  { id: "Guatemala", value: "Guatemala" },
  { id: "Guernesey", value: "Guernesey" },
  { id: "Guinee", value: "Guinee" },
  { id: "Guinee_Bissau", value: "Guinee_Bissau" },
  { id: "Guinee equatoriale", value: "Guinee equatoriale" },
  { id: "Guyana", value: "Guyana" },
  { id: "Guyane_Francaise", value: "Guyane_Francaise" },
  { id: "Haiti", value: "Haiti" },
  { id: "Hawaii", value: "Hawaii" },
  { id: "Honduras", value: "Honduras" },

  { id: "Hong_Kong", value: "Hong Kong" },
  { id: "Hongrie", value: "Hongrie" },
  { id: "Inde", value: "Inde" },
  { id: "Indonesie", value: "Indonesie" },
  { id: "Iran", value: "Iran" },
  { id: "Iraq", value: "Iraq" },
  { id: "Irlande", value: "Irlande" },
  { id: "Islande", value: "Islande" },
  { id: "Italie", value: "Italie" },
  { id: "Jamaique", value: "Jamaique" },

  { id: "Jan Mayen", value: "Jan Mayen" },
  { id: "Japon", value: "Japon" },
  { id: "Jersey", value: "Jersey" },
  { id: "Jordanie", value: "Jordanie" },
  { id: "Kazakhstan", value: "Kazakhstan" },
  { id: "Kenya", value: "Kenya" },
  { id: "Kirghizstan", value: "Kirghizstan" },
  { id: "Kiribati", value: "Kiribati" },
  { id: "Koweit", value: "Koweit" },
  { id: "Laos", value: "Laos" },

  { id: "Lesotho", value: "Lesotho" },
  { id: "Lettonie", value: "Lettonie" },
  { id: "Liban", value: "Liban" },
  { id: "Liberia", value: "Liberia" },
  { id: "Liechtenstein", value: "Liechtenstein" },
  { id: "Lituanie", value: "Lituanie" },
  { id: "Luxembourg", value: "Luxembourg" },
  { id: "Lybie", value: "Lybie" },
  { id: "Macao", value: "Macao" },
  { id: "Macedoine", value: "Macedoine" },

  { id: "Madagascar", value: "Madagascar" },
  { id: "Madère", value: "Madère" },
  { id: "Malaisie", value: "Malaisie" },
  { id: "Malawi", value: "Malawi" },
  { id: "Maldives", value: "Maldives" },
  { id: "Mali", value: "Mali" },
  { id: "Malte", value: "Malte" },
  { id: "Man", value: "Man" },
  { id: "Mariannes du Nord", value: "Mariannes du Nord" },
  { id: "Marshall", value: "Marshall" },

  { id: "Martinique", value: "Martinique" },
  { id: "Maurice", value: "Maurice" },
  { id: "Mauritanie", value: "Mauritanie" },
  { id: "Mayotte", value: "Mayotte" },
  { id: "Mexique", value: "Mexique" },
  { id: "Micronesie", value: "Micronesie" },
  { id: "Midway", value: "Midway" },
  { id: "Moldavie", value: "Moldavie" },
  { id: "Monaco", value: "Monaco" },
  { id: "Mongolie", value: "Mongolie" },

  { id: "Montserrat", value: "Montserrat" },
  { id: "Mozambique", value: "Mozambique" },
  { id: "Namibie", value: "Namibie" },
  { id: "Nauru", value: "Nauru" },
  { id: "Nepal", value: "Nepal" },
  { id: "Nicaragua", value: "Nicaragua" },
  { id: "Niger", value: "Niger" },
  { id: "Nigeria", value: "Nigeria" },
  { id: "Niue", value: "Niue" },
  { id: "Norfolk", value: "Norfolk" },

  { id: "Norvege", value: "Norvege" },
  { id: "Nouvelle_Caledonie", value: "Nouvelle_Caledonie" },
  { id: "Nouvelle_Zelande", value: "Nouvelle_Zelande" },
  { id: "Oman", value: "Oman" },
  { id: "Ouganda", value: "Ouganda" },
  { id: "Ouzbekistan", value: "Ouzbekistan" },
  { id: "Pakistan", value: "Pakistan" },
  { id: "Palau", value: "Palau" },
  { id: "Palestine", value: "Palestine" },
  { id: "Panama", value: "Panama" },

  { id: "Paraguay", value: "Paraguay" },
  { id: "Pays_Bas", value: "Pays_Bas" },
  { id: "Perou", value: "Perou" },
  { id: "Philippines", value: "Philippines" },
  { id: "Pologne", value: "Pologne" },
  { id: "Polynesie", value: "Polynesie" },
  { id: "Porto_Rico", value: "Porto_Rico" },
  { id: "Portugal", value: "Portugal" },
  { id: "Portugal", value: "Portugal" },
  { id: "Qatar", value: "Qatar" },

  { id: "Republique_Dominicaine", value: "Republique_Dominicaine" },
  { id: "Republique_Tcheque", value: "Republique_Tcheque" },
  { id: "Reunion", value: "Reunion" },
  { id: "Royaume_Uni", value: "Royaume_Uni" },
  { id: "Russie", value: "Russie" },
  { id: "Rwanda", value: "Rwanda" },
  { id: "Sahara Occidental", value: "Sahara Occidental" },
  { id: "Sainte_Lucie", value: "Sainte_Lucie" },
  { id: "Saint_Marin", value: "Saint_Marin" },
  { id: "Salomon", value: "Salomon" },

  { id: "Salvador", value: "Salvador" },
  { id: "Samoa_Occidentales", value: "Samoa_Occidentales" },
  { id: "Samoa_Americaine", value: "Samoa_Americaine" },
  { id: "Sao_Tome_et_Principe", value: "Sao Tome et Principe" },
  { id: "Senegal", value: "Senegal" },
  { id: "Seychelles", value: "Seychelles" },
  { id: "Sierra Leone", value: "Sierra Leone" },
  { id: "Singapour", value: "Singapour" },
  { id: "Slovaquie", value: "Slovaquie" },
  { id: "Slovenie", value: "Slovenie" },

  { id: "Somalie", value: "Somalie" },
  { id: "Soudan", value: "Soudan" },
  { id: "Sri_Lanka", value: "Sri_Lanka" },
  { id: "Suede", value: "Suede" },
  { id: "Surinam", value: "Surinam" },
  { id: "Swaziland", value: "Swaziland" },
  { id: "Syrie", value: "Syrie" },
  { id: "Tadjikistan", value: "Tadjikistan" },
  { id: "Taiwan", value: "Taiwan" },
  { id: "Tonga", value: "Tonga" },

  { id: "Tanzanie", value: "Tanzanie" },
  { id: "Tchad", value: "Tchad" },
  { id: "Thailande", value: "Thailande" },
  { id: "Tibet", value: "Tibet" },
  { id: "Timor_Oriental", value: "Timor_Oriental" },
  { id: "Togo", value: "Togo" },
  { id: "Trinite_et_Tobago", value: "Trinite et Tobago" },
  { id: "Tristan da cunha", value: "Tristan da cunha" },
  { id: "Turkmenistan", value: "Turkmenistan" },
  { id: "Tunisie", value: "Tunisie" },

  { id: "Turquie", value: "Turquie" },
  { id: "Ukraine", value: "Ukraine" },
  { id: "Uruguay", value: "Uruguay" },
  { id: "Vanuatu", value: "Vanuatu" },
  { id: "Vatican", value: "Vatican" },
  { id: "Venezuela", value: "Venezuela" },
  { id: "Vierges_Americaines", value: "Vierges_Americaines" },
  { id: "Vierges_Britanniques", value: "Vierges_Britanniques" },
  { id: "Vietnam", value: "Vietnam" },
  { id: "Wake", value: "Wake" },
  { id: "Wallis et Futuma", value: "Wallis et Futuma" },
  { id: "Yemen", value: "Yemen" },
  { id: "Yougoslavie", value: "Yougoslavie" },
  { id: "Zambie", value: "Zambie" },
  { id: "Zimbabwe", value: "Zimbabwe" },
  { id: "Autre", value: "Autre" },
];

/* --------------------------- Data Departement  ----------------------  */
export const Data_Departement = [
  { id: "01", value: "01 - Ain" },
  { id: "02", value: "02 - Aisne" },
  { id: "03", value: "03 - Allier" },
  { id: "04", value: "04 - Alpes-de-Haute-Provence" },
  { id: "05", value: "05 - Hautes-Alpes" },
  { id: "06", value: "06 - Alpes-Maritimes" },
  { id: "07", value: "07 - Ardèche" },
  { id: "08", value: "08 - Ardennes" },
  { id: "09", value: "09 - Ariège" },
  { id: "10", value: "10 - Aube" },
  { id: "11", value: "11 - Aude" },
  { id: "12", value: "12 - Aveyron" },
  { id: "13", value: "13 - Bouches-du-Rhône" },
  { id: "14", value: "14 - Calvados" },
  { id: "15", value: "15 - Cantal" },
  { id: "16", value: "16 - Charente" },
  { id: "17", value: "17 - Charente-Maritime" },
  { id: "18", value: "18 - Cher" },
  { id: "19", value: "19 - Corrèze" },
  { id: "21", value: "21 - Côte-d'Or" },
  { id: "22", value: "22 - Côtes-d'Armor" },
  { id: "23", value: "23 - Creuse" },
  { id: "24", value: "24 - Dordogne" },
  { id: "25", value: "25 - Doubs" },
  { id: "26", value: "26 - Drôme" },
  { id: "27", value: "27 - Eure" },
  { id: "28", value: "28 - Eure-et-Loir" },
  { id: "29", value: "29 - Finistère" },
  { id: "2A", value: "2A - Corse-du-Sud" },
  { id: "2B", value: "2B - Haute-Corse" },
  { id: "30", value: "30 - Gard" },
  { id: "31", value: "31 - Haute-Garonne" },
  { id: "32", value: "32 - Gers" },
  { id: "33", value: "33 - Gironde" },
  { id: "34", value: "34 - Hérault" },
  { id: "35", value: "35 - Ille-et-Vilaine" },
  { id: "36", value: "36 - Indre" },
  { id: "37", value: "37 - Indre-et-Loire" },
  { id: "38", value: "38 - Isère" },
  { id: "39", value: "39 - Jura" },
  { id: "40", value: "40 - Landes" },
  { id: "41", value: "47 - Loir-et-Cher" },
  { id: "42", value: "42 - Loire" },
  { id: "43", value: "43 - Haute-Loire" },
  { id: "44", value: "44 - Loire-Atlantique" },
  { id: "45", value: "45 - Loiret" },
  { id: "46", value: "46 - Lot" },
  { id: "47", value: "47 - Lot-et-Garonne" },
  { id: "48", value: "48 - Lozère" },
  { id: "49", value: "49 - Maine-et-Loire" },
  { id: "50", value: "50 - Manche" },
  { id: "51", value: "51 - Marne" },
  { id: "52", value: "52 - Haute-Marne" },
  { id: "53", value: "53 - Mayenne" },
  { id: "54", value: "54 - Meurthe-et-Moselle" },
  { id: "55", value: "55 - Meuse" },
  { id: "56", value: "56 - Morbihan" },
  { id: "57", value: "57 - Moselle" },
  { id: "58", value: "58 - Nièvre" },
  { id: "59", value: "59 - Nord" },
  { id: "60", value: "60 - Oise" },
  { id: "61", value: "61 - Orne" },
  { id: "62", value: "62 - Pas-de-Calais" },
  { id: "63", value: "63 - Puy-de-Dôme" },
  { id: "64", value: "64 - Pyrénées-Atlantiques" },
  { id: "65", value: "65 - Hautes-Pyrénées" },
  { id: "66", value: "66 - Pyrénées-Orientales" },
  { id: "67", value: "67 - Bas-Rhin" },
  { id: "68", value: "68 - Haut-Rhin" },
  { id: "69", value: "69 - Rhône" },
  { id: "70", value: "70 - Haute-Saône" },
  { id: "71", value: "71 - Saône-et-Loire" },
  { id: "72", value: "72 - Sarthe" },
  { id: "73", value: "73 - Savoie" },
  { id: "74", value: "74 - Haute-Savoie" },
  { id: "75", value: "75 - Paris" },
  { id: "76", value: "76 - Seine-Maritime" },
  { id: "77", value: "77 - Seine-et-Marne" },
  { id: "78", value: "78 - Yvelines" },
  { id: "79", value: "79 - Deux-Sèvres" },
  { id: "80", value: "80 - Somme" },
  { id: "81", value: "81 - Tarn" },
  { id: "82", value: "82 - Tarn-et-Garonne" },
  { id: "83", value: "83 - Var" },
  { id: "84", value: "84 - Vaucluse" },
  { id: "85", value: "85 - Vendée" },
  { id: "86", value: "86 - Vienne" },
  { id: "87", value: "87 - Haute-Vienne" },
  { id: "88", value: "88 - Vosges" },
  { id: "89", value: "89 - Yonne" },
  { id: "90", value: "90 - Territoire de Belfort" },
  { id: "91", value: "91 - Essonne" },
  { id: "92", value: "92 - Hauts-de-Seine" },
  { id: "93", value: "93 - Seine-Saint-Denis" },
  { id: "94", value: "94 - Val-de-Marne" },
  { id: "95", value: "95 - Val-d'Oise" },
  { id: "971", value: "971 - Guadeloupe" },
  { id: "972", value: "972 - Martinique" },
  { id: "973", value: "973 - Guyane" },
  { id: "974", value: "947 - La Réunion" },
  { id: "976", value: "976 - Mayotte" },
];
