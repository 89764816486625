import React, { useContext, useState, useEffect, createContext } from "react";
import axios from "axios";
import XMLParser from "react-xml-parser";

const AppContext = createContext();
let lecture = {
  age: "",
  partielle: "",
  totale: "",
};

var token= sessionStorage.getItem("token");
var informations= sessionStorage.getItem("infos");
var admin= sessionStorage.getItem("isAdmin");

export function getToken() {
  return token;
}
export function setToken(t) {
  token = t;
}

export function getInformations() {
  return informations;
}
export function setInformations(info) {
  informations = info;
}

export function getAdmin() {
  return admin;
}
export function setAdmin(ad) {
  admin = ad;
}


export function docDownload(name) {
  let linkSource = `${process.env.REACT_APP_URL_PDF}/${name}`;
  let downloadLink = document.createElement("a");
  downloadLink.href = linkSource;  
  downloadLink.download = name;
  downloadLink.click();
}




const AppProvider = ({ children }) => {
  const [tabSimu, setTabSimu] = useState([]);

  /*
  useEffect(() => {
    fetch(`${process.env.REACT_APP_URL_NODE}/apiSum`)
      .then((responce) => responce.text())
      .then((data) => {
        let responseDoc = new DOMParser().parseFromString(
          data,
          "application/xml"
        );
        console.log(responseDoc);
        setUsers(responseDoc.getElementsByTagName("a:DateNaissance"));
        console.log(responseDoc.getElementsByTagName("a:DateNaissance"));
      });
  }, []);
*/
  useEffect(() => {
    async function fetchData() {
      fetch(`${process.env.REACT_APP_URL_NODE}/apiSum`)
        .then((responce) => responce.text())
        .then((data) => {
          var responseDoc = new XMLParser().parseFromString(data);
          /*let responseDoc = new DOMParser().parseFromString(
            data,
            "application/xml"
          );*/
          var listeSimu =
            responseDoc.children["0"].children["0"].children["0"].children["21"]
              .children;
          var tabSimu = [];

          var exiteTab = false;
          listeSimu.map((items) => {
            if (
              items.children["0"].value == "70" ||
              items.children["0"].value == "75" ||
              items.children["0"].value == "80" ||
              items.children["0"].value == "85"
            ) {
              tabSimu.map((itemsPar) => {
                if (items.children["0"].value == itemsPar.age) {
                  exiteTab = true;
                }
              });
              if (!exiteTab) {
                lecture = {
                  age: items.children["0"].value,
                  partielle: items.children["2"].value,
                  totale: items.children["3"].value,
                };

                tabSimu.push(lecture);
              }
            }
          });
          setTabSimu(tabSimu);
        });
    }
    fetchData();
  }, []);
  return (
    <AppContext.Provider
      value={{
        tabSimu,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useApiContext = () => {
  return useContext(AppContext);
};

export { AppContext, AppProvider };
