import React, { Component } from "react";
import logo from "../assets/img/Logo-repam-white.svg";
import { Dropdown } from "react-bootstrap";
import Iconclosemenu from "../assets/img/icon-menu-close-blanc.svg";
import { getToken } from "../Context/ApiContext";
import { withRouter } from "./ComponentMain/whitRouter";
import ModalQuit from "./ComponentMain/CommonComponent/modalQuit";

class NavBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      namePage: "",
    };
  }

  alertClick = (event) => {
    event.preventDefault();
    let tag = event.currentTarget.dataset.tag;

    if (document.getElementsByClassName("navigationSection").length || document.getElementsByClassName("dependenceFiltre").length ) {
      this.setState({
        showModal: true,
        namePage: tag,
      });
    } else {
      this.setState({
        showModal: false,
      });
      let alink = document.createElement("a");
      alink.href = tag;
      alink.click();
    }
  };

  OnchangeModal = () => {
    if (this.state.showModal) {
      this.setState({
        showModal: false,
      });
    }
  };

  render() {
    return (
      <>
        <div className="sidebar-wrapper">
          <div className="close_menu mobile" onClick={this.props.openMenu}>
            <img
              src={Iconclosemenu}
              className="img-responsive img_close_menu"
              alt=""
            />
          </div>
          <div className="row bloc_logo">
            <a
              href={`#/dependance/${getToken()}`}
              data-tag={`#/dependance/${getToken()}`}
              onClick={(e)=>this.alertClick(e)}
            >
              <img src={logo} alt={logo} className="logo img-responsive" />
            </a>
          </div>
          <div className="row">
            <ul className="navsidebar" id="menu">
              <li className="m1 active">
                <Dropdown>
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-menu-children"
                    className=""
                  >
                    Souscription & contrats
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="">Santé</Dropdown.Item>
                    <Dropdown.Item href="">Prévoyance TNS</Dropdown.Item>
                    <Dropdown.Item href="">Obsèques</Dropdown.Item>
                    <Dropdown.Item href="">
                      Vidéos de démonstration
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="active"
                      href={`#/dependance/${getToken()}`}
                      data-tag={`#/dependance/${getToken()}`}
                      onClick={(e)=>this.alertClick(e)}
                    >
                      Dépendance
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
              {/* <li>
                <a className="m2 " href="#">
                  Documents pratiques
                </a>
              </li>
             <li>
                <a className="m3 " href="#">
                  Produits
                </a>
              </li>
              <li>
                <a className="m4 " href="#">
                  Commande de Documentation
                </a>
              </li>
              <li>
                <a className="m5 " href="#">
                  Actualités
                </a>
              </li> */}
            </ul>
          </div>
        </div>
        {this.state.showModal && (
          <ModalQuit
            page={this.state.namePage}
            OnchangeModal={()=>this.OnchangeModal()}
          />
        )}
      </>
    );
  }
}

export default withRouter(NavBar);
