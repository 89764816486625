import React from "react";
import { Modal } from "react-bootstrap";

const ModalServeur = (props) => {

  return (
    <Modal id="ModalProposition" className="ModalExpire" show={true}>
      <h3>Information</h3>

      <Modal.Body>
        <p>Une erreur est survenue, veuillez vérifier les données saisies et réessayer ultérieurement !</p>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-primary" onClick={props.handleCloseModalServeur}>
          Ok
        </button>
      </Modal.Footer>
    </Modal>
  );
};
export default ModalServeur;